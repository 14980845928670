import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PropTypes from 'prop-types';
import { blue } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material';

import UserPreferences from 'js/api/userPreferences';
import {
    addSavedListInfo, addWatchListInfo,
    getMoreSavedList, removeSavedListInfo, removeWatchListInfo,
    updateSavedList,
    updateWatchList
} from 'js/actions/watchList';
import Commons from '../../../../helpers/Commons';
import SavedListErrorHandler from './SavedListErrorHandler';
import SavedListLoader from './SavedListLoader';
import Colors from 'js/constants/colors';
import SegmentHelper from 'js/helpers/Segment';
import { trackEvent } from 'js/actions/segment';
import SearchSavedList from './SearchSavedList';
import ToastMessages from 'js/constants/toastMessage';
import { selectSelectedAccount } from '../../../../selectors/linkedAccount';
import  InfoMsg from 'js/constants/infoMessage';
import { SAVED_LIST_LIMIT } from '../../../userFavorites/savedLists/SavedListsPanel';

const StyledLimitMessage = styled('p')({
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    marginTop: 8,
    color: Colors.TEXT_PRIMARY,
});

export const ADD = 'ADD';
const REMOVE = 'REMOVE';

const propertyHandler = {
    add: {
        type: ADD,
        save: 'addPropertyToSavedList',
        watch: 'addPropertyToWatchList',
        successMessage: (count, list) => `${count} ${Commons.getPropertyLabel(count)} successfully added to ${list}`,
        failureMessage: (count, list) => `Sorry, there was an error adding ${count} ${Commons.getPropertyLabel(count)} to ${list}`,
        severity: 'success',
        eventNameSavedList: 'Add to Saved List',
        eventNameWatchList: 'Add to My Watch List',
    },
    remove: {
        type: REMOVE,
        save: 'deletePropertyToSavedList',
        successMessage: (count, list) => `${count} ${Commons.getPropertyLabel(count)} successfully removed from ${list}`,
        failureMessage: (count, list) => `Sorry, there was an error removing ${count} ${Commons.getPropertyLabel(count)} from ${list}`,
        watch: 'deletePropertyToWatchList',
        severity: 'warning',
        eventNameSavedList: 'Remove from Saved List',
        eventNameWatchList: 'Remove from My Watch List',
    },
};

// + 1 is for watchlist that is already included in the list
const MAX_SAVED_LIST_TO_DISPLAY = SAVED_LIST_LIMIT + 1;

const SavedList = ({
    list,
    properties,
    handleSnackBar,
    listRef,
    pageContext,
    entryPoint,
    searchTab,
    searchActive,
    isHistoricProperty,
}) => {
    const dispatch = useDispatch();
    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount, shallowEqual);
    const savedList = useSelector(state => state?.watchList.get('savedList'));
    const info = useSelector(state => state?.watchList.get('info'));

    const [loading, setLoading] = useState({});
    const page = useSelector(state => state?.watchList?.get('savedList'))?.page || 0;
    const scrollState = useState(0);
    const [reached, setReached] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        // Scroll has reached the threshold with a successful previous call and has next page
        if (reached
            && savedList.hasNext
            && !savedList.fetchingMore
            && savedList.successToFetchMore &&
            list.length < MAX_SAVED_LIST_TO_DISPLAY) {
            const nextPage = page + 1;
            dispatch(getMoreSavedList({
                params: {
                    page: nextPage,
                    ...(searchValue ? { search: searchValue } : {}),
                },
            }))
        }
    }, [reached]);

    useEffect(() => {
        if (!savedList.fetchingMore) {
            setReached(false);
        }
    }, [savedList.fetchingMore]);

    useEffect(() => {
        if (!savedList.searching && !savedList.successSearch) {
            handleSnackBar('error', ToastMessages.SAVED_LIST_ERROR);
        }

        if (!savedList.searching && savedList.successSearch && listRef?.current) {
            listRef.current.scrollTop = 0;
        }
    }, [savedList.searching, savedList.successSearch]);

    const initRequest = (handler, id, selectedProperties) => {
        // Watchlist id is always falsy because it is zero
        if (!id) {
            return UserPreferences[handler.watch](
                selectedClAppAccountUserGuid,
                selectedProperties,
            );
        }
        // Save List
        return UserPreferences[handler.save](
            selectedClAppAccountUserGuid,
            id,
            selectedProperties,
        )
    };

    const updateList = (id, res) => {
        const updater = !id ? updateWatchList : updateSavedList;
        dispatch(updater({
            propertiesCount: res?.additionalInfo?.propertiesCount,
            id,
        }))
    }

    const sendMixpanelEvent = (handler, id) => {
        const eventName = !id
            ? handler.eventNameWatchList
            : handler.eventNameSavedList;
        dispatch(trackEvent(SegmentHelper.addOrRemoveToList({
            eventName,
            pageContext,
            searchTab,
            properties: properties,
            entryPoint,
        })))
    }

    const addOrRemoveProperties = (handler, id = 0) => {
        const { description } = list.find(item => item.id === id);
        initRequest(handler, id, properties)
            .then((res) => {
                handleSnackBar(
                    handler.severity,
                    handler.successMessage(properties.length, description),
                );
                updateList(id, res);
                sendMixpanelEvent(handler, id);

                if (id !== 0 && handler.type === ADD) {
                    dispatch(addSavedListInfo({
                        id: [id],
                        properties,
                    }));
                } else if (id !== 0 && handler.type === REMOVE) {
                    dispatch(removeSavedListInfo({
                        id,
                        properties,
                    }))
                }
                if (id === 0 && handler.type === ADD) {
                    dispatch(addWatchListInfo(properties));
                } else if (id === 0 && handler.type === REMOVE) {
                    dispatch(removeWatchListInfo(properties))
                }
            })
            .catch(() => {
                handleSnackBar(
                    'error',
                    handler.failureMessage(properties.length, description),
                );
            })
            .finally(() => {
                setLoading(prev => ({
                    ...prev,
                    [id]: false,
                }))
            });
    }

    const handleToggle = (id) => {
        if (!loading[id]) {
            setLoading(prev => ({ ...prev, [id]: true }))
        }

        if (isSavedListChecked(id)) {
            // remove properties if it is already checked
            addOrRemoveProperties(propertyHandler.remove, id);
        } else {
            addOrRemoveProperties(propertyHandler.add, id);
        }
    };

    const isSavedListChecked = (id) => {
        let checked = false;

        if (!properties || !properties?.length) {
            return false;
        }

        if (id === 0) { // watchlist
            checked = properties.every(p => info?.watchList?.includes(p));
        } else if (id > 0) { // saved list
            const targetSavedList = info?.savedList?.find(s => s.id === id);
            checked = properties.every(p => targetSavedList?.properties?.includes(p));
        }
        return checked;
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        scrollState[1]((prevScrollTop) => {
            // Scroll direction should always be from top to bottom
            if (scrollTop > prevScrollTop) {
                // Will trigger the API call if scroll is more than 60%;
                const threshold = scrollHeight * 0.60;
                const scrollPosition = scrollTop + offsetHeight;
                if (Math.trunc(scrollPosition) > Math.trunc(threshold) && !reached) {
                    setReached((prev) => !prev ? true : prev);
                }
            }

            return scrollTop;
        });
    };

    return (
        <>
            {
                searchActive &&
                    <SearchSavedList
                        searchActive={searchActive}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        setReached={setReached}
                    />
            }
            <List
                sx={{
                    width: '100%',
                    maxHeight: '360px',
                    overflow: 'auto',
                    marginBottom: 1,
                }}
                onScroll={handleScroll}
                ref={listRef}
            >
                {list?.map((_list, i) => {
                    const labelId = `checkbox-list-label-${_list.description}`;

                    return (
                        <ListItem
                            key={_list.description}
                            secondaryAction={i === 0 && <NotificationsActiveIcon />}
                            disablePadding
                            sx={{
                                color: Colors.SECONDARY.MAIN,
                                '.MuiListItemSecondaryAction-root' : {
                                    top: '55%',
                                }
                            }}
                        >
                            <ListItemButton
                                onClick={() => handleToggle(_list.id)}
                                divider={i !== list.length -1}
                                disabled={(isHistoricProperty && !isSavedListChecked(_list.id)) || loading[_list.id]}
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 'initial' }}>
                                    {
                                        loading[_list.id]
                                            ? (
                                                <div style={{ width: '28.28px', height: '40.28px' }}>
                                                    <CircularProgress
                                                        size={18}
                                                        sx={{
                                                            color: blue[500],
                                                            position: 'absolute',
                                                            top: '55%',
                                                            left: '8.5%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                </div>
                                            )
                                            : (
                                                <Checkbox
                                                    edge="start"
                                                    checked={isSavedListChecked(_list.id)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                        'data-testid': `saved-list-check-${i}`,
                                                    }}
                                                />
                                            )
                                    }

                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    sx={{
                                        color: 'black',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        '& .MuiTypography-root': {
                                            display: 'flex',
                                            fontSize: '14px',
                                            color: Colors.SECONDARY.MAIN,
                                            lineHeight: '21px',
                                        },
                                    }}
                                >
                                    <span
                                        style={{
                                            maxWidth: 240,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                        title={_list.description}
                                    >
                                        {_list.description}
                                    </span>
                                    <span style={{ marginLeft: '4px' }}>
                                        ({_list.propertiesCount})
                                    </span>
                                    {
                                        i === 0 &&
                                        <Tooltip
                                            title={
                                                <div>
                                                    <span>
                                                        Properties added to this list will be monitored. You will receive alerts when properties are listed for sale, for rent or sold
                                                    </span>
                                                    <br/>
                                                    <br/>
                                                    <span>
                                                        <span style={{ fontWeight: '700', marginRight: '3px', }}>
                                                            n.b.
                                                        </span>
                                                        This list can’t be deleted
                                                    </span>
                                                </div>
                                            }
                                            placement="left"
                                            arrow
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        fontSize: 10,
                                                        fontWeight: '400',
                                                        backgroundColor: Colors.SECONDARY.MAIN,
                                                        '&amp;.MuiTooltip-arrow': {
                                                            color: Colors.SECONDARY.MAIN,
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <InfoOutlinedIcon
                                                sx={{
                                                    color: Colors.BLACK_54_OPAQUE,
                                                    fontSize: '20px',
                                                    marginLeft: 1,
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
                {
                    list.length >= MAX_SAVED_LIST_TO_DISPLAY &&
                        <StyledLimitMessage>{ InfoMsg.MAX_RESULTS_RETURNED }</StyledLimitMessage>
                }
                <SavedListLoader loading={savedList.fetchingMore} />
                <SavedListErrorHandler page={page} />
            </List>
        </>
    );
}

SavedList.propTypes = {
    list: PropTypes.array,
    properties: PropTypes.array,
    listRef: PropTypes.object,
    handleSnackBar: PropTypes.func,
    pageContext: PropTypes.string,
    searchTab: PropTypes.string,
    entryPoint: PropTypes.string,
    searchActive: PropTypes.bool,
    isHistoricProperty: PropTypes.bool,
};

export default SavedList;
