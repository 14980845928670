import { isAU, isNZ } from '../constants/crux';
import RSS from '../constants/rss';
import States from '../constants/states';
import COUNTRY from '../constants/countries';
import RapidSearch, {
    PROPERTY_TYPES_V2,
    PROPERTY_TYPES_NZ_V2,
    LISTING_TYPE_NZ_V2,
    LISTING_TYPE_AU_V2,
    ADVANCED_FILTER_FIELD_NAMES,
} from '../constants/rapidSearch';
import {
    COMPLIANCE_NOTES_AU,
    COMPLIANCE_NOTES_NZ,
    FILTERS,
    FILTERS_NZ,
    LOGOS,
} from '../constants/assets';
import TOOLTIP from '../constants/tooltipText';
import SuggestionTypes from '../constants/suggestionTypes';
import DropdownOptions from '../constants/options/dropdownOptions';
import MapTypeConstant from '../constants/mapTypeConstant';
import {
    PRINT_PROPERTY_HISTORY_AU,
    PRINT_PROPERTY_HISTORY_NZ,
    PRINT_ADDITIONAL_INFORMATION_AU,
    PRINT_ADDITIONAL_INFORMATION_NZ, PRINT_VALUATION_ESTIMATE_AU, PRINT_VALUATION_ESTIMATE_NZ,
} from '../constants/propertyPrint';
import DateFilterConst from '../constants/rapidSearch/dateFilter';
import ExportConst from '../constants/export';
import SuburbStatConst from '../constants/suburbStatReport';
import RAPID from '../constants/rapidSearch';

const { ADDRESS, LOCALITY } = SuggestionTypes;

export const FEATURE_FLAG_KEY_MARKETING_DIRECT = isAU ? 'rpd.marketing.direct' : 'pg.marketing.direct';
export const MARKETING_DIRECT = {
    ...ExportConst.MARKETING_EXPORT,
    featureFlagKey: FEATURE_FLAG_KEY_MARKETING_DIRECT,
};

export const RSS_ID = isNZ ? RSS.RSS_ID_NZ : RSS.RSS_ID_AU;
export const LNG = isNZ ? 'en-NZ' : 'en-AU';
export const BSG_CONTEXT = isNZ ? 'nz' : 'au';
export const CORELOGIC_LOGO = isAU ? LOGOS.RP_HEADER : LOGOS.PG_HEADER;
export const APP_CONTEXT = isNZ ? '/rpnz' : '/rpp';
export const SITE = isNZ ? 'NZ CRUX' : 'RPP CRUX';
export const RETURN_SUGGESTION = isNZ ? 'byType' : 'byStatusAndType';
export const FOOTER = {
    copyRights: isNZ ? 'clCopyrightShort_nz' : 'clCopyrightShort_au',
    disclaimers: {
        general: isNZ ? 'cl_nz' : 'cl_au',
        valuation: isNZ ? 'avmShort_nz' : 'avmShort_au',
    },
    // For testing purposes
    links: {
        privacyPolicy: isNZ ? 'https://www.corelogic.co.nz/about-us/privacy-policy' : 'https://www.corelogic.com.au/legals/privacy-policy',
        termsOfUse: isNZ ? 'https://www.corelogic.co.nz/terms-use' : 'https://www.corelogic.com.au/legals/terms-conditions',
        exportReportTermsAndUse: isNZ ? 'https://www.corelogic.co.nz/about-us/product-terms-and-conditions' : 'https://www.corelogic.com.au/legals/terms-conditions',
        contactUs: isNZ ? 'https://www.corelogic.co.nz/contact-us' : 'https://www.corelogic.com.au/contact/sales-redirect',
        disclaimers: isNZ ? 'https://www.corelogic.co.nz/about-us/copyright-disclaimer' : 'https://www.corelogic.com.au/legals/copyright-disclaimer',
    },
    mobileBadges: {
        appleStoreLink: isNZ ? 'https://apps.apple.com/nz/app/corelogic-property-pro/id1093664720'
            : 'https://itunes.apple.com/us/app/rp-data-pro/id962231516?ls=1&mt=8',
        googleStoreLink: isNZ ? 'https://play.google.com/store/apps/details?id=nz.com.corelogic.propertypro'
            : 'https://play.google.com/store/apps/details?id=au.com.corelogic.rpdatapro',
    },
};
export const SITE_TITLE = isNZ ? 'CoreLogic Property Guru' : 'RP Data';
export const PROPERTY_PROFILE = {
    reportWithoutAvmId: isNZ ? 'cl_nz_no_avm' : 'cl_au_no_avm',
    reportWithAvmId: isNZ ? 'rpnz_avm' : 'cl_au_avm',
};
export const STATES = isNZ ? [COUNTRY.NZ] : States.map(_state => _state.toLowerCase());
export const LISTING_TYPE = isNZ ? RapidSearch.LISTING_TYPE_METHOD_NZ :
    RapidSearch.LISTING_TYPE_METHOD;

export const SUGGEST_FILTERS = isAU ?
    RapidSearch.SUGGEST_FILTERS_AU :
    RapidSearch.SUGGEST_FILTERS_NZ;

export const S3_FILTERS = isAU ? FILTERS : FILTERS_NZ;
export const S3_COMPLIANCE_NOTE = isAU ? COMPLIANCE_NOTES_AU : COMPLIANCE_NOTES_NZ;

export const SLAS_PLACEHOLDER = isAU ? 'Search for an address, street name, suburb, postcode, council area or state' : 'Search for an address, street name, suburb or council area';

export const RAPID_PROPERTY_TYPE_V2 = isAU ? PROPERTY_TYPES_V2 : PROPERTY_TYPES_NZ_V2;
export const RAPID_PROPERTY_TYPE_V2_TENURE = [
    RAPID_PROPERTY_TYPE_V2[0],
    isAU ? PROPERTY_TYPES_V2[1] : [],
].flat();

export const LISTING_TYPE_V2 = isAU ? LISTING_TYPE_AU_V2 : LISTING_TYPE_NZ_V2;

export const SUGGESTION_TYPE_NO_LGA_SEARCH = isAU ?
    [SuggestionTypes.ADDRESS, SuggestionTypes.STREET,
        SuggestionTypes.LOCALITY, SuggestionTypes.POSTCODE] :
    [SuggestionTypes.ADDRESS, SuggestionTypes.STREET, SuggestionTypes.LOCALITY];

export const LOCALIZED_SORT_BY_SETTING = isAU ? RapidSearch.SORT_BY_SETTING_AU : RapidSearch.SORT_BY_SETTING_NZ;
export const MATERIALS_IN_EXTERNAL_WALL_FILTER = isNZ ? 'wallMaterial' : 'materialsInExternalWalls';
export const MATERIALS_IN_ROOF_FILTER = isNZ ? 'roofMaterial' : 'materialsInRoof';
export const COMMON_INITIAL_FILTERS = isAU ? {
    type: RAPID_PROPERTY_TYPE_V2.map(type => type.value),
    beds: { min: '', max: '' },
    baths: { min: '', max: '' },
    carSpaces: { min: '', max: '' },
    subType: [],
    keywords: [],
    salesLastCampaignListedType: LISTING_TYPE_V2.map(listingType => listingType.value),
    developmentZoneDescription: [],
    landUse: [],
    landArea: { min: '', max: '', unit: 'm²' },
    yearBuilt: { min: '', max: '' },
    distanceToHospital: { min: '', max: '' },
    distanceToRiverFrontage: { min: '', max: '' },
    distanceToSchool: { min: '', max: '' },
    distanceToSeaRiverCanal: { min: '', max: '' },
    distanceToShops: { min: '', max: '' },
    allWeatherAccessToLand: false,
    allWeatherRoadsToFarm: false,
    housesOnFarm: { min: '', max: '' },
    irrigatedArea: { min: '', max: '' },
    damsBores: { min: '', max: '' },
    paddocksYards: { min: '', max: '' },
    permanentWater: false,
    sealedRoadsToProperty: false,
    boatRampMooringSpace: false,
    fenced: false,
    grannyFlatSelfContained: false,
    lockupGarages: { min: '', max: '' },
    materialsInExternalWalls: '',
    materialsInRoof: '',
    swimmingPool: false,
    noOfTennisCourts: { min: '', max: '' },
    treesOnLand: false,
    accessAvailable24Hrs: false,
    accessSecurityInstalled: false,
    closeToPublicTransport: false,
    liftInstalled: false,
    onMainRoad: false,
    sewage: false,
    townSewerageAvailable: false,
    townWaterAvailable: false,
    water: false,
    canalFrontage: false,
    coastFrontage: false,
    golfFrontage: false,
    highExposure: false,
    lakeFrontage: false,
    riverFrontage: false,
    scenicView: false,
    waterFrontage: false,
    currentUnimprovedLandValue: { min: '', max: '' },
    unimprovedCapitalValue: { min: '', max: '' },
    unimprovedCapitalValueDate: { min: '', max: '' },
    unimprovedCapitalValueDateOption: DateFilterConst.ALL,
    feeCode: '',
    factoryArea: { min: '', max: '', unit: 'm²' },
    mezzanineArea: { min: '', max: '', unit: 'm²' },
    officeShowroomArea: { min: '', max: '', unit: 'm²' },
    otherArea: { min: '', max: '', unit: 'm²' },
    shopArea: { min: '', max: '', unit: 'm²' },
    showroomArea: { min: '', max: '', unit: 'm²' },
    warehouseArea: { min: '', max: '', unit: 'm²' },
    workshopArea: { min: '', max: '', unit: 'm²' },
    airConditioned: false,
    builtInWardrobes: false,
    ductedHeating: false,
    ductedVacuum: false,
    fireplace: false,
    openPlan: false,
    polishedFloors: false,
    renovated: false,
    diningRooms: { min: '', max: '' },
    familyRumpusRooms: { min: '', max: '' },
    floorLevelsInside: { min: '', max: '' },
    loungeRooms: { min: '', max: '' },
    loungeDiningRoomsCombined: { min: '', max: '' },
    kitchens: { min: '', max: '' },
    noOfStudyRooms: { min: '', max: '' },
    toilets: { min: '', max: '' },
    heritageOtherClassification: '',
    buildingArea: { min: '', max: '', unit: 'm²' },
    equivalentBuildingArea: { min: '', max: '', unit: 'm²' },
    floorLevelOfUnit: { min: '', max: '' },
    floorArea: { min: '', max: '' },
    floorLevelsInsideUnit: { min: '', max: '' },
    flats: { min: '', max: '' },
    totalFloorsInBuilding: { min: '', max: '' },
    streetFrontage: { min: '', max: '' },
    yearBuildingRefurbished: { min: '', max: '' },
    yearEstablished: { min: '', max: '' },
    freeStandingBuilding: false,
    salesLastCampaignStartDate: { min: '', max: '' },
    salesLastCampaignStartDateOption: DateFilterConst.ALL,
    rentalLastCampaignStartDate: { min: '', max: '' },
    rentalLastCampaignStartDateOption: DateFilterConst.ALL,
    rentalOccupancy: false,
} : {
    type: RAPID_PROPERTY_TYPE_V2.map(type => type.value),
    beds: { min: '', max: '' },
    baths: { min: '', max: '' },
    carSpaces: { min: '', max: '' },
    subType: [],
    salesLastCampaignListedType: LISTING_TYPE_V2.map(listingType => listingType.value),
    developmentZoneDescription: [],
    landUse: [],
    landArea: { min: '', max: '', unit: 'm²' },
    floorArea: { min: '', max: '' },
    yearBuilt: { min: '', max: '' },
    salesClassification: '',
    wallMaterial: '',
    roofMaterial: '',
    valuationLandValue: { min: '', max: '' },
    valuationImprovementsValue: { min: '', max: '' },
    valuationCapitalValue: { min: '', max: '' },
    valuationAssessmentDate: { min: '', max: '' },
    valuationAssessmentDateOption: DateFilterConst.ALL,
    salesLastCampaignStartDate: { min: '', max: '' },
    salesLastCampaignStartDateOption: DateFilterConst.ALL,
    rentalLastCampaignStartDate: { min: '', max: '' },
    rentalLastCampaignStartDateOption: DateFilterConst.ALL,
    rentalOccupancy: false,
};

export const HOUSEHOLD_INFORMATION_PANEL_TOOLTIP_TEXT = isNZ ?
    TOOLTIP.TEXT.HOUSEHOLD_INFORMATION_NZ :
    TOOLTIP.TEXT.HOUSEHOLD_INFORMATION;

export const SITE_NAME = isAU ? 'RP Data' : 'Property Guru';
export const HOTLINE = isAU ? '1300 734 318' : '0800 355 355';
export const COUNTRY_CODE_NUM = isAU ? '61' : '64';
export const CUSTOMER_CARE_LINK = isAU
    ? 'https://www.corelogic.com.au/contact#speak-to-customer-care'
    : 'https://www.corelogic.co.nz/contact-us';

export const LOCALIZED_ACTIVE_LISTING_ONLY_SETTING = isAU
    ? RapidSearch.ACTIVE_LISTING_ONLY_SETTING_AU
    : RapidSearch.ACTIVE_LISTING_ONLY_SETTING_NZ;

export const LOCALIZED_SEARCH_OPTIONS = isAU
    ? DropdownOptions.SEARCH_OPTIONS_AU
    : DropdownOptions.SEARCH_OPTIONS_NZ;

export const PARCEL_SEARCH_PLACEHOLDER = isAU ? 'Search for a parcel, lot/plan, section/block or hundred'
    : 'Search for a legal description';

export const MANAGE_USERS_CUSTOMER_CONTACT = isAU ? '1300 734 318' : '0800 355 355';
export const MAX_USERS_LIMIT_REACHED = `You have reached the maximum number of users for your subscription. To add a new user please remove another user first, or to add more users to your subscription contact ${MANAGE_USERS_CUSTOMER_CONTACT}.`;

export const MANAGE_USERS_TABLE_HEADING = isAU ? [
    { id: 'name', label: 'Name' },
    { id: 'userName', label: 'Username' },
    { id: 'email', label: 'Email', style: { minWidth: '200px', width: '20%' } },
    {
        id: 'lastLogin',
        label: 'Last Login',
        style: { minWidth: '162px', width: '15%' },
    },
    {
        id: 'canPayInArrears',
        label: 'Pay on Invoice',
        style: { minWidth: '160px', textAlign: 'center' },
        tooltip: 'Selecting this allows the user to add the cost of' +
            ' any in-application purchases (e.g. Titles) to the organisation\'s invoice.',
    },
    {
        id: 'adminUser',
        label: 'Admin User',
        style: { minWidth: '135px', textAlign: 'center', width: '10%' },
        tooltip: 'Admin User: The account administrator can add user to or remove ' +
            'users from this account. They can also add other users as administrators',
        disableSort: true,
    },
    { id: 'action', label: 'Action', disableSort: true, style: { textAlign: 'center' } },
] : [
    { id: 'name', label: 'Name' },
    { id: 'userName', label: 'Username' },
    { id: 'email', label: 'Email', style: { minWidth: '150px', width: '20%' } },
    {
        id: 'canOrderTitles',
        label: 'Titles',
        style: { width: '8%', minWidth: '86.88px', textAlign: 'center' },
        tooltip: 'Titles: Adding or removing this controls the users ability to ' +
            'order titles, and add the cost to the organisation\'s invoice.',
        disableSort: true,
    },
    {
        id: 'lastLogin',
        label: 'Last Login',
        style: { minWidth: '162px', width: '15%' },
    },
    {
        id: 'adminUser',
        label: 'Admin User',
        style: { minWidth: '125px', textAlign: 'center', width: '10%' },
        tooltip: 'Admin User: The account administrator can add user to or remove ' +
            'users from this account. They can also add other users as administrators',
        disableSort: true,
    },
    { id: 'action', label: 'Action', disableSort: true, style: { textAlign: 'center' } },
];

export const CRUX_FEED = isAU
    ? 'https://www.corelogic.com.au/tools/residential-news.json'
    : 'https://www.corelogic.co.nz/tools/residential-news-rpnz.json';

export const DEFAULT_MAP = isNZ ? MapTypeConstant.CORELOGIC_ROAD : MapTypeConstant.ROADMAP;
export const SELECTED_MAP_VIEW = isAU ? 'SELECTED_MAP_VIEW_AU' : 'SELECTED_MAP_VIEW_NZ';
export const SELECTED_STATE_NAME = 'SELECTED_STATE_NAME';
export const SELECTED_STATE_COMPANY = 'SELECTED_STATE_COMPANY';
export const SELECTED_STATE_BUILDING = 'SELECTED_STATE_BUILDING';
export const SELECTED_STATE_VOLUME_FOLIO = 'SELECTED_STATE_VOLUME_FOLIO';

export const PRINT_PROPERTY_HISTORY = isAU ? PRINT_PROPERTY_HISTORY_AU : PRINT_PROPERTY_HISTORY_NZ;
export const PRINT_ADDITIONAL_INFORMATION = isAU
    ? PRINT_ADDITIONAL_INFORMATION_AU
    : PRINT_ADDITIONAL_INFORMATION_NZ;
export const CUSTOM_PRINT_DISPLAY_OPTIONS = isAU
    ? 'CUSTOM_PRINT_DISPLAY_OPTIONS_AU'
    : 'CUSTOM_PRINT_DISPLAY_OPTIONS_NZ';

export const SUBURB_STAT_DISPLAY_OPTIONS = isAU
    ? 'SUBURB_STAT_DISPLAY_OPTIONS_AU'
    : 'SUBURB_STAT_DISPLAY_OPTIONS_NZ';

export const SUBURB_STAT_OPTIONS_SORT = isAU
    ? 'SUBURB_STAT_OPTIONS_SORT_AU'
    : 'SUBURB_STAT_OPTIONS_SORT_NZ';

export const PRINT_VALUATION_ESTIMATE = isAU
    ? PRINT_VALUATION_ESTIMATE_AU
    : PRINT_VALUATION_ESTIMATE_NZ;

export const METRIC_TYPE_ID_LIST = isNZ
    ? [21, 37, 77, 69, 75, 79, 82, 36, 80]
    : [11, 37, 49, 88, 32, 4, 90, 36, 50];

export const STREET_FIELD = isAU ? 'addressStreetSuburbStatePostcode' : 'addressStreetSuburbTown';
export const ADDRESS_FIELD = isAU ? 'addressCollective' : 'addressContains';
export const SUBURB_FIELD = isAU ? 'addressSuburbStatePostcode' : 'addressSuburbTown';
export const POSTCODE_FIELD = isAU ? 'addressPostcodeState' : 'addressPostcode';
export const COUNCIL_AREA_FIELD = isAU ? 'councilAreaState' : 'councilArea';
export const COUNCIL_AREA_CATEGORY = isAU ? '5' : '7';
export const NAVIGATION_FILTERS = isAU ? [
    { label: 'Core Filters', value: 'core_filters' },
    { label: 'Commercial', value: 'commercial', hasEntitlements: true  },
    { label: 'Distances', value: 'distances', disabled: true, hasEntitlements: true },
    { label: 'External', value: 'external', hasEntitlements: true },
    { label: 'Frontage', value: 'frontage', hasEntitlements: true },
    { label: 'Internal', value: 'internal', hasEntitlements: true },
    { label: 'Property Values', value: 'property_values', hasEntitlements: true },
    { label: 'Rural', value: 'rural', disabled: true, hasEntitlements: true },
    { label: 'Service', value: 'service', disabled: true, hasEntitlements: true },
    { label: 'Structure', value: 'structure', hasEntitlements: true },
] : [
    { label: 'Core Filters', value: 'core_filters' },
    { label: 'External', value: 'external', hasEntitlements: true },
    { label: 'Property Values', value: 'property_values', hasEntitlements: true },
    { label: 'Commercial', value: 'commercial', disabled: true },
    { label: 'Distances', value: 'distances', disabled: true },
    { label: 'Frontage', value: 'frontage', disabled: true },
    { label: 'Internal', value: 'internal', disabled: true },
    { label: 'Rural', value: 'rural', disabled: true },
    { label: 'Service', value: 'service', disabled: true },
];

export const EXTERNAL = [
    { label: ADVANCED_FILTER_FIELD_NAMES.boatRampMooringSpace, value: 'boatRampMooringSpace' },
    { label: ADVANCED_FILTER_FIELD_NAMES.fenced, value: 'fenced' },
    { label: ADVANCED_FILTER_FIELD_NAMES.grannyFlatSelfContained, value: 'grannyFlatSelfContained' },
    { label: ADVANCED_FILTER_FIELD_NAMES.lockupGarages, value: 'lockupGarages' },
    { label: ADVANCED_FILTER_FIELD_NAMES.materialsInExternalWalls, value: MATERIALS_IN_EXTERNAL_WALL_FILTER },
    { label: ADVANCED_FILTER_FIELD_NAMES.materialsInRoof, value: MATERIALS_IN_ROOF_FILTER },
    { label: ADVANCED_FILTER_FIELD_NAMES.swimmingPool, value: 'swimmingPool' },
    { label: ADVANCED_FILTER_FIELD_NAMES.noOfTennisCourts, value: 'noOfTennisCourts' },
    { label: ADVANCED_FILTER_FIELD_NAMES.treesOnLand, value: 'treesOnLand' },
];

export const PROCESSED_FILTER_RANGES = isAU ? [
    'beds', 'carSpaces', 'baths', 'landArea', 'yearBuilt',
    'factoryArea', 'mezzanineArea', 'officeShowroomArea', 'otherArea',
    'showroomArea', 'warehouseArea', 'workshopArea', 'shopArea',
    'buildingArea', 'equivalentBuildingArea', 'floorLevelOfUnit',
    'floorArea', 'floorLevelsInsideUnit', 'flats',
    'totalFloorsInBuilding', 'yearBuildingRefurbished', 'yearEstablished',
    'lockupGarages', 'noOfTennisCourts',
    'currentUnimprovedLandValue', 'unimprovedCapitalValue', 'unimprovedCapitalValueDate',
    'salesLastSaleSettlementDate',
] : [
    'beds', 'carSpaces', 'baths', 'landArea', 'yearBuilt',
    'valuationLandValue', 'valuationImprovementsValue', 'floorArea',
    'valuationCapitalValue', 'valuationAssessmentDate', 'salesLastSaleSettlementDate',
];

export const MARKETING_EXPORT_TTT_STATEMENT = isAU
    ? ExportConst.MARKETING_EXPORT_TTT_STATEMENT_AU
    : ExportConst.MARKETING_EXPORT_TTT_STATEMENT_NZ;

export const MARKETING_EXPORT_DISCLAIMER = isAU
    ? ExportConst.MARKETING_EXPORT_DISCLAIMER_AU
    : ExportConst.MARKETING_EXPORT_DISCLAIMER_NZ;

export const ANALYSIS_EXPORT_DISCLAIMER = isAU
    ? ExportConst.ANALYSIS_EXPORT_DISCLAIMER_AU
    : ExportConst.ANALYSIS_EXPORT_DISCLAIMER_NZ;

export const ADDITIONAL_EXPORT_STATEMENT = {
    contact: ExportConst[`CONTACT_STATEMENT_${isAU ? 'AU' : 'NZ'}`],
    record: ExportConst[`RECORD_STATEMENT_${isAU ? 'AU' : 'NZ'}`],
}

export const EXPORT_STATEMENT_LIST = isAU
    ? ExportConst.STATEMENT_LIST_AU
    : ExportConst.STATEMENT_LIST_NZ;

export const EXPORT_USER_SETTINGS_NAMES = {
    [ExportConst.MARKETING_EXPORT.title]: isAU ? ExportConst.MARKETING_EXPORT_DISPLAY_OPTIONS : ExportConst.MARKETING_EXPORT_DISPLAY_OPTIONS_NZ,
    [ExportConst.ANALYSIS_EXPORT.title]: isAU ? ExportConst.ANALYSIS_EXPORT_DISPLAY_OPTIONS : ExportConst.ANALYSIS_EXPORT_DISPLAY_OPTIONS_NZ,
};

const REPORT_TYPES_AU = {
    digitalReport: {
        label: 'Digital Property Report',
        value: 'digitalReport',
        suggestionType: [ADDRESS],
        entitlements: 'DIGITAL_PROPERTY_PROFILE',
    },
    cmaReport: {
        label: 'CMA Report',
        value: 'cmaReport',
        suggestionType: [ADDRESS],
        entitlements: 'Property CMA',
    },
    onePageReport: {
        label: '1 Page PDF',
        value: 'onePageReport',
        suggestionType: [ADDRESS],
        entitlements: 'Profiler Property 1 Page',
    },
    valuationEstimate: {
        label: 'Valuation Estimate',
        value: 'valuationEstimate',
        suggestionType: [ADDRESS],
        entitlements: 'Valuation'
    },
    propertyReport: {
        label: 'Property Report',
        value: 'propertyReport',
        suggestionType: [ADDRESS],
        entitlements: 'Property Profiler',
    },
    proposal: {
        label: 'Proposal',
        value: 'proposal',
        suggestionType: [ADDRESS],
        entitlements: 'PROPOSAL_POINT',
    },
    suburbReport: {
        label: 'Suburb Report',
        value: 'suburbReport',
        suggestionType: [LOCALITY],
        entitlements: 'Profiler Suburb',
    },
    rentalEstimate: {
        label: 'Rental Estimate',
        value: 'rentalEstimate',
        suggestionType: [ADDRESS],
        entitlements: 'Rental Avm Full'
    },
    rentalCmaReport: {
        label: 'Rental CMA Report',
        value: 'rentalCmaReport',
        suggestionType: [ADDRESS],
        entitlements: 'Rentals CMA',
    },
    soi: {
        label: 'Statement of Information',
        value: 'soi',
        suggestionType: [ADDRESS],
        entitlements: 'CMA Generic Report Engine Full'
    },
    suburbStats: {
        label: 'Suburb Statistics Report',
        value: 'suburbStatsReport',
        suggestionType: [LOCALITY],
        entitlements: 'Suburb Statistics',
    },
    propertyInvestment: {
        label: 'Property Investment Report',
        value: 'propertyInvestment',
        suggestionType: [ADDRESS],
        entitlements: 'EXPORT_LIMIT_PRO',
        featureFlag: 'crux-property-investment-report',
    },
};

const REPORT_TYPES_NZ = {
    digitalReport: {
        label: 'Digital Property Report',
        value: 'digitalReport',
        suggestionType: [ADDRESS],
        entitlements: 'DIGITAL_PROPERTY_PROFILE',
    },
    cmaReport: {
        label: 'CMA Report',
        value: 'cmaReport',
        suggestionType: [ADDRESS],
        entitlements: 'Property CMA',
    },
    onePageReport: {
        label: '1 Page PDF',
        value: 'onePageReport',
        suggestionType: [ADDRESS],
        entitlements: 'Profiler Property 1 Page',
    },
    propertyReport: {
        label: 'Property Report',
        value: 'propertyReport',
        suggestionType: [ADDRESS],
        entitlements: 'Property Profiler',
    },
    rentalCmaReport: {
        label: 'Rental CMA Report',
        value: 'rentalCmaReport',
        suggestionType: [ADDRESS],
        entitlements: 'Rentals CMA',
    },
    titleReport: {
        label: 'Title Report',
        value: 'titleReport',
        suggestionType: [ADDRESS],
        entitlements: 'TITLES_REPORT',
    },
    suburbStats: {
        label: 'Suburb Statistics Report',
        value: 'suburbStatsReport',
        suggestionType: [LOCALITY],
        entitlements: 'Suburb Statistics',
    },
};

export const REPORT_TYPES = isAU ? REPORT_TYPES_AU : REPORT_TYPES_NZ;
export const USER_SETTING_CARD_VIEW = isAU ? 'CARD_VIEW_AU' : 'CARD_VIEW_NZ';
export const PER_PAGE_LIMIT = isAU ? 'PER_PAGE_LIMIT_AU' : 'PER_PAGE_LIMIT_NZ';

export const SUBURB_STATS_TOGGLE_IDS = isAU
    ? SuburbStatConst.TOGGLE_IDS_AU
    : SuburbStatConst.TOGGLE_IDS_NZ;

export const SUBURB_STATS_REPORT_OPTIONS = isAU
    ? SuburbStatConst.SUBURB_STATS_REPORT_OPTIONS_AU
    : SuburbStatConst.SUBURB_STATS_REPORT_OPTIONS_NZ;

export const OCCUPATION_IMAGE = isAU
    ? SuburbStatConst.OCCUPATION_IMAGE_AU
    : SuburbStatConst.OCCUPATION_IMAGE_NZ;

export const OCCUPATION_LABEL = isAU
    ? SuburbStatConst.OCCUPATION_LABEL_AU
    : SuburbStatConst.OCCUPATION_LABEL_NZ;

export const LGA_KEY = isAU ? 'councilAreaId' : 'territorialAuthorityId';
export const PROPERTY_TYPE_IDS = isAU ? ['1', '2', '3'] : ['3', '6'];
export const LGA_NAME_NODE = isAU ? 'councilAreaName' : 'territorialAuthorityName';
export const LGA_TITLE = isAU ? 'Council Area' : 'Territorial Authority';

export const MARKET_TRENDS_METRIC_TYPES_CARDS = isAU
    ? SuburbStatConst.MARKET_TRENDS_METRIC_TYPES_CARDS_AU
    : SuburbStatConst.MARKET_TRENDS_METRIC_TYPES_CARDS_NZ;

export const MARKET_TRENDS_METRIC_TYPES_QUARTILES = isAU
    ? SuburbStatConst.MARKET_TRENDS_METRIC_TYPES_QUARTILE_AU
    : SuburbStatConst.MARKET_TRENDS_METRIC_TYPES_QUARTILE_NZ;

export const SSR_PROPERTY_TYPE_LIST = isAU
    ? SuburbStatConst.PROPERTY_TYPES_AU
    : SuburbStatConst.PROPERTY_TYPES_NZ;

export const RAPID_WEEKLY = isAU ? RAPID.UNLEASH_WEEKLY : RAPID.WEEKLY;

export const ACTIVE_COUNTRY = {
    name: isAU ? 'Australia' : 'New Zealand',
    zoom: isAU ? 4.5 : 5.5,
};

export const CONTACT_NUMBER = isAU ? '1300 734 318' : '0800 355 355';

export const LONGITUDE_ERROR = `The shape must be within ${isAU ? 'Australia' : 'New Zealand' }, please adjust any points outside of the country to save your territory.`
