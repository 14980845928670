export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_STATUS_TYPE = 'SET_STATUS_TYPE';
export const GET_SAVED_SEARCH = 'GET_SAVED_SEARCH';
export const GET_SAVED_SEARCH_FAIL = 'GET_SAVED_SEARCH_FAIL';
export const GET_SAVED_SEARCH_SUCCESS = 'GET_SAVED_SEARCH_SUCCESS';
export const UPDATE_SAVED_SEARCH_SETTINGS = 'UPDATE_SAVED_SEARCH_SETTINGS';
export const UPDATE_SAVED_SEARCH_SETTINGS_FAIL = 'UPDATE_SAVED_SEARCH_SETTINGS_FAIL';
export const UPDATE_SAVED_SEARCH_SETTINGS_SUCCESS = 'UPDATE_SAVED_SEARCH_SETTINGS_SUCCESS';
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH';
export const UPDATE_SAVED_SEARCH_FAIL = 'UPDATE_SAVED_SEARCH_FAIL';
export const UPDATE_SAVED_SEARCH_SUCCESS = 'UPDATE_SAVED_SEARCH_SUCCESS';
export const SAVE_SEARCH = 'SAVE_SEARCH';
export const SAVE_SEARCH_FAIL = 'SAVE_SEARCH_FAIL';
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';
export const REMOVE_SAVED_SEARCH = 'REMOVE_SAVED_SEARCH';
export const REMOVE_SAVED_SEARCH_FAIL = 'REMOVE_SAVED_SEARCH_FAIL';
export const REMOVE_SAVED_SEARCH_SUCCESS = 'REMOVE_SAVED_SEARCH_SUCCESS';
export const GET_NOTIFICATION_HISTORY = 'GET_NOTIFICATION_HISTORY';
export const GET_NOTIFICATION_HISTORY_FAIL = 'GET_NOTIFICATION_HISTORY_FAIL';
export const GET_NOTIFICATION_HISTORY_SUCCESS = 'GET_NOTIFICATION_HISTORY_SUCCESS';
export const RESET_NOTIFICATION_HISTORY = 'RESET_NOTIFICATION_HISTORY';
export const SET_TOAST_STATUS = 'SET_TOAST_STATUS';
export const CLEAR_SAVED_SEARCH = 'CLEAR_SAVED_SEARCH';
export const SET_SHOULD_REFRESH = 'SET_SHOULD_REFRESH';

export function getSavedSearch(itemIds, enableSavedSearchV2 = false) {
    return {
        type: GET_SAVED_SEARCH,
        payload: {
            itemIds,
            enableSavedSearchV2,
        },
    };
}

export function updateSavedSearchSettings(savedSearch, enableSavedSearchV2 = false) {
    return {
        type: UPDATE_SAVED_SEARCH_SETTINGS,
        payload: {
            savedSearch,
            enableSavedSearchV2,
        },
    };
}

export function setSearchType(payload) {
    return {
        type: SET_SEARCH_TYPE,
        payload,
    };
}

export function setStatusType(payload) {
    return {
        type: SET_STATUS_TYPE,
        payload,
    };
}

export function saveSearch(_saveSearch, enableSavedSearchV2 = false) {
    return {
        type: SAVE_SEARCH,
        payload: {
            saveSearch: _saveSearch,
            enableSavedSearchV2,
        },
    };
}

export function removeSavedSearch(itemId) {
    return {
        type: REMOVE_SAVED_SEARCH,
        payload: itemId,
    };
}

export function getNotificationHistory(itemId) {
    return {
        type: GET_NOTIFICATION_HISTORY,
        payload: itemId,
    };
}

export function updateSavedSearch(_savedSearch, enableSavedSearchV2 = false) {
    return {
        type: UPDATE_SAVED_SEARCH,
        payload: {
            savedSearch: _savedSearch,
            enableSavedSearchV2,
        },
    };
}

export function resetNotifications() {
    return {
        type: RESET_NOTIFICATION_HISTORY,
    };
}


export function setToastStatus(payload) {
    return {
        type: SET_TOAST_STATUS, payload,
    };
}
export function setShouldRefresh(payload) {
    return {
        type: SET_SHOULD_REFRESH, payload,
    };
}

export function clearSavedSearch() {
    return {
        type: CLEAR_SAVED_SEARCH,
    };
}
