import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    SEARCH_ACCOUNT_ADDRESS,
    USER_SETTINGS,
    USER_SETTINGS_FAIL,
    USER_SETTINGS_SUCCESS,
    APP_CONFIGS,
    APP_CONFIGS_FAIL,
    APP_CONFIGS_SUCCESS,
    ACCOUNT_DETAILS,
    ACCOUNT_DETAILS_FAIL,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_FAIL,
    UPLOAD_USER_PHOTO,
    getAccountDetailsSuccess,
    getAccountDetailsFail,
    updateAccountDetailsSuccess,
    updateAccountDetailsFail,
    searchAccountAddressSuccess,
    searchAccountAddressFail,
    setUploadUserPhotoStatus,
} from '../actions/userPreferences';
import { addCruxCmptError, rmCruxCmptError } from '../actions/errorHandler';
import UserPrefsApi from '../api/userPreferences';
import ClapiApi from '../api/clapi';
import Bugsnag from '../bugsnag';
import Commons from '../helpers/Commons';
import Segment from '../helpers/Segment';
import { trackEvent } from '../actions/segment';
import { setActiveView, updateUserPhoto } from '../actions/clapi';
import { USER_SETTING_CARD_VIEW } from '../helpers/Localization';

function* getUserPrefAPIConf() {
    const { config, claud, clapi, linkedAccounts } = yield select();
    const clUserGuid = claud.get('session')?.clusrId;
    const clapiUsrDetail = clapi.get('usrDetail');
    const accountNumber = Commons.get(clapiUsrDetail, 'accountDetail.externalAccountId');
    return {
        config,
        clUserGuid,
        accountNumber,
        linkedAccounts
    };
}

function getUserSettingsFn() {
    return function* () {
        try {
            const userSettings = yield call(UserPrefsApi.getUserSettings);
            yield put({
                type: USER_SETTINGS_SUCCESS,
                payload: userSettings,
            });
            const activeView  = userSettings
                ?.find(userSetting => userSetting.name === USER_SETTING_CARD_VIEW)
                ?.value;
            yield put(setActiveView(activeView));
            yield put(rmCruxCmptError(USER_SETTINGS));
        } catch (e) {
            Bugsnag.notify({
                name: 'User settings',
                message: e.message,
            });
            yield put({
                type: USER_SETTINGS_FAIL,
            });
            yield put(addCruxCmptError(USER_SETTINGS, e));
        }
    };
}

function getAppConfigurationsFn() {
    return function* () {
        try {
            const { linkedAccounts } = yield getUserPrefAPIConf();
            const selectedClAppAccountUserGuid = linkedAccounts.get("selectedClAppAccountUserGuid");
            const appConfigs = yield call(
                UserPrefsApi.getAppConfigurations,
                selectedClAppAccountUserGuid,
            );
            yield put({
                type: APP_CONFIGS_SUCCESS,
                payload: appConfigs,
            });
            yield put(rmCruxCmptError(APP_CONFIGS));
        } catch (e) {
            Bugsnag.notify(e);
            yield put({
                type: APP_CONFIGS_FAIL,
            });
            yield put(addCruxCmptError(APP_CONFIGS, e));
        }
    };
}

function getAccountDetailsFn() {
    return function* () {
        try {
            const { config, accountNumber } = yield getUserPrefAPIConf();
            const domain = config.get('applicationDomain').userPreferences;

            yield put(rmCruxCmptError(ACCOUNT_DETAILS_FAIL));
            const accountDetails = yield call(UserPrefsApi.getAccountDetails, domain, accountNumber);
            yield put(getAccountDetailsSuccess(accountDetails));
        } catch (error) {
            Bugsnag.notify(error);
            yield put(getAccountDetailsFail());
            yield put(addCruxCmptError(ACCOUNT_DETAILS_FAIL, error));
        }
    };
}

function updateAccountDetailsFn() {
    return function* (action) {
        try {
            const { config, accountNumber } = yield getUserPrefAPIConf();
            const domain = config.get('applicationDomain').userPreferences;

            const { payload: body, addressToRemove, mixPanelProps } = action;
            const newAccountAddress = body.addresses.find(address => !address.addressNumber);
            if (newAccountAddress) {
                const newAddress = yield call(
                    UserPrefsApi.addAccountAddress,
                    domain,
                    accountNumber,
                    newAccountAddress,
                );
                body.addresses = body.addresses
                    .map(address => (!address.addressNumber ? newAddress : address));
            }

            yield put(rmCruxCmptError(UPDATE_ACCOUNT_DETAILS_FAIL));
            if (addressToRemove) {
                yield call(
                    UserPrefsApi.removePaymentAddress,
                    domain,
                    addressToRemove.addressNumber,
                );
            }
            const accountDetails = yield call(
                UserPrefsApi.updateAccountDetails,
                domain,
                accountNumber,
                body,
            );
            yield put(updateAccountDetailsSuccess(accountDetails));
            yield put(trackEvent(Segment.updateAccountDetails({
                ...mixPanelProps,
                result: 'Success',
            })));
        } catch (error) {
            Bugsnag.notify(error);
            yield put(updateAccountDetailsFail());
            yield put(addCruxCmptError(UPDATE_ACCOUNT_DETAILS_FAIL, error));
            yield put(trackEvent(Segment.updateAccountDetails({
                ...action.mixPanelProps,
                result: 'Failure',
            })));
        }
    };
}

function searchAccountAddressFn() {
    return function* (action) {
        try {
            const keyword = action.payload;
            const suggestions = yield call(ClapiApi.getSuggestions, keyword, ['address'], false);
            yield put(searchAccountAddressSuccess(suggestions));
        } catch (error) {
            yield put(searchAccountAddressFail());
        }
    };
}

function uploadUserPhotoFn() {
    return function* (action) {
        try {
            const { config } = yield getUserPrefAPIConf();
            const userPrefDomain = config.get('applicationDomain').userPreferences;
            yield put(setUploadUserPhotoStatus({
                isUploadSuccess: false,
                isUploading: true,
            }));
            const uploadPhotoResponse = yield call(
                UserPrefsApi.uploadUserPhoto,
                userPrefDomain,
                action.payload,
            );

            yield put(updateUserPhoto(uploadPhotoResponse.profilePhotoUrl));
            yield put(setUploadUserPhotoStatus({
                isUploadSuccess: true,
                isUploading: false,
            }));
            yield put(trackEvent(Segment.updateProfileImage('Success')));
        } catch (error) {
            yield put(setUploadUserPhotoStatus({
                hasError: true,
            }));
            yield put(trackEvent(Segment.updateProfileImage('Failure')));
        } finally {
            yield put(setUploadUserPhotoStatus({
                isUploadSuccess: false,
                isUploading: false,
                hasError: false,
            }));
        }
    };
}

export const getUserSettings = getUserSettingsFn();
export const getAppConfigs = getAppConfigurationsFn();
export const getAccountDetails = getAccountDetailsFn();
export const updateAccountDetails = updateAccountDetailsFn();
export const searchAccountAddress = searchAccountAddressFn();
export const uploadUserPhoto = uploadUserPhotoFn();

function* getUserSettingsWatcher() {
    yield takeLatest(USER_SETTINGS, getUserSettings);
}
function* getAppConfigsWatcher() {
    yield takeLatest(APP_CONFIGS, getAppConfigs);
}
function* getAccountDetailsWatcher() {
    yield takeLatest(ACCOUNT_DETAILS, getAccountDetails);
}
function* updateAccountDetailsWatcher() {
    yield takeLatest(UPDATE_ACCOUNT_DETAILS, updateAccountDetails);
}
function* addressSearchWatcher() {
    yield takeLatest(SEARCH_ACCOUNT_ADDRESS, searchAccountAddress);
}
function* uploadUserPhotoWatcher() {
    yield takeLatest(UPLOAD_USER_PHOTO, uploadUserPhoto);
}

const watchersList = [
    getUserSettingsWatcher(),
    getAppConfigsWatcher(),
    getAccountDetailsWatcher(),
    updateAccountDetailsWatcher(),
    addressSearchWatcher(),
    uploadUserPhotoWatcher(),
];

export default watchersList;
