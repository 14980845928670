import methods from '../constants/methods';
import API from './index';
import CLAPI from './clapi';

const getMapLayerImage = (mapLayerUrl) => {
    const requestOptions = {
        path: `/api/map${mapLayerUrl}`,
        isProxy: true,
        timeout: API.getTimeouts().large
    };
    const headerOptions = {
        method: methods.GET.method,
        headers: {
            Accept: '*/*',
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getMapTileImage = (mapTileUrl) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/nearmap${mapTileUrl}/?${appAccountUserGuid}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.GET.method,
        headers: {
            Accept: '*/*',
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getNearmapCoverage = (polygon) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/nearmap/coverage/${polygon}/?${appAccountUserGuid}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getMapLayerImage,
    getMapTileImage,
    getNearmapCoverage,
};
