import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';

import UserPreferences from '../../../api/userPreferences';
import { getSavedList, updateSavedList } from '../../../actions/watchList';
import { SAVE_SEARCH_ACCEPTABLE_CHARS } from '../../../constants/regexPatterns';
import ValidationStatus from '../../../constants/validationStatus';
import { trackEvent } from '../../../actions/segment';
import SegmentHelper from '../../../helpers/Segment';
import ErrorMessage from 'js/constants/errorMsg';
import { selectSelectedAccount } from '../../../selectors/linkedAccount';

const SavedListDrawer = ({
    open,
    onClose,
    id,
    description = '',
    showMessage,
    setLoading,
    setDescriptionFilter,
}) => {
    const [name, setName] = useState(description);

    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount);
    const dispatch = useDispatch();

    useEffect(() => {
        setName(description);
    }, [description]);

    const addList = () => {
        const params = {
            description: name.trim(),
            propertiesCount: 0,
        };

        setLoading(true);
        UserPreferences.createSavedList(
            selectedClAppAccountUserGuid,
            params
        ).then((res) => {
            if (res?.statusCode === 'CREATED') {
                dispatch(trackEvent(SegmentHelper.createSavedList({
                    savedListName: name,
                    pageContext: 'Favourites',
                })));
                dispatch(getSavedList());
                setDescriptionFilter('');
                showMessage(`${name} list was created successfully`, ValidationStatus.SUCCESS);
                closeDrawer();
            } else {
                showMessage(ErrorMessage.CREATE_SAVED_LIST_ERROR, ValidationStatus.ERROR);
                setLoading(false);
            }
        }).catch((e) => {
            if (e?.response?.statusCode === 'CONFLICT') {
                showMessage(ErrorMessage.DUPLICATE_SAVED_LIST, ValidationStatus.ERROR);
            } else {
                showMessage(ErrorMessage.CREATE_SAVED_LIST_ERROR, ValidationStatus.ERROR);
            }
            setLoading(false);
        });
    }

    const editList = () => {
        const params = {
            description: name.trim(),
        };

        setLoading(true);
        UserPreferences.renameSavedList(
            selectedClAppAccountUserGuid,
            id,
            params
        ).then((res) => {
            if (res.statusCode === 'OK') {
                dispatch(updateSavedList({ ...params, id }));
                dispatch(trackEvent(SegmentHelper.recordSavedList({
                    eventName: 'Configure Saved List',
                    name,
                    page: 'Favourites',
                })));
                showMessage(`${name} list was updated successfully`, ValidationStatus.SUCCESS);
                closeDrawer();
            } else {
                showMessage(ErrorMessage.CREATE_SAVED_LIST_ERROR, ValidationStatus.ERROR);
            }
        }).catch((e) => {
            if (e?.response?.statusCode === 'CONFLICT') {
                showMessage(ErrorMessage.DUPLICATE_SAVED_LIST, ValidationStatus.ERROR);
            } else {
                showMessage(ErrorMessage.CREATE_SAVED_LIST_ERROR, ValidationStatus.ERROR);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const save = () => {
        if (!new RegExp(SAVE_SEARCH_ACCEPTABLE_CHARS).test(name)) {
            showMessage('Invalid character entered', ValidationStatus.ERROR);
            return;
        }

        if (id) {
            editList();
        } else {
            addList();
        }
    };

    const closeDrawer = () => {
        onClose();
        setName(description);
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={closeDrawer}
            data-testid="saved-list-drawer"
        >
            <div className="saved-list-drawer">
                <div className="saved-list-drawer-header">
                    <span>{id ? 'Update': 'Create'} Saved List</span>
                    <Button
                        disableRipple
                        onClick={closeDrawer}
                        className="close-button-style"
                    >
                        <Close />
                    </Button>
                </div>
                <hr />
                <div className="saved-list-description-name">
                    <TextField
                        variant="outlined"
                        label="Name"
                        style={{
                            width: '100%',
                        }}
                        sx={{
                            '&:hover': { borderColor: '#39f' }
                        }}
                        value={name}
                        onChange={event => setName(event.target.value)}
                        inputProps={{ maxLength: 255 }}
                    />
                </div>
                <hr />
                <div className="saved-list-drawer-button">
                    <Button
                        variant="text"
                        onClick={closeDrawer}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={save}
                        disabled={
                            name?.trim()?.length <= 0 ||
                            name === description ||
                            name?.length > 255
                        }
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Drawer>);
};

SavedListDrawer.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    showSuccessMessage: PropTypes.func,
    showErrorMessage: PropTypes.func,
    setLoading: PropTypes.func,
    setDescriptionFilter: PropTypes.func,
};

export default SavedListDrawer;
