import { PERSIST, REHYDRATE } from 'redux-persist';
import { LD_INIT, LD_INIT_FAIL, LD_INIT_SUCCESS } from 'js/actions/launchDarkly';
import { SEGMENT_INIT, SEGMENT_INIT_FAIL, SEGMENT_INIT_SUCCESS } from 'js/actions/segment';
import * as CLAUD from 'js/actions/claud';
import * as CLAPI from 'js/actions/clapi';
import * as SEARCH from 'js/actions/search';
import { CRUX_APP_ERROR } from 'js/actions/errorHandler';
import Commons from 'js/helpers/Commons';
import environments from './environments';
import * as CONFIG from 'js/actions/config';
import * as BRANDING from 'js/actions/manageBranding';
import * as SAVED_SEARCH from 'js/actions/savedSearch';
import * as MSG from 'js/actions/msgApi';
import { CRUX_LOADER, CRUX_UNLOADER } from '../actions/crux';
import * as WatchListAction from 'js/actions/watchList';
import * as LaActions from 'js/actions/linkedAccounts';
import { GET_SAVED_TERRITORIES, GET_SAVED_TERRITORIES_SUCCESS, GET_SAVED_TERRITORIES_FAIL } from 'js/actions/territories';
import {
    GET_ALL_REI_TOKENS,
    GET_ALL_REI_TOKENS_FAIL,
    GET_ALL_REI_TOKENS_SUCCESS,
    GET_FORM_TEMPLATES,
    GET_FORM_TEMPLATES_FAIL,
    GET_FORM_TEMPLATES_SUCCESS,
    GET_USER_TEMPLATES, GET_USER_TEMPLATES_FAIL,
    GET_USER_TEMPLATES_SUCCESS,
    CONNECT_REI_TOKEN,
    CONNECT_REI_TOKEN_SUCCESS,
    CONNECT_REI_TOKEN_FAIL,
    DISCONNECT_REI_TOKEN,
    DISCONNECT_REI_TOKEN_SUCCESS,
    DISCONNECT_REI_TOKEN_FAIL,
} from '../actions/reiForms';

// REDUX_BLOCKING holds the redux actions that will trigger the block ui to show
export const REDUX_BLOCKING = [
    CLAUD.LOGOUT,
    LD_INIT,
    CLAPI.HANDLE_RECENT_SEARCH,
    CLAPI.GET_ADDRESS_COLLECTIVE,
    CLAUD.GET_USER_DETAILS,
    CONFIG.CONFIG_INIT,
    CONFIG.PUBLIC_CONFIGURATION_INIT,
    CLAPI.SET_SEARCH_FILTERS_V2,
    CLAPI.GET_RAPID_NAME_SEARCH_RESULT,
    CLAPI.UPDATE_SEARCH_FILTERS_LIMIT,
    CLAPI.UPDATE_SAVED_LIST_FILTERS_LIMIT,
    SEARCH.RECENT_SEARCH_START,
    SEGMENT_INIT,
    PERSIST,
    BRANDING.GET_BRANDING,
    BRANDING.SAVE_BRANDING,
    BRANDING.EDIT_BRANDING,
    BRANDING.DELETE_BRANDING,
    BRANDING.ACTIVATE_THEME,
    BRANDING.REMOVE_THEME_LOGO,
    SAVED_SEARCH.UPDATE_SAVED_SEARCH,
    MSG.MSG_REMOVE_RECENT_SEARCH,
    MSG.MSG_REMOVE_RECENT_PARCEL_SEARCH,
    MSG.MSG_REMOVE_RECENT_TITLE_SEARCH,
    CRUX_LOADER,
    CLAPI.GET_SAVED_LIST_RESULT,
    WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST,
    LaActions.LA_ACTION.FETCH_ACCOUNTS_FETCHING,
    GET_SAVED_TERRITORIES,
    GET_ALL_REI_TOKENS,
    CONNECT_REI_TOKEN,
    DISCONNECT_REI_TOKEN,
    GET_FORM_TEMPLATES,
    GET_USER_TEMPLATES,
];
// REDUX_UNBLOCKING holds the redux actions that will trigger the block ui to hide
export const REDUX_UNBLOCKING = [
    CLAUD.LOGOUT_SUCCESS,
    CLAUD.LOGOUT_FAIL,
    LD_INIT_SUCCESS,
    LD_INIT_FAIL,
    CRUX_APP_ERROR,
    CLAPI.HANDLE_RECENT_SEARCH_SUCCESS,
    CLAPI.HANDLE_RECENT_SEARCH_FAIL,
    CLAPI.GET_ADDRESS_COLLECTIVE_SUCCESS,
    CLAUD.GET_USER_DETAILS_SUCCESS,
    CLAUD.GET_USER_DETAILS_FAIL,
    CONFIG.CONFIG_INIT_SUCCESS,
    CONFIG.CONFIG_INIT_FAIL,
    CONFIG.PUBLIC_CONFIGURATION_INIT_SUCCESS,
    CONFIG.PUBLIC_CONFIGURATION_INIT_FAIL,
    CLAPI.GET_SEARCH_RESULTS_SUCCESS,
    CLAPI.GET_SEARCH_RESULTS_FAIL,
    CLAPI.GET_RAPID_NAME_SEARCH_RESULT_SUCCESS,
    CLAPI.GET_RAPID_NAME_SEARCH_RESULT_FAIL,
    SEARCH.RECENT_SEARCH_END,
    SEGMENT_INIT_SUCCESS,
    SEGMENT_INIT_FAIL,
    REHYDRATE,
    BRANDING.GET_BRANDING_SUCCESS,
    BRANDING.GET_BRANDING_FAIL,
    BRANDING.SAVE_BRANDING_SUCCESS,
    BRANDING.SAVE_BRANDING_FAIL,
    BRANDING.EDIT_BRANDING_FAIL,
    BRANDING.EDIT_BRANDING_SUCCESS,
    BRANDING.DELETE_BRANDING_SUCCESS,
    BRANDING.DELETE_BRANDING_FAIL,
    BRANDING.ACTIVATE_THEME_SUCCESS,
    BRANDING.ACTIVATE_THEME_FAIL,
    BRANDING.REMOVE_THEME_LOGO_SUCCESS,
    BRANDING.REMOVE_THEME_LOGO_FAIL,
    SAVED_SEARCH.UPDATE_SAVED_SEARCH_SUCCESS,
    SAVED_SEARCH.UPDATE_SAVED_SEARCH_FAIL,
    MSG.MSG_REMOVE_RECENT_SEARCH_SUCCESS,
    MSG.MSG_REMOVE_RECENT_SEARCH_FAIL,
    MSG.MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG.MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL,
    MSG.MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS,
    MSG.MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL,
    CRUX_UNLOADER,
    WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS,
    WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL,
    CLAPI.GET_SAVED_LIST_RESULT_SUCCESS,
    CLAPI.GET_SAVED_LIST_RESULT_FAILED,
    LaActions.LA_ACTION.FETCH_ACCOUNTS_FAILED,
    LaActions.LA_ACTION.FETCH_ACCOUNTS_SUCCESS,
    GET_SAVED_TERRITORIES_SUCCESS,
    GET_SAVED_TERRITORIES_FAIL,
    GET_ALL_REI_TOKENS_SUCCESS,
    GET_ALL_REI_TOKENS_FAIL,
    CONNECT_REI_TOKEN_SUCCESS,
    CONNECT_REI_TOKEN_FAIL,
    DISCONNECT_REI_TOKEN_SUCCESS,
    DISCONNECT_REI_TOKEN_FAIL,
    GET_FORM_TEMPLATES_SUCCESS,
    GET_FORM_TEMPLATES_FAIL,
    GET_USER_TEMPLATES_SUCCESS,
    GET_USER_TEMPLATES_FAIL,
];

export const IS_PRODUCTION = process.env.NODE_ENV === environments.PRODUCTION;
export const COUNTRY = window.ENV.COUNTRY_CODE || 'au';
export const isNZ = COUNTRY === 'nz';
export const isAU = COUNTRY !== 'nz';

export const getRppDomain = () => {
    try {
        const cruxLocalStorage = window.localStorage;
        const cruxStore = JSON.parse(cruxLocalStorage.getItem('persist:crux'));
        const storedConfig = JSON.parse(cruxStore.config);
        const appContext = COUNTRY === 'nz' ? '/legacyrpnz' : '/legacyrpp';
        return Commons.get(storedConfig, 'applicationDomain.rpp') + appContext;
    } catch (e) {
        return null;
    }
};

export const DOM_PURIFY_FOOTER_OPTIONS = { USE_PROFILES: { html: true } };

export const POPPINS_FONT = '"Poppins", sans-serif';
