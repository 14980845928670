import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { connect } from 'react-redux';
import { routeCodes } from '../../constants/routes';
import { isNZ } from '../../constants/crux';
import Commons from '../../helpers/Commons';
import { navigateBreadCrumb } from '../../actions/segment';
import withPanelErrorHandler from '../higherOrderComponents/WithPanelErrorHandler';
import Entitlements from '../../helpers/Entitlements';
import { SUGGESTION_TYPE_NO_LGA_SEARCH } from '../../helpers/Localization';
import { cruxLoader, cruxUnloader } from '../../actions/crux';
import Clapi from '../../api/clapi';
import { cruxAppError } from '../../actions/errorHandler';

const BREADCRUMB = {
    HOME: {
        suggestionType: 'home',
        context: 'Home',
    },
    POSTCODE: {
        suggestionType: 'postcode',
        context: 'Postcode',
    },
    LOCALITY: {
        suggestionType: 'locality',
        context: 'Suburb',
    },
    STREET: {
        suggestionType: 'street',
        context: 'Street',
    },
    ADDRESS: {
        suggestionType: 'address',
    },
    COUNCIL_AREA: {
        suggestionType: 'councilArea',
        context: 'Council Area',
    },
};

export const RapidBreadcrumbs = ({
    targetProperty,
    propertyId,
    history,
    dispatch,
    usrDetail,
    activeView,
}) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [propertyDetails, setPropertyDetails] = useState(null);
    const [isFetchingData, setFetchingData] = useState(true);

    const hasLGASearchRole = Entitlements.hasLGASearchRole(usrDetail);

    const registerEvent = (searchContext, searchString) => {
        const payload = {
            searchContext,
            searchString,
            activeView,
        };
        dispatch(navigateBreadCrumb(payload));
    }

    const onClickHandler = (breadcrumb, searchString) => {
        const { suggestionType } = breadcrumb;
        if (BREADCRUMB.HOME === breadcrumb) {
            registerEvent(BREADCRUMB.HOME.context);
            history.push(routeCodes.HOME.path);
        } else if (hasLGASearchRole || SUGGESTION_TYPE_NO_LGA_SEARCH.includes(suggestionType)) {
            registerEvent(breadcrumb.context, searchString);
        }
    };

    const fetchAverageHoldPeriod = (route, searchString, suggestionType) => {
        dispatch(cruxLoader());
        Clapi.getStatistics({ searchString, suggestionType }, 'averageHoldTrends').then((response) => {
            route.state.averageHoldPeriod = response;
        }).catch(() => {
            dispatch(cruxAppError());
        }).finally(() => {
            dispatch(cruxUnloader());
            history.push(route);
        });
    }

    const redirectUrl = (suggestionType, searchString) => {
        const route = {
            pathname: routeCodes.SEARCH_RESULT.path(`${suggestionType}`, `${searchString}`),
            state: {
                entryPoint: 'Breadcrumb',
                suggestion: [{
                    suggestion: searchString,
                    suggestionType,
                }],
                suggestionType,
            },
        };

        if ([BREADCRUMB.LOCALITY.suggestionType,
            BREADCRUMB.COUNCIL_AREA.suggestionType,
            BREADCRUMB.POSTCODE.suggestionType].includes(suggestionType)) {
            fetchAverageHoldPeriod(route, searchString, suggestionType);
        } else {
            history.push(route);
        }
    };

    const addressToSingleLine = (mergeList, skipFormat = []) => mergeList
        .filter(item => !!item)
        .map(item => skipFormat.includes(item) ? item : Commons.capitalizeFirstLetter(item))
        .join(isNZ ? ', ' : ' ');

    const getSuburbTown = (suburb, town) => suburb === town ? [suburb] : [suburb, town];

    // effect to clear state upon search
    useEffect(() => {
        setBreadcrumbs([]);
        setFetchingData(true);
        setPropertyDetails(null);
    }, [propertyId]);

    // effect to retrieve details from rapid
    useEffect(() => {
        if (targetProperty) {
            const { value, isFetching } = targetProperty;
            setFetchingData(isFetching);
            if (!isFetching && value && !value.error ) {
                setPropertyDetails(value);
            }
        }
    }, [targetProperty]);

    // effect to set breadcrumbs data
    useEffect(() => {
        if (propertyDetails) {
            // set breadcrumbs here
            const {
                addressPostcode = '',
                addressTown = '',
                addressSuburb = '',
                addressStreet = '',
                councilArea = '',
                addressFirstLine = '',
                addressComplete = '',
                addressState = '',
            } = propertyDetails;
            // nz formatted address
            const _addressSuburbTown = getSuburbTown(addressSuburb, addressTown);
            const addressSuburbTown = addressToSingleLine(_addressSuburbTown);
            const addressStreetSuburbTown = addressToSingleLine([addressStreet, ..._addressSuburbTown]);
            // au formatted address
            const addressSuburbStatePostcode = addressToSingleLine([addressSuburb, addressState, addressPostcode], [addressState]);
            const addressStreetSuburbStatePostcode = addressToSingleLine(
                [addressStreet, addressSuburb, addressState, addressPostcode],
                [addressState],
            );
            const addressPostcodeState = addressToSingleLine([addressPostcode, addressState], [addressState]);
            const councilAreaState = addressToSingleLine([councilArea, addressState], [addressState]);

            const suburbSingleLine = isNZ ? addressSuburbTown : addressSuburbStatePostcode;
            const suburbDisplayText = isNZ ? addressSuburb || addressTown : addressSuburb;
            const streetSingleLine = isNZ ? addressStreetSuburbTown : addressStreetSuburbStatePostcode;
            const postcodeSingleLine = isNZ ? addressPostcode : addressPostcodeState;
            const councilAreaSingleLine = isNZ ? Commons.capitalizeFirstLetter(councilArea) : councilAreaState;

            const _breadcrumbs = [
                {
                    displayText: 'HOME',
                    suggestion: 'HOME',
                    breadcrumb: BREADCRUMB.HOME,
                },
                {
                    displayText: postcodeSingleLine,
                    suggestion: postcodeSingleLine,
                    breadcrumb: BREADCRUMB.POSTCODE,
                },
                {
                    displayText: councilArea,
                    suggestion: councilAreaSingleLine,
                    breadcrumb: BREADCRUMB.COUNCIL_AREA,
                },
                {
                    displayText: suburbDisplayText,
                    suggestion: suburbSingleLine,
                    breadcrumb: BREADCRUMB.LOCALITY,
                },
                {
                    displayText: addressStreet,
                    suggestion: streetSingleLine,
                    breadcrumb: BREADCRUMB.STREET,
                },
                {
                    displayText: addressFirstLine,
                    suggestion: addressComplete,
                    breadcrumb: BREADCRUMB.ADDRESS,
                },
            ];
            setBreadcrumbs(_breadcrumbs.filter(item => item.suggestion));
        }
    }, [propertyDetails]);

    if (isFetchingData) {
        return <BlockUi tag="div" blocking className="white-blocker move-to-back" keepInView />;
    }

    return (
        <div id="breadcrumbs">
            {
                breadcrumbs &&
                <ol className="breadcrumb">
                    {
                        breadcrumbs.map((item, i) => {
                            const {
                                suggestion,
                                breadcrumb: { suggestionType },
                                breadcrumb,
                                displayText,
                            } = item;
                            return (
                                <li
                                    className="breadcrumb-item"
                                    key={`breadcrumb-${suggestion.replace(/\s/g, '-')}-${i}`}
                                    onClick={() => onClickHandler(breadcrumb, suggestion)}
                                >
                                    { i > 0 ? <ChevronRightIcon sx={{ color: '#b2b2b2', fontSize: '16px', fontWeight: '500' }} /> : null}
                                    {
                                        [BREADCRUMB.HOME, BREADCRUMB.ADDRESS].includes(breadcrumb) ||
                                        (!hasLGASearchRole && !SUGGESTION_TYPE_NO_LGA_SEARCH.includes(suggestionType)) ?
                                            (
                                                <span>{displayText}</span>
                                            ) : (
                                                <a onClick={() => redirectUrl(suggestionType, suggestion)}>
                                                    {displayText}
                                                </a>
                                            )
                                    }
                                </li>
                            )
                        })
                    }
                </ol>
            }
        </div>
    );
};


const RapidBreadcrumbsWithErrorHandler = withPanelErrorHandler(RapidBreadcrumbs, true);

RapidBreadcrumbsWithErrorHandler.propTypes = {
    targetProperty: PropTypes.object,
    propertyId: PropTypes.number,
    history: PropTypes.any,
    usrDetail: PropTypes.object,
    activeView: PropTypes.string,
}

const ConnectedRapidBreadCrumbs = connect(state => ({
    targetProperty: state.rapid.get('targetProperty'),
    usrDetail: state.clapi.get('usrDetail'),
    activeView: state.searchResults.get('activeView'),
}))(RapidBreadcrumbsWithErrorHandler);

ConnectedRapidBreadCrumbs.displayName = 'ConnectedRapidBreadCrumbs';

export default ConnectedRapidBreadCrumbs;
