import moment from 'moment';
import { SITE } from '../helpers/Localization';

export const parsePath = (pathname) => {
    let parsedPath = pathname.replace(/\/properties\/(\d)*/, '/properties/:id');
    if (pathname === '') {
        parsedPath = '/';
        return parsedPath;
    }
    return parsedPath;
};

const AnalyticsGateway = {
    initialize: ({ segment }) => {
        if (window.analytics) {
            window.analytics.load(segment.key);
        }
    },

    reportDescription: (reportType) => {
        const reportTypeMappings = {
            csr: 'Comparable Sales Report',
            soi: 'Statement of Information Report',
            cma: 'CMA Report',
        };
        return reportTypeMappings[reportType];
    },

    sendEvent: (data) => {
        const eventProperties = {};
        data.properties.forEach((property) => {
            eventProperties[property.label] = property.value;
        });
        window.analytics.track(data.eventName, {
            ...eventProperties,
        });
    },

    identifyUser: (data) => {
        const dateFormat = 'ddd, MMM d, YYYY h:m A';
        if (window.analytics) {
            window.analytics.identify(
                data.userName,
                {
                    Site: SITE,
                    'Site Version': '1',
                    Username: data.userName,
                    Email: data.workEmail,
                    'User ID': data.userId,
                    'First Name': data.firstName,
                    'Last Name': data.lastName,
                    'CRM Account ID': data.accountId,
                    'Trade Name': data.tradeName,
                    'Industry Segment': data.industrySegment,
                    'Franchise ID': data.franchiseId,
                    [`Date Last Used ${SITE}`]: moment().format(dateFormat),
                    'CRUX User': true,
                },
            );
            window.analytics.ready(() => {
                /* eslint no-undef: 0 */
                mixpanel.people.set_once({
                    [`Date First Used ${SITE}`]: moment().format(dateFormat),
                });
            });
        }
    },

    addUserLastGeneratedReport: (data, reportType) => {
        const reportDateGeneratedX = `Date Last Generated ${reportType}`;
        const params = {
            'Date Last Generated Report': new Date().toISOString(),
        };
        params[reportDateGeneratedX] = new Date().toISOString();

        if (window.analytics) {
            window.analytics.identify(
                data.userName,
                params,
            );
        }
    },

    pageView: (pathname) => {
        window.analytics.page({ path: parsePath(pathname) });
    },

    addUserTraits: (data) => {
        const userTraits = {};
        data.traits.forEach((trait) => {
            userTraits[trait.label] = trait.value;
        });
        window.analytics.identify(
            data.username,
            { ...userTraits },
        );
    },
};

export default AnalyticsGateway;
