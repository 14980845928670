import Bugsnag from '@bugsnag/js';
import moment from 'moment/moment';
import OTM from '../../constants/otm';
import Commons from '../Commons';
import { isAU, isNZ } from '../../constants/crux';
import {
    RAPID_PROPERTY_TYPE_V2,
    LISTING_TYPE_V2,
    COMMON_INITIAL_FILTERS,
    PROCESSED_FILTER_RANGES,
    EXTERNAL,
    RAPID_PROPERTY_TYPE_V2_TENURE,
    RAPID_WEEKLY,
} from '../Localization';
import RapidSearch, {
    ALL,
    MAX_OPTION,
    MIN_OPTION,
    SERVICE_FILTERS,
    FRONTAGE,
    INTERNAL,
    DISTANCE_FILTERS,
    RURAL_FILTERS,
    RURAL_DROPDOWN_FILTERS,
    ADVANCED_FILTER_FIELD_NAMES,
} from '../../constants/rapidSearch';
import DateFilterConst from '../../constants/rapidSearch/dateFilter';
import Entitlements from '../Entitlements';
import { HOLD_PERIOD_HOUSE, HOLD_PERIOD_UNIT } from '../../constants/batchSearch/batchSearch';

const HUNDRED_DAYS = 100;
const THREE_HUNDRED_SIXTY_FIVE_DAYS = 365;
const RAPID_PROPERTY_TYPE_V2_DEFAULT_VALUES = RAPID_PROPERTY_TYPE_V2.map(opt => opt.value);
const RAPID_PROPERTY_TYPE_V2_DEFAULT_VALUES_TENURE = RAPID_PROPERTY_TYPE_V2_TENURE
    .map(opt => opt.value);
const RURAL_CHECKBOXES_DEFAULT_VALUES = RURAL_FILTERS.map(opt => opt.value);
const RURAL_DROPDOWN_DEFAULT_VALUES = RURAL_DROPDOWN_FILTERS.map(opt => opt.value);
const SERVICE_FILTERS_DEFAULT_VALUES = SERVICE_FILTERS.map(opt => opt.value);
const FRONTAGE_DEFAULT_VALUES = FRONTAGE.map(opt => opt.value);
const DISTANCE_FILTERS_DEFAULT_VALUES = DISTANCE_FILTERS.map(opt => opt.value);
const INTERNAL_CHECKBOX_DEFAULT_VALUES = INTERNAL.checkbox.map(opt => opt.value);
const INTERNAL_RANGE_DEFAULT_VALUES = INTERNAL.range.map(opt => opt.value);
const EXTERNAL_DEFAULT_VALUES = EXTERNAL.filter(opt =>
    ![ADVANCED_FILTER_FIELD_NAMES.lockupGarages,
        ADVANCED_FILTER_FIELD_NAMES.noOfTennisCourts].includes(opt.label)).map(opt => opt.value);

const getFilterActiveTabName = (activeTab) => {
    switch (activeTab) {
        case 0:
            return OTM.ALL;
        case 1:
            return OTM.RECENT_SALE;
        case 2:
            return OTM.FOR_SALE;
        case 3:
            return OTM.FOR_RENT;
        default:
            return OTM.ALL;
    }
};

const convertM2ToHa = (value) => {
    if (value) {
        const splitValue = Commons.splitUnitAndValue(value);
        const { numericValue, unit } = splitValue;
        if (numericValue && !Number.isNaN(parseFloat(numericValue))) {
            let cleanValue = parseInt(Commons.removeCommaAndSpace(numericValue), 10);
            let placeHolder = 'm\u00B2';
            if (unit.toLowerCase() === 'ha') {
                placeHolder = 'Ha';
            } else if (cleanValue >= 10000) {
                cleanValue = Math.round((cleanValue / 10000) * 100) / 100;
                placeHolder = 'Ha';
            }
            return `${Commons.numberWithCommas(cleanValue)}${placeHolder}`;
        }
    }
    return null;
};

// ex. 100,000 => 100k
const abbreviateNumber = (number) => {
    // made sure that number is integer
    parseInt(number, 10);
    if (number < 1000) return number;
    const unit = ['', 'K', 'M', 'B'];
    // Will get the digit count based on number provided
    // and divide by 3 to get the corresponding tier (['', 'K', 'M', 'B'])
    const tier = Math.log10(Math.abs(number)) / 3 | 0;
    const postfix = unit[tier];
    // Math.pow === ** (exponential function)
    const scale = 10 ** (tier * 3);
    const scaled = number / scale;
    let formatted;
    let modulo = 10;
    if (number > 1000000) {
        modulo = 5000;
    }
    if (number % modulo === 0) {
        formatted = (Math.round(scaled * 100) / 100) + postfix;
    } else {
        formatted = Commons.numberWithCommas(number);
    }
    return formatted;
};

const convertPriceUnit = (value) => {
    if (value) {
        const { numericValue, unit } = Commons.splitUnitAndValue(value);
        if (numericValue && !Number.isNaN(parseFloat(numericValue))) {
            if (unit !== '') {
                return `${numericValue}${unit.toUpperCase()}`;
            }
            return abbreviateNumber(Commons.removeCommaAndSpace(numericValue));
        }
    }
    return null;
};

function getDefaultDate(tab, currentDateFilter) {
    const defaultDate = moment();
    const monthsUnit = 'months';
    const dateFormat = 'YYYYMMDD';
    const forSaleMonths = 6;
    const otmMonths = 3;
    let defaultDateVal = '';
    switch (tab) {
        case 'recentSale': case 1: {
            defaultDate.subtract(forSaleMonths, monthsUnit);
            defaultDateVal = `${defaultDate.format(dateFormat)}-${moment().format(dateFormat)}`;
            break;
        }
        case 'all': case 0: {
            return '';
        }
        default: {
            defaultDate.subtract(otmMonths, monthsUnit);
            defaultDateVal = `${defaultDate.format(dateFormat)}-${moment().format(dateFormat)}`;
            break;
        }
    }

    // Should not return default date when there is an existing date filter applied.
    if (currentDateFilter && currentDateFilter !== '' && defaultDateVal !== currentDateFilter) {
        return currentDateFilter;
    }
    return defaultDateVal;
}

function getDefaultDateV2(statusType) {
    const defaultDate = moment();
    const monthsUnit = 'months';
    const dateFormat = 'YYYYMMDD';
    const forSaleMonths = 6;
    const otmMonths = 3;
    if (statusType === OTM.RECENT_SALE) {
        defaultDate.subtract(forSaleMonths, monthsUnit);
    } else if (statusType === OTM.FOR_RENT || statusType === OTM.FOR_SALE) {
        defaultDate.subtract(otmMonths, monthsUnit);
    }
    return {
        min: defaultDate.format(dateFormat),
        max: moment().format(dateFormat),
    };
}

function getDefaultWithdrawnDate(amount, isSalesLastCampaign) {
    const defaultDate = moment();
    const daysUnit = 'days';

    return {
        min: defaultDate.subtract(amount, daysUnit).format(DateFilterConst.RAPID_DATE_FORMAT),
        max: isSalesLastCampaign ? moment().subtract(HUNDRED_DAYS, daysUnit).format(DateFilterConst.RAPID_DATE_FORMAT)
            : moment().format(DateFilterConst.RAPID_DATE_FORMAT),
    };
}
// Will remove commas and dollar sign from min and max object.
function constructCleanMinAndMaxValues(attr, isCurrency) {
    Object.keys(attr).forEach((key) => {
        const formatted = parseInt(Commons.getNumericValue(attr[key], isCurrency), 10);
        attr[key] = Number.isNaN(formatted) ? '' : formatted;
    });
}

const constructRapidMultiSelectParams = (field, options = []) => {
    let fieldParams = '';
    if (field && field.length !== options.length) {
        // Some possible values include commas.
        // We need to wrap each value with double quotes as per api doco.
        // eslint-disable-next-line
        fieldParams = `"${field.map(String).map(value => {
            return `${value.replace(/"/g, '"')}`;
        }).join('","')}"`;
    }
    return fieldParams;
};

const addOptionalAdditionalFilter = (
    finalFilter,
    additionalFilterData,
    additionalFilterFieldName,
    condition,
) => {
    if (condition || (condition === undefined && additionalFilterData)) {
        finalFilter[additionalFilterFieldName] = additionalFilterData;
    } else {
        finalFilter[additionalFilterFieldName] = '';
    }
};

const buildRadiusRapidSearchFilter = (
    filter,
    targetPropertyDetail,
    radiusDetail,
) => {
    const final = JSON.parse(JSON.stringify(radiusDetail));
    const targetSuburbOnly = Commons.get(filter, 'radius.suburbOnly');
    final.radius = `${parseFloat(Commons.get(filter, 'radius.distance.value'))}km`;
    if (targetSuburbOnly) {
        final.suburbOnly = true;
    } else {
        delete final.suburbOnly;
    }
    return final;
};

const getDateAndPriceFieldNames = (statusType) => {
    switch (statusType) {
        case OTM.FOR_SALE:
            return {
                price: 'salesLastCampaignLastListedPrice',
                date: 'salesLastCampaignEndDate',
            };
        case OTM.FOR_RENT:
            return {
                price: 'rentalLastCampaignLastListedPrice',
                date: 'rentalLastCampaignEndDate',
            };
        case OTM.AGED:
            return {
                price: 'salesLastCampaignLastListedPrice',
                date: 'salesLastCampaignDaysOnMarket',
            };
        case OTM.WITHDRAWN:
            return  {
                price: 'salesLastCampaignLastListedPrice',
                date: 'salesLastCampaignEndDate',
            }
        default:
            return {
                price: 'salesLastSoldPrice',
                date: 'salesLastSaleContractDate',
            };
    }
};

const sanitizedRadiusSearchDetails = (radiusSearchDetails = {}) => {
    if (!Commons.isObjectEmpty(radiusSearchDetails)) {
        const _radiusSearchDetails = JSON.parse(JSON.stringify(radiusSearchDetails));
        delete _radiusSearchDetails.includeHistoric;
        delete _radiusSearchDetails.locality;
        delete _radiusSearchDetails.propertyId;
        return _radiusSearchDetails;
    }
    return {};
};

const formatPropertyTypeDisplay = (propertyTypes, isTenure) => {
    const options = isTenure ? RAPID_PROPERTY_TYPE_V2_TENURE : RAPID_PROPERTY_TYPE_V2;
    if (propertyTypes.length === 0
        || Commons.isStringArrayEquals(propertyTypes, isTenure
                ? RAPID_PROPERTY_TYPE_V2_DEFAULT_VALUES_TENURE
                : RAPID_PROPERTY_TYPE_V2_DEFAULT_VALUES)) {
        return ALL;
    }
    const selectedPropertyTypes = propertyTypes
        .map((propertyType) => {
            const _propertyType = options
                .find(type => type.value === propertyType);
            if (_propertyType) {
                return _propertyType.label;
            }
            return '';
        });

    const propertyListing = options
        .filter(option => Commons.removeFalsyFromArray(selectedPropertyTypes).includes(option.label))
        .map(type => type.label)
        .join(', '); // filter selected option from the actual list

    return selectedPropertyTypes.length ? propertyListing : '';
};

export const generateHouseHoldPeriod = (holdPeriodHouse) => {
    if (isRangeFilterEmpty(holdPeriodHouse)) {
        return '';
    } else if (holdPeriodHouse.max === '') {
        return `${isAU ? 'House' : 'Residential'}: ${holdPeriodHouse.min}y +`;
    }
    return `${isAU ? 'House' : 'Residential'}: ${holdPeriodHouse.min}y - ${holdPeriodHouse.max}y`;
}

const generateUnitHoldPeriod = (holdPeriodUnit, withComma) => {
    if (isRangeFilterEmpty(holdPeriodUnit)) {
        return '';
    } else if (holdPeriodUnit.max === '') {
        return `${withComma ? ', ' : ''}Unit: ${holdPeriodUnit.min}y +`;
    }
    return `${withComma ? ', ' : ''}Unit: ${holdPeriodUnit.min}y - ${holdPeriodUnit.max}y`;
}
const hasMinMax = (field) => !!field.max && !!field.min;

const formatHoldPeriodDisplay = (
    holdPeriodHouse,
    holdPeriodUnit,
    propertyTypes,
) => {
    const hasHoldPeriodHouseResidential = propertyTypes.includes(RAPID_PROPERTY_TYPE_V2[0].label || hasMinMax(holdPeriodHouse));
    const hasHoldPeriodUnit = propertyTypes.includes(RAPID_PROPERTY_TYPE_V2[1].label || hasMinMax(holdPeriodUnit));
    if (!propertyTypes.length || (hasHoldPeriodHouseResidential && hasHoldPeriodUnit)) {
        return `${generateHouseHoldPeriod(holdPeriodHouse)}${generateUnitHoldPeriod(holdPeriodUnit, hasMinMax(holdPeriodHouse))}`;
    } else if (propertyTypes.includes(RAPID_PROPERTY_TYPE_V2[0].label)) {
        return generateHouseHoldPeriod(holdPeriodHouse);
    } else if (propertyTypes.includes(RAPID_PROPERTY_TYPE_V2[1].label)) {
        return generateUnitHoldPeriod(holdPeriodUnit);
    }
    return '';
};

const formatSubTypeDisplay = subTypes => (subTypes.length === 0 ? ALL : subTypes.join(', '));

const formatRangeDisplay = (
    { min, max },
    isCurrency = false,
    hasPlusSign = true,
) => {
    let _min = min;
    let _max = max;
    if (isCurrency && max) {
        const expandedValue = Commons.expandAbbreviatedValue(max);
        const converted = convertPriceUnit(`${expandedValue || ''}`);
        _max = converted ? `$${converted}` : '';
    }
    if (isCurrency && min) {
        const expandedValue = Commons.expandAbbreviatedValue(min);
        const converted = convertPriceUnit(`${expandedValue || ''}`);
        _min = converted ? `$${converted}` : '';
    }

    const plusSign = hasPlusSign ? '+' : '';
    if (_min && !_max) {
        return `${_min === '6+' ? '6' : _min}${plusSign}`;
    } else if (!_min && _max) {
        return `0 - ${_max}`;
    } else if (_min && _max) {
        if (_min === _max) {
            return hasPlusSign
                ? (_max === '6' ? `${_min}+` : _min)
                : _min;
        }
        if (_max === '6') {
            return `${_min} - ${_max}${plusSign}`;
        }
        return `${_min} - ${_max}`;
    }
    return '';
};

const formatRangeDisplayWithoutPlus = (
    minMax,
    isCurrency = false,
) => formatRangeDisplay(minMax, isCurrency, false);

const formatDateRange = ({ min, max }) => {
    if (min && !max) {
        return `${min}+`;
    } else if (!min && max) {
        return `- ${max}`;
    } else if (max && min) {
        if (min === max) {
            return max;
        }
        return `${min} - ${max}`;
    }
    return '';
};

const formatRangeParam = ({ min, max } = {}, isBedBathCar = false, isMeter = false) => {
    const _min = parseFloat(Commons.expandAbbreviatedValue(min, isMeter));
    const _max = parseFloat(Commons.expandAbbreviatedValue(max, isMeter));
    if (_min && !_max) {
        return `${_min}-`;
    } else if (!_min && _max) {
        return (isBedBathCar && _max === 6) ? '' : `-${_max}`;
    } else if (_min && _max) {
        if (_max === 6 && isBedBathCar) {
            return `${_min}-`;
        } else if (_min === _max) {
            return _min.toString();
        }
        return `${_min}-${_max}`;
    }
    return '';
};

const formatAreaParam = ({ min, max, unit }) => {
    const multiplier = unit === 'ha' ? 10000 : 1;
    const _min = parseFloat(Commons.expandAbbreviatedValue(min)) * multiplier;
    const _max = parseFloat(Commons.expandAbbreviatedValue(max)) * multiplier;
    if (_min && !_max) {
        return `${_min}-`;
    } else if (!_min && _max) {
        return `-${_max}`;
    } else if (_min && _max) {
        if (_min === _max) {
            return _min.toString();
        }
        return `${_min}-${_max}`;
    }
    return '';
};

const getSortDefaultFilter = (sort, isRadiusSearch) => {
    if (sort) {
        if (JSON.parse(sort)[0].attr === 'distance' && !isRadiusSearch) {
            return RapidSearch.SEARCH_DEFAULT_SORT.ADDRESS;
        }
        return JSON.parse(sort);
    } else {
        if (isRadiusSearch) {
            return RapidSearch.SEARCH_DEFAULT_SORT.RADIUS;
        } else {
            return RapidSearch.SEARCH_DEFAULT_SORT.ADDRESS;
        }
    }
};

const getDefaultFilters = (
    isRadiusSearch,
    defaultActiveListingsOnly,
    currentFilters = {},
    tenureFilters = {},
    sort = {},
) => {
    const commons = {
        ...COMMON_INITIAL_FILTERS,
        ...(isRadiusSearch &&
            {
                radius: currentFilters?.radius,
                lat: currentFilters?.lat,
                lon: currentFilters?.lon,
                suburbOnly: currentFilters?.suburbOnly,
            }),
    };
    return ({
        [OTM.ALL]: {
            ...commons,
            sort: getSortDefaultFilter(sort.all, isRadiusSearch),
            [getDateAndPriceFieldNames(OTM.ALL).price]: { min: '', max: '' },
            [getDateAndPriceFieldNames(OTM.ALL).date]: { min: '', max: '' },
            dateFilterSelectOption: DateFilterConst.ALL,
        },
        [OTM.RECENT_SALE]: {
            ...commons,
            agentAdvised: false,
            sort: getSortDefaultFilter(sort.recentSale, isRadiusSearch),
            [getDateAndPriceFieldNames(OTM.RECENT_SALE).price]: { min: '', max: '' },
            [getDateAndPriceFieldNames(OTM.RECENT_SALE).date]: {
                ...getDefaultDateV2(OTM.RECENT_SALE),
            },
            dateFilterSelectOption: DateFilterConst.SIX_MONTHS,
            salesLastSaleIsArmsLength: false,
            salesLastSaleSettlementDate: { min: '', max: '' },
            salesLastSaleSettlementDateOption: DateFilterConst.ALL,
        },
        [OTM.FOR_SALE]: {
            ...commons,
            sort: getSortDefaultFilter(sort.forSale, isRadiusSearch),
            isActiveListingsOnly: Commons.parseBoolean(defaultActiveListingsOnly[OTM.FOR_SALE]),
            [getDateAndPriceFieldNames(OTM.FOR_SALE).price]: { min: '', max: '' },
            [getDateAndPriceFieldNames(OTM.FOR_SALE).date]: {
                ...getDefaultDateV2(OTM.FOR_SALE),
            },
            dateFilterSelectOption: DateFilterConst.THREE_MONTHS,
        },
        [OTM.FOR_RENT]: {
            ...commons,
            sort: getSortDefaultFilter(sort.forRent, isRadiusSearch),
            isActiveListingsOnly: Commons.parseBoolean(defaultActiveListingsOnly[OTM.FOR_RENT]),
            [getDateAndPriceFieldNames(OTM.FOR_RENT).price]: { min: '', max: '' },
            [getDateAndPriceFieldNames(OTM.FOR_RENT).date]: {
                ...getDefaultDateV2(OTM.FOR_RENT),
            },
            rentalLastCampaignLastListedPeriod: RAPID_WEEKLY,
            dateFilterSelectOption: DateFilterConst.THREE_MONTHS,
        },
        [OTM.AGED]: {
            ...commons,
            sort: getSortDefaultFilter(sort.aged, isRadiusSearch),
            isActiveListingsOnly: Commons.parseBoolean(defaultActiveListingsOnly[OTM.AGED]),
            [getDateAndPriceFieldNames(OTM.AGED).price]: { min: '', max: '' },
            salesLastCampaignDaysOnMarket: 60,
            isForSale: true,
        },
        [OTM.WITHDRAWN]: {
            ...commons,
            sort: getSortDefaultFilter(sort.withdrawn, isRadiusSearch),
            [getDateAndPriceFieldNames(OTM.WITHDRAWN).price]: { min: '', max: '' },
            [getDateAndPriceFieldNames(OTM.FOR_SALE).date]: {
                ...getDefaultWithdrawnDate(THREE_HUNDRED_SIXTY_FIVE_DAYS, true),
            },
            [getDateAndPriceFieldNames(OTM.RECENT_SALE).date]: {
                ...getDefaultWithdrawnDate(HUNDRED_DAYS),
            },
        },
        [OTM.TENURE]: {
            ...commons,
            isForSale: false,
            sort: getSortDefaultFilter(sort.tenure, isRadiusSearch),
            [getDateAndPriceFieldNames(OTM.TENURE).date]: { min: '', max: '' },
            [getDateAndPriceFieldNames(OTM.TENURE).price]: { min: '', max: '' },
            holdPeriodHouse: { max: '', min: '' },
            holdPeriodUnit: { max: '', min: '' },
            ...tenureFilters,
        },
    });
};

const getDefaultFiltersV2 = (filters) => {
    const filtersForEveryStatusType = JSON.parse(JSON.stringify({
        ...COMMON_INITIAL_FILTERS,
        ...filters.base,
    }));
    return {
        [OTM.ALL]: {
            ...filtersForEveryStatusType,
            salesLastSoldPrice: { min: '', max: '' },
            salesLastSaleContractDate: { min: '', max: '' },
            salesLastSaleSettlementDate: { min: '', max: '' },
            salesLastSaleSettlementDateOption: DateFilterConst.ALL,
            dateFilterSelectOption: DateFilterConst.ALL,
            ...filters.all,
        },
        [OTM.RECENT_SALE]: {
            ...filtersForEveryStatusType,
            agentAdvised: false,
            salesLastSoldPrice: { min: '', max: '' },
            salesLastSaleContractDate: getDefaultDateV2(OTM.RECENT_SALE),
            dateFilterSelectOption: DateFilterConst.SIX_MONTHS,
            salesLastSaleIsArmsLength: false,
            salesLastSaleSettlementDate: { min: '', max: '' },
            salesLastSaleSettlementDateOption: DateFilterConst.ALL,
            ...filters.recentSale,
        },
        [OTM.FOR_SALE]: {
            ...filtersForEveryStatusType,
            salesLastCampaignLastListedPrice: { min: '', max: '' },
            salesLastCampaignEndDate: getDefaultDateV2(OTM.FOR_SALE),
            dateFilterSelectOption: DateFilterConst.THREE_MONTHS,
            ...filters.forSale,
        },
        [OTM.FOR_RENT]: {
            ...filtersForEveryStatusType,
            rentalLastCampaignLastListedPrice: { min: '', max: '' },
            rentalLastCampaignEndDate: getDefaultDateV2(OTM.FOR_RENT),
            rentalLastCampaignLastListedPeriod: RAPID_WEEKLY,
            dateFilterSelectOption: DateFilterConst.THREE_MONTHS,
            ...filters.forRent,
        },
        [OTM.AGED]: {
            ...filtersForEveryStatusType,
            isActiveListingsOnly: false,
            salesLastCampaignLastListedPrice: { min: '', max: '' },
            salesLastCampaignDaysOnMarket: 60,
            isForSale: true,
            ...filters.aged,
        },
        [OTM.WITHDRAWN]: {
            ...filtersForEveryStatusType,
            salesLastCampaignLastListedPrice: { min: '', max: '' },
            salesLastCampaignEndDate: getDefaultWithdrawnDate(THREE_HUNDRED_SIXTY_FIVE_DAYS, true),
            salesLastSaleContractDate: getDefaultWithdrawnDate(HUNDRED_DAYS),
            ...filters.withdrawn,
        },
        [OTM.TENURE]: {
            ...filtersForEveryStatusType,
            isForSale: false,
            salesLastSoldPrice: { min: '', max: '' },
            salesLastSaleContractDate: { min: '', max: '' },
            holdPeriodHouse: { min: '', max: '' },
            holdPeriodUnit: { min: '', max: '' },
            ...filters.tenure,
        },
    };
};

const updateUrl = (keyValueList) => {
    try {
        const href = new URL(window.location.href);
        if (keyValueList) {
            keyValueList.forEach((item) => {
                href.searchParams.set(item.key, item.value);
            });
        }
        window.history.replaceState({}, '', href.toString());
    } catch (e) {
        Bugsnag.notify(e);
    }
};

const processRecentSaleFilters = ({ processedFilters, field, value, willProceed }, filters) => {
    addOptionalAdditionalFilter(processedFilters, value, field, willProceed);
    if (isNZ && filters.salesClassification) {
        processedFilters.salesClassification = filters.salesClassification;
    }
    if (filters.salesLastSaleIsArmsLength) {
        processedFilters.salesLastSaleIsArmsLength = filters.salesLastSaleIsArmsLength;
    }
    if (filters.salesLastSaleSettlementDate) {
        processedFilters.salesLastSaleSettlementDate = formatRangeParam(filters.salesLastSaleSettlementDate);
    }
};

const processForSaleFilters = ({ processedFilters, field, value }, filters) => {
    addOptionalAdditionalFilter(processedFilters, value, field);
    if (filters.salesLastCampaignListedType) {
        const allListingTypeSelected = Commons.isStringArrayEquals(
            filters.salesLastCampaignListedType,
            LISTING_TYPE_V2.map(listingType => listingType.value),
        );
        if (!allListingTypeSelected) {
            processedFilters.salesLastCampaignListedType =
                filters.salesLastCampaignListedType.toString();
        }
    }
    if (filters.salesLastCampaignStartDate) {
        processedFilters.salesLastCampaignStartDate = formatRangeParam(filters.salesLastCampaignStartDate);
    }
};

const processForRentFilters = ({ processedFilters, field, value }, filters) => {
    addOptionalAdditionalFilter(processedFilters, value, field);
    if (filters.rentalLastCampaignStartDate) {
        processedFilters.rentalLastCampaignStartDate = formatRangeParam(filters.rentalLastCampaignStartDate);
    }
};

const processAgedFilters = (processedFilters, salesLastCampaignDaysOnMarket, isActiveListingsOnly) => {
    processedFilters.isForSale = true;
    processedFilters.isActiveForSaleCampaign = isActiveListingsOnly;
    processedFilters.salesLastCampaignDaysOnMarket = salesLastCampaignDaysOnMarket;
};

const processWithdrawnFilters = (processedFilters, salesLastCampaignEndDate, salesLastSaleContractDate) => {
    processedFilters.salesLastCampaignEndDate = formatRangeParam(salesLastCampaignEndDate);
    processedFilters.salesLastSaleContractDate = formatRangeParam(salesLastSaleContractDate);
};

const processTenureFilters = (processedFilters) => {
    processedFilters.isForSale = false;
};

const filterProcessorPerStatus = {
    [OTM.ALL]: () => {},
    [OTM.RECENT_SALE]: (processedFilters, filters) => processRecentSaleFilters({
            processedFilters,
            field: 'salesLastSaleSource',
            value: RapidSearch.VALUER_GENERAL,
            willProceed: !!filters.agentAdvised
        }, filters),
    [OTM.FOR_SALE]: (processedFilters, filters) => processForSaleFilters({
            processedFilters,
            field: 'isActiveForSaleCampaign',
            value: filters.isActiveListingsOnly,
        }, filters),
    [OTM.FOR_RENT]: (processedFilters, filters) => processForRentFilters({
        processedFilters,
        field: 'isActiveForRentCampaign',
        value: filters.isActiveListingsOnly,
    }, filters),
    [OTM.AGED]: (processedFilters, filters) =>
        processAgedFilters(processedFilters, filters.salesLastCampaignDaysOnMarket, filters.isActiveListingsOnly),
    [OTM.WITHDRAWN]: (processedFilters, filters) =>
        processWithdrawnFilters(processedFilters, filters.salesLastCampaignEndDate, filters.salesLastSaleContractDate),
    [OTM.TENURE]: (processedFilters) =>
        processTenureFilters(processedFilters),
};

// Parse clean filter that can be sent directly to search results saga
const getProcessedFilters = (filters, statusType) => {
    const processedFilters = {
        offset: 0, // default page
    };

    const {
        type, subType, rentalLastCampaignLastListedPeriod,
        developmentZoneDescription, landUse,
        sort, radius, lat, lon, suburbOnly, feeCode, heritageOtherClassification,
    } = filters;

    const { price, date } = getDateAndPriceFieldNames(statusType);
    const ranges = [ price, date, HOLD_PERIOD_HOUSE, HOLD_PERIOD_UNIT, ...PROCESSED_FILTER_RANGES ];

    ranges.forEach((range) => {
        if (range) {
            let formattedRange;
            if (RapidSearch.AREA_FILTERS.includes(range)) {
                const landArea = {
                    min: `${filters[range].min}${filters[range].unit}`,
                    max: `${filters[range].max}${filters[range].unit}`,
                };
                constructCleanMinAndMaxValues(landArea);
                formattedRange = formatAreaParam(landArea);
            } else if (range === 'floorArea') {
                const floorArea = {...filters[range]};
                constructCleanMinAndMaxValues(floorArea);
                formattedRange = formatRangeParam(floorArea);
            } else {
                formattedRange = formatRangeParam(
                    filters[range],
                    ['beds', 'carSpaces', 'baths'].includes(range),
                    ['streetFrontage'].includes(range),
                )
            }
            processedFilters[range] = formattedRange;
            if (formattedRange && range === 'rentalLastCampaignLastListedPrice') {
                processedFilters.rentalLastCampaignLastListedPeriod =
                    rentalLastCampaignLastListedPeriod;
            }
        }
    });

    if (isAU) {
        DISTANCE_FILTERS_DEFAULT_VALUES.forEach((value) => {
            processedFilters[value] = formatRangeParam(filters[value]);
        });

        INTERNAL_CHECKBOX_DEFAULT_VALUES.forEach((value) => {
            let internalCheckboxValues = filters[value];
            if (internalCheckboxValues) {
                processedFilters[value] = internalCheckboxValues;
            }
        });

        INTERNAL_RANGE_DEFAULT_VALUES.forEach((value) => {
            processedFilters[value] = formatRangeParam(filters[value]);
        });

        RURAL_CHECKBOXES_DEFAULT_VALUES.forEach((value) => {
            let ruralCheckboxValues = filters[value];
            if (ruralCheckboxValues) {
                processedFilters[value] = ruralCheckboxValues;
            }
        });

        RURAL_DROPDOWN_DEFAULT_VALUES.forEach((value) => {
            processedFilters[value] = formatRangeParam(filters[value]);
        });

        SERVICE_FILTERS_DEFAULT_VALUES.forEach((value) => {
            const serviceValues = filters[value];
            if (serviceValues) {
                processedFilters[value] = serviceValues;
            }
        });

        FRONTAGE_DEFAULT_VALUES.forEach((value) => {
            let frontageValues = filters[value];
            if (frontageValues) {
                processedFilters[value] = frontageValues;
            }
        });

        if (filters.freeStandingBuilding) {
            processedFilters.freeStandingBuilding = filters.freeStandingBuilding;
        }

        if (feeCode) {
            processedFilters.feeCode = feeCode;
        }

        if (heritageOtherClassification) {
            processedFilters.heritageOtherClassification = heritageOtherClassification;
        }
    }

    EXTERNAL_DEFAULT_VALUES.forEach((value) => {
        let externalValues = filters[value];
        if (externalValues) {
            processedFilters[value] = externalValues;
        }
    });

    if (type) {
        if (!Commons.isStringArrayEquals(type, RAPID_PROPERTY_TYPE_V2_DEFAULT_VALUES)) {
            processedFilters.type = type.toString();
        } else {
            processedFilters.type = '';
        }
    }
    if (subType) {
        processedFilters.subType = constructRapidMultiSelectParams(subType);
    }

    if (developmentZoneDescription) {
        processedFilters.developmentZoneDescription =
            constructRapidMultiSelectParams(developmentZoneDescription);
    }
    if (landUse) {
        processedFilters.landUse = constructRapidMultiSelectParams(landUse);
    }

    processedFilters.sort = sort;

    filterProcessorPerStatus[statusType](processedFilters, filters);

    if (radius) {
        processedFilters.radius = radius;
        processedFilters.suburbOnly = suburbOnly;
        processedFilters.lat = lat;
        processedFilters.lon = lon;
    }
    return processedFilters;
};

const FilterHandler = {
    _source: {},
    _target: {
        offset: 0, // default page
    },
    _statusType: OTM.ALL,
    _specific: {
        type() {
            if (this.parent._source.type) {
                if (!Commons.isStringArrayEquals(this.parent._source.type, RAPID_PROPERTY_TYPE_V2_DEFAULT_VALUES)) {
                    this.parent._target.type = this.parent._source.type.toString();
                } else {
                    this.parent._target.type = '';
                }
            }
        },
        agentAdvised() {
            if ('agentAdvised' in this.parent._source
                && !!this.parent._source.agentAdvised) {
                this.parent._target.salesLastSaleSource = RapidSearch.VALUER_GENERAL;
            }
        },
        isActiveListingsOnly() {
            if ('isActiveListingsOnly' in this.parent._source
                && this.parent._source.isActiveListingsOnly) {
                if (this.parent._statusType === OTM.FOR_RENT) {
                    this.parent._target.isActiveForRentCampaign = this.parent._source.isActiveListingsOnly;
                } else {
                    this.parent._target.isActiveForSaleCampaign = this.parent._source.isActiveListingsOnly;
                }
            }
        },
        salesLastCampaignListedType() {
            if ('salesLastCampaignListedType' in this.parent._source) {
                const allListingTypeSelected = Commons.isStringArrayEquals(
                    this.parent._source.salesLastCampaignListedType,
                    LISTING_TYPE_V2.map(listingType => listingType.value),
                );
                if (!allListingTypeSelected) {
                    this.parent._target.salesLastCampaignListedType =
                        this.parent._source.salesLastCampaignListedType.toString();
                }
            }
        },
        tenureData() {
            if (HOLD_PERIOD_UNIT in this.parent._source
                || HOLD_PERIOD_HOUSE in this.parent._source) {
                this.parent._target.isForSale = false;
                this.parent._target.tenureData = {
                    [HOLD_PERIOD_UNIT]: this.parent._source[HOLD_PERIOD_UNIT],
                    [HOLD_PERIOD_HOUSE]: this.parent._source[HOLD_PERIOD_HOUSE],
                    type: this.parent._source.type.toString(),
                }
            }
        },
        streetFrontage() {
            if (this.parent._source.streetFrontage?.min
                || this.parent._source.streetFrontage?.max) {
                this.parent._target.streetFrontage = {
                    min: Commons.expandAbbreviatedValue(this.parent._source.streetFrontage.min, true),
                    max: Commons.expandAbbreviatedValue(this.parent._source.streetFrontage.max, true),
                }
            }
        },
        rentalLastCampaignLastListedPeriod() {
            if (this.parent._source.rentalLastCampaignLastListedPrice?.min
                || this.parent._source.rentalLastCampaignLastListedPrice?.max) {
                this.parent._target.rentalLastCampaignLastListedPeriod = this.parent._source.rentalLastCampaignLastListedPeriod;
            }
        },
        suburbOnly() {
            if (this.parent._source.suburbOnly) {
                this.parent._target.addressPostcode = this.parent._source.addressPostcode;
                this.parent._target.addressPostcodeState = this.parent._source.addressPostcodeState;
                this.parent._target.addressTown = this.parent._source.addressTown;
                this.parent._target.addressSuburb = this.parent._source.addressSuburb;
            }
        }
    },
    source(value) {
        this._source = value;
        return this;
    },
    target(value) {
        this._target = value;
        return this;
    },
    statusType(value) {
        this._statusType = value;
        return this;
    },
    default(field) {
        if (typeof this._source[field] === 'object'
            && ('min' in this._source[field]
                && 'max' in this._source[field])) {
            if (this._source[field]?.min
                || this._source[field]?.max) {
                this._target[field] = {
                    min: `${Commons.expandAbbreviatedValue(this._source[field].min)}`,
                    max: `${Commons.expandAbbreviatedValue(this._source[field].max)}`,
                    unit: this._source[field]?.unit,
                };
            }
        } else if (this._source[field]) {
            this._target[field] = this._source[field];
        }
    },
    boolean(field) {
        if (field in this._source
            && this._source[field]) {
            this.default(field);
        }
    },
    optional(field) {
        if (field in this._source) {
            this.default(field);
        }
    },
    multi(field) {
        this._target[field] = constructRapidMultiSelectParams(this._source[field]);
    },
    specific(field) {
        if (field in this._specific) {
            this._specific.parent ??= this;
            this._specific[field]();
        }
    },
    getFilters() {
        return this._target;
    },
};

const BOOLEAN_FILTERS = INTERNAL_CHECKBOX_DEFAULT_VALUES
    .concat(RURAL_CHECKBOXES_DEFAULT_VALUES)
    .concat(SERVICE_FILTERS_DEFAULT_VALUES)
    .concat(FRONTAGE_DEFAULT_VALUES)
    .concat(['freeStandingBuilding', 'feeCode', 'heritageOtherClassification']);
const OPTIONAL_FILTERS = DISTANCE_FILTERS_DEFAULT_VALUES
    .concat(INTERNAL_RANGE_DEFAULT_VALUES)
    .concat(RURAL_DROPDOWN_DEFAULT_VALUES)
    .concat([
        'keywords',
        'salesClassification',
        'salesLastSaleIsArmsLength',
        'salesLastSaleSettlementDate',
        'salesLastSoldPrice',
        'salesLastSaleContractDate',
        'salesLastCampaignEndDate',
        'salesLastCampaignLastListedPrice',
        'salesLastCampaignDaysOnMarket',
        'salesLastCampaignStartDate',
        'rentalLastCampaignEndDate',
        'rentalLastCampaignLastListedPrice',
        'rentalLastCampaignStartDate',
        'isForSale',
        'radius',
        'lat',
        'lon',
        'rentalOccupancy',
    ]);
const getRapidFilters = (filters, statusType) => {
    // still need this. In RapidBatchSearch.js if a field is not in there it still insert it in the request body
    const handler = FilterHandler.source(filters)
        .target({
            offset: 0, // default page
        })
        .statusType(statusType);

    PROCESSED_FILTER_RANGES
        .concat(['sort'])
        .forEach(field => handler.default(field));

    EXTERNAL_DEFAULT_VALUES
        .concat(BOOLEAN_FILTERS)
        .forEach(field => handler.boolean(field));

    ['subType', 'developmentZoneDescription', 'landUse']
        .forEach(field => handler.multi(field));

    OPTIONAL_FILTERS.forEach(field => handler.optional(field));

    [
        'type',
        'agentAdvised',
        'salesLastCampaignListedType',
        'isActiveListingsOnly',
        'tenureData',
        'rentalLastCampaignLastListedPeriod',
        'suburbOnly',
        'streetFrontage',
    ].forEach(field => handler.specific(field));

    return handler.getFilters();
};

const getOptionsWithMinAndMax = (options) => {
    // Material UI cannot render the options properly if one is repeating.
    // Key issue
    // https://github.com/mui/material-ui/issues/26492

    // if (hasALongList) {
    //     _options = {
    //         min: [MIN_OPTION, ...options],
    //         max: [MAX_OPTION, ...options, MAX_OPTION],
    //     };
    // }
    return { min: [MIN_OPTION, ...options], max: [MAX_OPTION, ...options] };
};

const isSuburbOnly = (searchParam) => {
    const suburbOnly = new URLSearchParams(searchParam).get('suburbOnly');
    return !!suburbOnly && suburbOnly === 'true';
};

const setValuesToTypes = (state, values, activeStatusType) => {
    Object.keys(OTM).forEach((_key) => {
        const searchType = OTM[_key];
        if (searchType !== activeStatusType) {
            state[searchType] = { ...state[searchType], ...values };
        }
    });
};

const handleResetAcrossTypes = (state, activeStatusType, defaultState, isRadiusSearch) => {
    const _state = JSON.parse(JSON.stringify(state));
    if (isRadiusSearch) {
        // stored into a variable just for readability purposes
        const isRadiusDirty = state[activeStatusType].radius !==
            defaultState[activeStatusType].radius;
        const isSuburbDirty = state[activeStatusType].suburbOnly !==
            defaultState[activeStatusType].suburbOnly;
        // Because radius and suburbOnly are global filters,
        // they need to be reverted across status types
        if (isRadiusDirty || isSuburbDirty) {
            setValuesToTypes(
                _state,
                {
                    radius: defaultState.all.radius,
                    suburbOnly: defaultState.all.suburbOnly,
                },
                activeStatusType,
            );
        }
    }
    // Resetting filters only in active tab
    _state[activeStatusType] = defaultState[activeStatusType];
    return _state;
};

const handlePreviousStateAcrossTypes = (
    state,
    incomingState,
    hasImmediateFilters,
    activeStatusType,
    filters,
    defaultState,
    isRadiusSearch,
) => {
    const _state = JSON.parse(JSON.stringify(state));
    if (isRadiusSearch) {
        // stored into a variable just for readability purposes
        const isRadiusDirty = incomingState[activeStatusType].radius !==
            defaultState[activeStatusType].radius;
        const isSuburbDirty = incomingState[activeStatusType].suburbOnly !==
            defaultState[activeStatusType].suburbOnly;
        // Because radius and suburbOnly are global filters,
        // they need to be stored across status types
        if ((isRadiusDirty || isSuburbDirty) && !hasImmediateFilters) {
            setValuesToTypes(
                _state,
                {
                    radius: incomingState[activeStatusType].radius,
                    suburbOnly: incomingState[activeStatusType].suburbOnly,
                },
                activeStatusType,
            );
        }
    }
    _state[activeStatusType] = {
        ...(!hasImmediateFilters ? incomingState[activeStatusType] : state[activeStatusType]),
        ...filters,
    };
    return _state;
};

const isEntitledToSearchType = (checker, clapiUsrDetail) => !!checker ?
    Entitlements[checker](clapiUsrDetail)
    : true;

const isRangeFilterEmpty = filter => Commons.isEqual(filter, { min: 0, max: 0 });

const getPropertyTypesOptionsDisabled = filters => [
    ...(isRangeFilterEmpty(filters.holdPeriodHouse)
        ? [RAPID_PROPERTY_TYPE_V2[0].value]
        : []),
    ...(isAU && isRangeFilterEmpty(filters.holdPeriodUnit)
        ? [RAPID_PROPERTY_TYPE_V2[1].value]
        : []),
].flat();

export default {
    getFilterActiveTabName,
    buildRadiusRapidSearchFilter,
    getDefaultDate,
    convertM2ToHa,
    convertPriceUnit,
    sanitizedRadiusSearchDetails,
    formatPropertyTypeDisplay,
    formatSubTypeDisplay,
    formatRangeDisplayWithoutPlus,
    formatRangeDisplay,
    getDateAndPriceFieldNames,
    getDefaultDateV2,
    getDefaultWithdrawnDate,
    formatRangeParam,
    getDefaultFilters,
    getProcessedFilters,
    getOptionsWithMinAndMax,
    isSuburbOnly,
    updateUrl,
    handleResetAcrossTypes,
    handlePreviousStateAcrossTypes,
    formatDateRange,
    constructRapidMultiSelectParams,
    isEntitledToSearchType,
    formatHoldPeriodDisplay,
    generateHouseHoldPeriod,
    generateUnitHoldPeriod,
    isRangeFilterEmpty,
    getPropertyTypesOptionsDisabled,
    getSortDefaultFilter,
    getDefaultFiltersV2,
    getRapidFilters,
};
