import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import '../../../css/crux/components/portal/portal.scss';

const Portal = (props) => {
    const iFrame = React.createRef();
    const { src, height, width } = props;
    const [isLoaded, setLoading] = React.useState(false);
    const handleIFrameOnLoad = () => {
        setLoading(true);
    };
    return (
        <div
            className="crux-portal-container"
            data-testid="portal-container"
        >
            <BlockUi blocking={!isLoaded} renderChildren={!!src} className="white-blocker">
                <iframe
                    title="report-portal"
                    data-testid="portal"
                    ref={iFrame}
                    src={src}
                    height={height}
                    width={width}
                    onLoad={handleIFrameOnLoad}
                />
            </BlockUi>
        </div>
    );
};

Portal.propTypes = {
    src: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

Portal.defaultProps = {
    height: '100%',
    width: '100%',
};

export default Portal;
