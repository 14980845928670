import { applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createTracker } from 'redux-segment';
import ReduxLogger from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import BlockUiMiddleware from 'react-block-ui/lib/reduxMiddleware';

const { NODE_ENV } = process.env;
const DEVELOPMENT = 'development';
export const sagaMiddleware = createSagaMiddleware();
const segmentTracker = createTracker();
const getMiddlewares = () => {
    if (NODE_ENV === DEVELOPMENT) {
        return composeWithDevTools(applyMiddleware(sagaMiddleware, BlockUiMiddleware, ReduxLogger));
    }
    return applyMiddleware(sagaMiddleware, BlockUiMiddleware, segmentTracker);
};

const middlewares = getMiddlewares();

export default middlewares;
