import Commons from './Commons';
import Strings from './Strings';
import AttributesUnits from '../constants/savedSearch/searchAttributesUnits';
import SearchFilterHelper from './search/SearchFilterHelper';
import { isAU } from '../constants/crux';
import SavedSearchHelper from './search/SavedSearchHelper';

const convertMeterSquaredToHectares = meterSquared => (Number(meterSquared).valueOf() / 10000).toFixed(2);

const refinePropertyAttributes = (attributes) => {
    let formattedAttribute = {
        bedrooms: '-',
        bathrooms: '-',
        carSpaces: '-',
        landArea: {
            value: '-',
            unit: '',
        },
        floorArea: {
            value: '-',
            unit: '',
        },
    };

    if (attributes) {
        const {
            bedrooms,
            beds,
            bed,
            bathrooms,
            baths,
            bath,
            carSpaces,
            car,
            floorArea,
            landArea,
            distanceFromPoint,
        } = attributes;
        const _landArea = parseInt(landArea);
        const _floorArea = parseInt(floorArea);
        formattedAttribute = {
            bedrooms: parseInt(bedrooms || beds || bed) || '-',
            bathrooms: parseInt(bathrooms || baths || bath) || '-',
            carSpaces: parseInt(carSpaces || car) || '-',
            landArea: {
                value: _landArea || '-',
                unit: (_landArea && 'm²') || '',
            },
            floorArea: {
                value: _floorArea || '-',
                unit: (_floorArea && 'm²') || '',
            },
            distanceFromPoint,
        };

        // convert land area and/or floor area
        if (landArea > 10000) {
            let formattedLandArea = convertMeterSquaredToHectares(landArea);
            formattedLandArea = Commons.numberWithCommas(formattedLandArea, 2);
            formattedAttribute.landArea.value = formattedLandArea;
            formattedAttribute.landArea.unit = 'Ha';
        } else {
            formattedAttribute.landArea.value =
                Commons.numberWithCommas(formattedAttribute.landArea.value);
        }

        if (floorArea > 10000) {
            let formattedFloorArea = convertMeterSquaredToHectares(floorArea);
            formattedFloorArea = Commons.numberWithCommas(formattedFloorArea, 2);
            formattedAttribute.floorArea.value = formattedFloorArea;
            formattedAttribute.floorArea.unit = 'Ha';
        } else {
            formattedAttribute.floorArea.value =
                Commons.numberWithCommas(formattedAttribute.floorArea.value);
        }
    }

    return formattedAttribute;
};

const refineSearchValueUnit = (value, valueUnit) => {
    const {
        AREA_UNIT,
        PRICE_UNIT,
        DATE_UNIT,
        DISTANCE_UNIT,
        DISTANCE_METER_UNIT,
    } = AttributesUnits;

    if (valueUnit === AREA_UNIT) {
        if (value > 10000) {
            let formattedAreaNumber = convertMeterSquaredToHectares(value);
            formattedAreaNumber = Commons.numberWithCommas(formattedAreaNumber, 2);
            return `${formattedAreaNumber} Ha`;
        }
        const formattedAreaNumber = Commons.numberWithCommas(value);
        return `${formattedAreaNumber} m²`;
    }
    if (valueUnit === DISTANCE_METER_UNIT) {
        const formattedAreaNumber = Commons.numberWithCommas(value);
        return `${formattedAreaNumber} m`;
    }
    if (valueUnit === DISTANCE_UNIT) {
        const formattedAreaNumber = Commons.numberWithCommas(value);
        return `${formattedAreaNumber} km`;
    }
    if (valueUnit === PRICE_UNIT) {
        const formattedAreaNumber = Commons.numberWithCommas(value);
        return `$${formattedAreaNumber}`;
    }
    if (valueUnit === DATE_UNIT) {
        const dateString = value.toString();
        if (dateString.length > 4) {
            const formattedDate = dateString.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
            return Commons.formatDate(formattedDate, 'DD MMM YYYY');
        }
    }
    return value;
};

const refineSearchValueRange = (value, valueUnit) => {
    const {
        DATE_UNIT,
        PURE_NUMBER,
    } = AttributesUnits;
    if (!value || value === '') {
        return 'Any';
    }

    if (`${value}`.includes('-')) {
        let valueFrom = parseFloat(value.substring(0, value.indexOf('-')));
        let valueTo = parseFloat(value.substring(value.indexOf('-') + 1));

        let displayValue = 'Any';
        if (valueFrom && valueTo) {
            valueFrom = refineSearchValueUnit(valueFrom, valueUnit);
            valueTo = refineSearchValueUnit(valueTo, valueUnit);
            const valRange = SearchFilterHelper.formatRangeDisplay({ min: valueFrom.toString(), max: valueTo.toString() });
            const valRangeWholeNumber = SearchFilterHelper.formatRangeDisplayWithoutPlus({ min: valueFrom.toString(), max: valueTo.toString() });
            displayValue = (valueUnit === PURE_NUMBER) ? valRange : valRangeWholeNumber;
        } else if (valueFrom) {
            valueFrom = refineSearchValueUnit(valueFrom, valueUnit);
            displayValue = `${valueFrom}+`;
        } else if (valueTo) {
            valueTo = refineSearchValueUnit(valueTo, valueUnit);
            displayValue = (valueUnit === DATE_UNIT) ? `1800 - ${valueTo}` : `0 - ${valueTo}`;
        }
        return displayValue;
    }
    return refineSearchValueUnit(value, valueUnit);
};

const refineDateRange = (value, valueUnit, customDateAttributes = {}) => {
    const { isCustomDate = [], optionName = '' } = customDateAttributes;
    if (value && !isCustomDate.includes(optionName)) {
        const fromAndToList = value.split('-');
        if (fromAndToList.length === 2) {
            const preset = SavedSearchHelper.getMonthDiff(fromAndToList);
            return preset === 1 ? 'Last Month' : `Last ${preset} Months`;
        }
    }
    return refineSearchValueRange(value, valueUnit);
};

const refineNumericSearchAttributes = (attributes) => {
    const {
        AREA_UNIT,
        PRICE_UNIT,
        DATE_UNIT,
        PURE_NUMBER,
        DISTANCE_UNIT,
        DISTANCE_METER_UNIT,
    } = AttributesUnits;

    if (attributes) {
        const {
            bedrooms,
            beds,
            bed,
            bathrooms,
            baths,
            bath,
            carSpaces,
            car,
            landArea,
            yearBuilt,
            salesLastSaleContractDate,
            salesLastSoldPrice,
            salesLastCampaignEndDate,
            salesLastCampaignStartDate,
            salesLastCampaignLastListedPrice,
            salesLastSaleSettlementDate,
            rentalLastCampaignEndDate,
            rentalLastCampaignStartDate,
            rentalLastCampaignLastListedPrice,
            distanceToHospital,
            distanceToRiverFrontage,
            distanceToSchool,
            distanceToSeaRiverCanal,
            distanceToShops,
            unimprovedCapitalValueDate,
            currentUnimprovedLandValue,
            unimprovedCapitalValue,
            housesOnFarm,
            irrigatedArea,
            damsBores,
            paddocksYards,
            factoryArea,
            mezzanineArea,
            officeShowroomArea,
            otherArea,
            shopArea,
            showroomArea,
            warehouseArea,
            workshopArea,
            diningRooms,
            familyRumpusRooms,
            floorLevelsInside,
            loungeRooms,
            loungeDiningRoomsCombined,
            kitchens,
            noOfStudyRooms,
            toilets,
            lockupGarages,
            noOfTennisCourts,
            buildingArea,
            equivalentBuildingArea,
            floorLevelOfUnit,
            floorArea,
            floorLevelsInsideUnit,
            flats,
            streetFrontage,
            totalFloorsInBuilding,
            yearBuildingRefurbished,
            yearEstablished,
            valuationLandValue,
            valuationImprovementsValue,
            valuationAssessmentDate,
            valuationCapitalValue,
            isCustomDate,
        } = attributes;
        const buildCustomDateAttr = optionName => ({ isCustomDate, optionName });
        if (isAU) {
            return {
                bedrooms: refineSearchValueRange(bedrooms || beds || bed, PURE_NUMBER),
                bathrooms: refineSearchValueRange(bathrooms || baths || bath, PURE_NUMBER),
                carSpaces: refineSearchValueRange(carSpaces || car, PURE_NUMBER),
                landArea: refineSearchValueRange(landArea, AREA_UNIT),
                yearBuilt: refineSearchValueRange(yearBuilt, DATE_UNIT),
                salesLastSaleContractDate: refineDateRange(salesLastSaleContractDate, DATE_UNIT, buildCustomDateAttr('date')),
                salesLastSoldPrice: refineSearchValueRange(salesLastSoldPrice, PRICE_UNIT),
                salesLastCampaignEndDate: refineDateRange(salesLastCampaignEndDate, DATE_UNIT, buildCustomDateAttr('date')),
                salesLastCampaignStartDate: refineDateRange(salesLastCampaignStartDate, DATE_UNIT, buildCustomDateAttr('salesLastCampaignStartDate')),
                salesLastCampaignLastListedPrice:
                    refineSearchValueRange(salesLastCampaignLastListedPrice, PRICE_UNIT),
                rentalLastCampaignEndDate: refineDateRange(rentalLastCampaignEndDate, DATE_UNIT, buildCustomDateAttr('date')),
                rentalLastCampaignStartDate: refineDateRange(rentalLastCampaignStartDate, DATE_UNIT, buildCustomDateAttr('rentalLastCampaignStartDate')),
                rentalLastCampaignLastListedPrice:
                    refineSearchValueRange(rentalLastCampaignLastListedPrice, PRICE_UNIT),
                distanceToHospital: refineSearchValueRange(distanceToHospital, DISTANCE_UNIT),
                distanceToRiverFrontage: refineSearchValueRange(distanceToRiverFrontage, DISTANCE_UNIT),
                distanceToSchool: refineSearchValueRange(distanceToSchool, DISTANCE_UNIT),
                distanceToSeaRiverCanal: refineSearchValueRange(distanceToSeaRiverCanal, DISTANCE_UNIT),
                distanceToShops: refineSearchValueRange(distanceToShops, DISTANCE_UNIT),
                housesOnFarm: refineSearchValueRange(housesOnFarm),
                irrigatedArea: refineSearchValueRange(irrigatedArea),
                damsBores: refineSearchValueRange(damsBores),
                paddocksYards: refineSearchValueRange(paddocksYards),
                factoryArea: refineSearchValueRange(factoryArea, AREA_UNIT),
                mezzanineArea: refineSearchValueRange(mezzanineArea, AREA_UNIT),
                officeShowroomArea: refineSearchValueRange(officeShowroomArea, AREA_UNIT),
                otherArea: refineSearchValueRange(otherArea, AREA_UNIT),
                shopArea: refineSearchValueRange(shopArea, AREA_UNIT),
                showroomArea: refineSearchValueRange(showroomArea, AREA_UNIT),
                warehouseArea: refineSearchValueRange(warehouseArea, AREA_UNIT),
                workshopArea: refineSearchValueRange(workshopArea, AREA_UNIT),
                diningRooms: refineSearchValueRange(diningRooms),
                familyRumpusRooms: refineSearchValueRange(familyRumpusRooms),
                floorLevelsInside: refineSearchValueRange(floorLevelsInside),
                loungeRooms: refineSearchValueRange(loungeRooms),
                loungeDiningRoomsCombined: refineSearchValueRange(loungeDiningRoomsCombined),
                kitchens: refineSearchValueRange(kitchens),
                noOfStudyRooms: refineSearchValueRange(noOfStudyRooms),
                toilets: refineSearchValueRange(toilets),
                lockupGarages: refineSearchValueRange(lockupGarages),
                noOfTennisCourts: refineSearchValueRange(noOfTennisCourts),
                buildingArea: refineSearchValueRange(buildingArea, AREA_UNIT),
                equivalentBuildingArea: refineSearchValueRange(equivalentBuildingArea, AREA_UNIT),
                floorLevelOfUnit: refineSearchValueRange(floorLevelOfUnit),
                floorArea: refineSearchValueRange(floorArea, AREA_UNIT),
                floorLevelsInsideUnit: refineSearchValueRange(floorLevelsInsideUnit),
                flats: refineSearchValueRange(flats),
                streetFrontage: refineSearchValueRange(streetFrontage, DISTANCE_METER_UNIT),
                totalFloorsInBuilding: refineSearchValueRange(totalFloorsInBuilding),
                yearBuildingRefurbished: refineSearchValueRange(yearBuildingRefurbished, DATE_UNIT),
                yearEstablished: refineSearchValueRange(yearEstablished, DATE_UNIT),
                unimprovedCapitalValueDate: refineDateRange(unimprovedCapitalValueDate, DATE_UNIT, buildCustomDateAttr('unimprovedCapitalValueDate')),
                currentUnimprovedLandValue: refineSearchValueRange(currentUnimprovedLandValue, PRICE_UNIT),
                unimprovedCapitalValue: refineSearchValueRange(unimprovedCapitalValue, PRICE_UNIT),
                salesLastSaleSettlementDate: refineDateRange(salesLastSaleSettlementDate, DATE_UNIT, buildCustomDateAttr('salesLastSaleSettlementDate')),
            };
        }
        return {
            bedrooms: refineSearchValueRange(bedrooms || beds || bed, PURE_NUMBER),
            bathrooms: refineSearchValueRange(bathrooms || baths || bath, PURE_NUMBER),
            carSpaces: refineSearchValueRange(carSpaces || car, PURE_NUMBER),
            landArea: refineSearchValueRange(landArea, AREA_UNIT),
            yearBuilt: refineSearchValueRange(yearBuilt, DATE_UNIT),
            salesLastSaleContractDate: refineDateRange(salesLastSaleContractDate, DATE_UNIT, buildCustomDateAttr('date')),
            salesLastSoldPrice: refineSearchValueRange(salesLastSoldPrice, PRICE_UNIT),
            salesLastCampaignEndDate: refineDateRange(salesLastCampaignEndDate, DATE_UNIT, buildCustomDateAttr('date')),
            salesLastCampaignStartDate: refineDateRange(salesLastCampaignStartDate, DATE_UNIT, buildCustomDateAttr('salesLastCampaignStartDate')),
            salesLastCampaignLastListedPrice:
                refineSearchValueRange(salesLastCampaignLastListedPrice, PRICE_UNIT),
            rentalLastCampaignEndDate: refineDateRange(rentalLastCampaignEndDate, DATE_UNIT, buildCustomDateAttr('date')),
            rentalLastCampaignStartDate: refineDateRange(rentalLastCampaignStartDate, DATE_UNIT, buildCustomDateAttr('rentalLastCampaignStartDate')),
            rentalLastCampaignLastListedPrice:
                refineSearchValueRange(rentalLastCampaignLastListedPrice, PRICE_UNIT),
            valuationLandValue: refineSearchValueRange(valuationLandValue, PRICE_UNIT),
            valuationImprovementsValue: refineSearchValueRange(valuationImprovementsValue, PRICE_UNIT),
            floorArea: refineSearchValueRange(floorArea, AREA_UNIT),
            valuationAssessmentDate: refineDateRange(valuationAssessmentDate, DATE_UNIT, buildCustomDateAttr('valuationAssessmentDate')),
            valuationCapitalValue: refineSearchValueRange(valuationCapitalValue, PRICE_UNIT),
            salesLastSaleSettlementDate: refineDateRange(salesLastSaleSettlementDate, DATE_UNIT, buildCustomDateAttr('salesLastSaleSettlementDate')),
        };
    }

    return {};
};

const refinePropertyType = (attribute) => {
    if (attribute) {
        return attribute.replace(/"/g, '')
            .replace(/, /g, ',').toLowerCase()
            .replace(/(^|,)\w/g, m => m.toUpperCase())
            .replace(/,/g, ', ');
    }
    return attribute;
};

const refineTextSearchAttribute = (attribute) => {
    if (attribute) {
        return attribute.replace(/"/g, '').replace(/,/g, ', ');
    }
    return attribute;
};

const refineTextSearchAttributes = (attributes) => {
    if (attributes) {
        const {
            type,
            subType,
            landUse,
            developmentZoneDescription,
            salesLastCampaignListedType,
        } = attributes;
        return {
            formattedType: refinePropertyType(type),
            formattedSubType: refineTextSearchAttribute(subType),
            formattedLandUse: refineTextSearchAttribute(landUse),
            formattedDevelopmentZoneDescription:
                refineTextSearchAttribute(developmentZoneDescription),
            formattedListingType: refineTextSearchAttribute(salesLastCampaignListedType),
        };
    }

    return {};
};

const transformTargetProperty = (property) => {
    return {
        propertyId: property && property.id,
        defaultPhoto: property.defaultPhoto,
        location: {
            singleLine: Commons.get(property, 'address.singleLine'),
            locallyFormattedAddress: Commons.get(property, 'address.locallyFormattedAddress'),
        },
        attrCore: property && property.attributes,
        lastSale: property && property.lastSale,
    };
};

const formatPricePeriodDate = (price, period, date) => {
    if (price && price !== '' && isNaN(price)) {
        return price;
    }

    const formattedDate = date && date !== '' ? `${Commons.formatDate(date, 'DD MMM YYYY')}` : '';
    const prd = period && period !== '' ? `/${period} ` : ' ';
    const priceAndPeriod = price && price !== '' ? `$${Commons.numberWithCommas(price)}${prd}` : '';
    const on = formattedDate !== '' && priceAndPeriod !== '' ? 'on ' : '';

    if (formattedDate === '' && priceAndPeriod === '') {
        return '-';
    }
    return `${priceAndPeriod}${on}${formattedDate}`;
};

const formatPublishedPrice = (price, date) => {
    let publishedPrice = '-';
    if (price && date) {
        publishedPrice = `${price} on ${Commons.formatDate(date, 'DD MMM YYYY')}`;
    } else if (price) {
        publishedPrice = `${price}`;
    } else if (date) {
        publishedPrice = `${Commons.formatDate(date, 'DD MMM YYYY')}`;
    }
    return publishedPrice;
};

const formatLegalName = (displayName) => {
    if (displayName && displayName !== '') {
        return displayName.replace('/', ' ');
    }
    return displayName;
};

const formatPriceDescription = (priceDescription) => {
    if (priceDescription && (priceDescription.toLowerCase() === 'not disclosed' ||
        priceDescription.toLowerCase() === 'auction')) {
        return 'Listing price not available';
    }
    return priceDescription || '-';
};

const formatPropertyType = (type = '', subType = '') => {
    if (type) {
        const propertyType = Commons.capitalizeFirstLetter(type.toLowerCase());
        return `${propertyType}${subType ? `: ${subType}` : ''}`;
    }
    return '';
};

const formatRentalPrice = (price, period) => {
    const rentalPrice = formatPricePeriodDate(price, period, '');
    if (rentalPrice && rentalPrice !== '' && rentalPrice !== '-' && rentalPrice !== '$0') {
        return rentalPrice;
    }
    return 'Not Disclosed';
};

const formatLegalDescription = (titles) => {
    let legalDescription = '';
    if (titles && titles.length) {
        const distinctLegalDescriptions = [...new Set(titles
            .reduce((acc, title) => acc.concat(title.estates), [])
            .map(estate => estate.legalDescription))];
        legalDescription = Strings.conjunct(distinctLegalDescriptions);
    }
    return legalDescription;
};

const formatDaysOnMarket = (value, placeHolder) => value === 0 || (value && !isNaN(value) && value > 0) ? `${value}` : placeHolder;

export default {
    refineNumericSearchAttributes,
    refineTextSearchAttribute,
    refineTextSearchAttributes,
    refinePropertyAttributes,
    refinePropertyType,
    refineSearchValueRange,
    transformTargetProperty,
    formatPricePeriodDate,
    formatLegalName,
    formatPublishedPrice,
    convertMeterSquaredToHectares,
    formatPriceDescription,
    formatPropertyType,
    formatRentalPrice,
    formatLegalDescription,
    formatDaysOnMarket,
};
