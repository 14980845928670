import { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RPDSidebar from './RPDSidebar/RPDSidebar';
import RPDSidebarLink from './RPDSidebar/RPDSidebarLink';
import RPDSidebarHeader from './RPDSidebar/RPDSidebarHeader';

import RppRedirect from '../../constants/redirect';
import { routeCodes } from '../../constants/routes';
import { navigateToRppEvent, trackEvent } from '../../actions/segment';
import SegmentProperty from '../../constants/segment';
import Commons from '../../helpers/Commons';
import getTitlesToken from '../../helpers/TitlesHelper';
import Segment from '../../helpers/Segment';
import ReportHelper from '../../helpers/report/ReportHelper';
import titles from '../../api/titles';
import { GET_USER_DETAIL_FAIL } from '../../actions/clapi';
import { getRppDomain, isNZ } from '../../constants/crux';
import Entitlements from '../../helpers/Entitlements';
import { UNSUBSCRIBED, UNAVAILABLE } from '../../constants/tooltipText';
import CruxSnackBar from '../common/CruxSnackBar';
import { goToSignatureReport, removeSignatureError } from '../../actions/signature';
import FeedbackLink from './FeedbackLink';
import { ReactComponent as Campaign } from '../../../assets/svg/menu/campaign.svg';
import '../../../css/crux/components/global/RPDSidebar.scss';

const SIGNATURE_SUB_TYPE = 'side-bar';

export const SidebarMenu = ({
    featureFlag, dispatch, pageContext, clapiUsrDetail,
    userSummaryApiSuccess, RPDSidebarRef, isHeaderSticky,
    searchType, appDomains, rpProposalsDomain, pgProposalsDomain, isFetchingSignature,
    signatureErrorMessage, signatureSubType, match, route,
}) => {
    const ref = useRef({});
    useEffect(() => {
        // Should only proceed after every successful API call.
        if (!isFetchingSignature && isFetchingSignature !== ref.current.isFetchingSignature) {
            if (!signatureErrorMessage && RPDSidebarRef.current) {
                RPDSidebarRef.current.setActivePane(false);
            }
        }

        // Storing previous value for the condition above
        ref.current = {
            isFetchingSignature,
            signatureErrorMessage,
        };
    }, [RPDSidebarRef, isFetchingSignature, signatureErrorMessage]);

    const dispatchLaunchEvent = (eventName) => {
        dispatch(trackEvent({
            eventName,
            properties: [
                {
                    label: SegmentProperty.EVENT_PROPERTIES.PAGE,
                    value: pageContext,
                },
                {
                    label: SegmentProperty.EVENT_PROPERTIES.ENTRY_POINT,
                    value: SegmentProperty.EVENT_PROPERTIES.PORTAL_SIDEBAR,
                },
            ],
        }));
    };

    const rpDataRedirect = () => {
        window.open(routeCodes.HOME.path);
        dispatchLaunchEvent(SegmentProperty.EVENT_NAMES.LAUNCH_RPP_CRUX);
    };

    const proposalsFeature = (featureFlagKey) => {
        const _featureFlag = featureFlag[featureFlagKey];
        switch (_featureFlag) {
            case 'displayLink': return true;
            case 'displayWithEntitlement': return !!Entitlements.hasRole(clapiUsrDetail, 'PROPOSAL_POINT');
            case 'hideLink':
            default: return false;
        }
    };

    const constructRppURL = () => {
        const propertyId = Commons.get(match, 'params.propertyId') || '';

        const rootUrl = getRppDomain();

        switch (route.pageContext) {
            case routeCodes.PROPERTY.pageContext: {
                // get property id when available
                dispatch(navigateToRppEvent(routeCodes.PROPERTY.pageContext, propertyId));
                return propertyId ?
                    RppRedirect.PROPERTY(rootUrl, propertyId) :
                    RppRedirect.DASHBOARD(rootUrl);
            }
            case routeCodes.SEARCH_RESULT.pageContext: {
                dispatch(navigateToRppEvent(routeCodes.SEARCH_RESULT.pageContext, ''));
                return RppRedirect.SEARCH_RESULT(rootUrl);
            }
            default: {
                dispatch(navigateToRppEvent(route.pageContext, ''));
                return RppRedirect.DASHBOARD(rootUrl);
            }
        }
    };

    const navigateToRpp = () => {
        window.open(constructRppURL());
    };

    const launchSignature = () => {
        dispatch(goToSignatureReport(
            null,
            null,
            SIGNATURE_SUB_TYPE,
            SegmentProperty.EVENT_PROPERTIES.PORTAL_SIDEBAR,
            pageContext,
        ));
    };

    const titlesLegacyRedirect = () => {
        titles.getTitlesUrl();
        dispatchLaunchEvent(SegmentProperty.EVENT_NAMES.LAUNCH_TITLE_DOCUMENT_SEARCH);
    }

    const titlesRedirect = () => {
        const userId = Commons.get(clapiUsrDetail, 'userName');
        const account = Commons.get(clapiUsrDetail, 'accountDetail.externalAccountId');
        const titlesUrl = `${Commons.get(appDomains, 'titles')}/?usertoken=${getTitlesToken(userId, account)}`;
        window.open(titlesUrl, '', 'noopener noreferrer');
        dispatchLaunchEvent(SegmentProperty.EVENT_NAMES.LAUNCH_TITLE_DOCUMENT_SEARCH);
    };

    const nzTitlesRedirect = () => {
        window.open(routeCodes.TITLE_ORDERING.path);
        dispatchLaunchEvent(SegmentProperty.EVENT_NAMES.LAUNCH_TITLE_DOCUMENT_SEARCH);
    };

    const marketInsightsRedirect = () => {
        window.open(routeCodes.MARKET_INSIGHTS.path);
        dispatch(trackEvent(Segment.launch(
            SegmentProperty.EVENT_NAMES.LAUNCH_MARKET_INSIGHTS,
            pageContext,
        )));
    };

    const othRedirect = () => {
        window.open('https://www.onthehouse.com.au/news/rp-data-onthehouse-membership-11841?utm_source=rpdata&utm_medium=referral&utm_campaign=oth-membership-nav-item', '', 'noopener');
        dispatch(trackEvent(Segment.launchOTH(pageContext)));
    };

    const rpProposalsRedirect = () => {
        window.open(rpProposalsDomain, '_blank');
        dispatchLaunchEvent(SegmentProperty.EVENT_NAMES.LAUNCH_RP_PROPOSALS);
    };

    const pgProposalsRedirect = () => {
        window.open(pgProposalsDomain, '_blank');
        dispatchLaunchEvent(SegmentProperty.EVENT_NAMES.LAUNCH_PG_PROPOSALS);
    };

    // Returns the top offset needed to render rp-connect sliding sidebar
    // it should be displayed under the header
    const getSidebarHeaderOffset = () => {
        const NAME_SEARCH_HEADER_HEIGHT_STICKY = 68;
        const ADDRESS_SEARCH_HEADER_HEIGHT_STICKY = 68;
        const NAME_SEARCH_HEADER_HEIGHT_NON_STICKY = 69.23;
        const ADDRESS_SEARCH_HEADER_HEIGHT_NON_STICKY = 69.23;
        const isNameSearch = searchType === 'name';
        let offset;

        if (isHeaderSticky) {
            offset = isNameSearch ?
                NAME_SEARCH_HEADER_HEIGHT_STICKY :
                ADDRESS_SEARCH_HEADER_HEIGHT_STICKY;
        } else {
            offset = isNameSearch ?
                NAME_SEARCH_HEADER_HEIGHT_NON_STICKY :
                parseFloat(ADDRESS_SEARCH_HEADER_HEIGHT_NON_STICKY.toFixed(2));
        }

        return offset;
    };

    const reportRoles = ReportHelper.getReportRoles(clapiUsrDetail);
    const isSignatureDisabled = !(reportRoles && (reportRoles.includes('Property CMA') ||
        reportRoles.includes('Rentals CMA') ||
        reportRoles.includes('CMA Generic Report Engine Full'))) ||
        !userSummaryApiSuccess;
    const isTitlesDisabled = !Entitlements.hasRole(clapiUsrDetail, 'Titles');
    const isSignaturePortalSidebar = signatureSubType === SIGNATURE_SUB_TYPE;
    const isLoadingSignature = isSignaturePortalSidebar && isFetchingSignature;
    const sideBarItemsAU = [
        {
            displayText: 'RP Data',
            active: pageContext !== routeCodes.MARKET_INSIGHTS.pageContext,
            onClick: rpDataRedirect,
        },
        {
            displayText: 'Signature',
            onClick: launchSignature,
            disabled: isSignatureDisabled,
            doNotClosePane: true,
            isLoading: isLoadingSignature,
            tooltip: {
                name: 'signature',
                // Show tooltip message when signature report is disabled
                enabled: isSignatureDisabled,
                message: userSummaryApiSuccess ? UNSUBSCRIBED : UNAVAILABLE,
            },
        },
        {
            displayText: 'Titles & Documents',
            onClick: featureFlag['crux.enable.lz.titles-au'] ? titlesRedirect : titlesLegacyRedirect,
            disabled: isTitlesDisabled,
            tooltip: {
                name: 'titles',
                enabled: isTitlesDisabled,
                message: userSummaryApiSuccess ? UNSUBSCRIBED : UNAVAILABLE,
            },
        },
        {
            displayText: 'Market Insights',
            onClick: marketInsightsRedirect,
            active: pageContext === routeCodes.MARKET_INSIGHTS.pageContext,
        },
        {
            displayText: 'RP Proposals',
            isNew: true,
            onClick: rpProposalsRedirect,
            featureFlag: proposalsFeature('rp.proposals.redirect'),
        },
        {
            displayText: 'OnTheHouse',
            isNew: true,
            onClick: othRedirect,
            featureFlag: featureFlag['oth.redirect'],
        },
        {
            displayText: '< Switch Back To RP Professional',
            onClick: navigateToRpp,
            featureFlag: featureFlag['rpd-to-rpp-banner'],
        },
    ];

    const sideBarItemsNZ = [
        {
            displayText: 'Property Guru',
            active: pageContext !== routeCodes.TITLE_ORDERING.pageContext,
            onClick: rpDataRedirect,
        },
        {
            displayText: 'Signature',
            onClick: launchSignature,
            doNotClosePane: true,
            isLoading: isLoadingSignature,
            disabled: isSignatureDisabled,
            tooltip: {
                name: 'signature',
                enabled: isSignatureDisabled,
                message: userSummaryApiSuccess ? UNSUBSCRIBED : UNAVAILABLE,
            },
        },
        {
            displayText: 'Titles & Documents',
            active: pageContext === routeCodes.TITLE_ORDERING.pageContext,
            featureFlag: Entitlements.canOrderTitles(clapiUsrDetail),
            onClick: nzTitlesRedirect,
        },
        {
            displayText: 'PG Proposals',
            isNew: true,
            featureFlag: proposalsFeature('pg.proposals.redirect'),
            onClick: pgProposalsRedirect,
        },
        {
            displayText: '< Switch back to RPNZ',
            onClick: navigateToRpp,
            featureFlag: featureFlag['pg-to-rpnz-banner'],
        },
    ];

    const sideBarItems = isNZ ? sideBarItemsNZ : sideBarItemsAU;

    return (
        <div className="feature-portal-sidebar">
            <RPDSidebar
                headerOffsetTop={getSidebarHeaderOffset()}
                ref={RPDSidebarRef}
            >
                <RPDSidebarHeader />
                {
                    sideBarItems.map(({
                        displayText, isNew, isComingSoon,
                        onClick, disabled, featureFlag: _featureFlag = true,
                        tooltip, active, doNotClosePane, isLoading,
                    }) => (_featureFlag ? (
                        <RPDSidebarLink
                            key={displayText}
                            displayText={displayText}
                            isNew={isNew}
                            isComingSoon={isComingSoon}
                            RPDSidebarRef={RPDSidebarRef}
                            onClick={onClick}
                            disabled={disabled}
                            tooltip={tooltip}
                            active={active}
                            doNotClosePane={doNotClosePane}
                            isLoading={isLoading}
                        />
                    ) : null))
                }
                <FeedbackLink>
                    <RPDSidebarLink
                        key="give-feedback"
                        displayText="Give Feedback"
                        displayIcon={<Campaign />}
                        RPDSidebarRef={RPDSidebarRef}
                        onClick={() => {/* do nothing */}}
                        active={false}
                    />
                </FeedbackLink>
                <CruxSnackBar
                    open={
                        !!signatureErrorMessage &&
                        isSignaturePortalSidebar
                    }
                    onClose={() => dispatch(removeSignatureError())}
                    severity="error"
                    message={signatureErrorMessage}
                />
            </RPDSidebar>
        </div>
    );
};

SidebarMenu.propTypes = {
    RPDSidebarRef: PropTypes.object,
    pageContext: PropTypes.string,
    searchType: PropTypes.string,
    route: PropTypes.object,
    match: PropTypes.object,
};

const ConnectedSidebarMenu = connect(state => ({
    isHeaderSticky: state.componentAccess.get('isHeaderFloating'),
    clapiUsrDetail: state.clapi.get('usrDetail'),
    userSummaryApiSuccess: !state.errorHandler.get('componentErrors').get(GET_USER_DETAIL_FAIL),
    featureFlag: state.launchDarkly.get('featureFlag'),
    appDomains: state.config.get('applicationDomain'),
    rpProposalsDomain: state.config.get('applicationDomain').rpProposals,
    pgProposalsDomain: state.config.get('applicationDomain').pgProposals,
    isFetchingSignature: state.signature.get('isFetching'),
    signatureErrorMessage: state.signature.get('errorMessage'),
    signatureSubType: state.signature.get('subType'),
}))(SidebarMenu);

export default forwardRef((props, ref) =>
    (<ConnectedSidebarMenu {...props} RPDSidebarRef={ref} />));
