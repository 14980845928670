import moment from 'moment';
import Commons from '../Commons';
import {
    CATEGORY_KEY,
    CATEGORY_PATH,
    CUSTOM_DATE_FILTER_SELECT_OPTIONS,
    EXCLUDE_SAVED_SEARCH_FILTERS,
    SEARCH_TYPE_BASED_ON_CATEGORY,
} from '../../constants/savedSearch';
import ListPropertyPreview from '../../constants/listPropertyPreview';
import OTM from '../../constants/otm';
import { LISTING_TYPE_V2, RAPID_PROPERTY_TYPE_V2 } from '../Localization';
import SearchFilterHelper from './SearchFilterHelper';
import dateFilter from '../../constants/rapidSearch/dateFilter';

const classicPresetOptionList = dateFilter.RAPID_CLASSIC_PRESET_OPTIONS.map(item => item.value);

const getFilterFromCategory = (savedSearch) => {
    try {
        const key = CATEGORY_KEY[savedSearch.category];
        const value = Commons.get(savedSearch, CATEGORY_PATH[savedSearch.category]);
        return {
            [key]: value,
        };
    } catch (e) {
        return {};
    }
};

const getStatusType = (filters) => {
    const { isForSale, isRecentlySold, isForRent } = filters;
    let statusType = OTM.ALL;
    if (isRecentlySold) {
        statusType = OTM.RECENT_SALE;
    } else if (isForSale) {
        statusType = OTM.FOR_SALE;
    } else if (isForRent) {
        statusType = OTM.FOR_RENT;
    }
    return statusType;
};

const getStatusTypeBySelectedTab = (index = 0) => {
    const statusTypeMap = [
        OTM.ALL,
        OTM.RECENT_SALE,
        OTM.FOR_SALE,
        OTM.FOR_RENT,
    ];
    return statusTypeMap[index];
}

const toMinMax = (defaultValue = { min: '', max: '' }, unit = '') => (value) => {
    try {
        if (`${value}`.includes('-')) {
            const values = value.split('-');
            const [min, max] = values;
            return { min: min && `${min}${unit}`, max: max && `${max}${unit}` };
        }
        return { min: `${value}${unit}`, max: `${value}${unit}` };
    } catch (e) {
        return defaultValue;
    }
};

const toMinMaxWithUnit = unit => toMinMax({ min: '', max: '' }, unit);
const toMinMaxArea = (defaultValue = { min: '', max: '', unit: 'm²' }) => (value) => {
    try {
        if (`${value}`.includes('-')) {
            const values = value.split('-');
            const [min, max] = values;
            return {
                min: min,
                max: max,
                unit: 'm²',
            };
        }
        return {
            min: value,
            max: value,
            unit: 'm²',
        };
    } catch (e) {
        return defaultValue;
    }
};

const toArray = (defaultValue = []) => (value) => {
    try {
        return Commons.removeFalsyFromArray(value.replace(/"(.*)"/g, '$1').split('","'));
    } catch (e) {
        return defaultValue;
    }
};

const toType = (value) => {
    try {
        value = Commons.formatPropertyTypes(value);
        const types = Commons.removeFalsyFromArray(value.split(','));
        if (types.length) {
            return RAPID_PROPERTY_TYPE_V2.filter(type => types.includes(type.label))
                .map(type => type.value);
        }
        return RAPID_PROPERTY_TYPE_V2.map(type => type.value);
    } catch (e) {
        return RAPID_PROPERTY_TYPE_V2.map(type => type.value);
    }
};

const toAgentsAdvised = value => ({
    key: 'agentAdvised',
    value: !!value,
});

const toSort = (value) => {
    const sorts = value.split(',');
    return sorts.map((sort) => {
        const attr = sort.substring(1);
        const order = sort.substring(0, 1);
        return {
            attr,
            order,
        };
    });
};

const FORM_FILTERS_FORMATTER = {
    type: toType,
    beds: toMinMax(),
    baths: toMinMax(),
    carSpaces: toMinMax(),
    subType: toArray(),
    salesLastCampaignListedType: toArray(LISTING_TYPE_V2.map(listingType => listingType.value)),
    developmentZoneDescription: toArray(),
    landUse: toArray(),
    landArea: toMinMaxArea(),
    yearBuilt: toMinMax(),
    salesLastSoldPrice: toMinMax(),
    salesLastSaleContractDate: toMinMax(SearchFilterHelper.getDefaultDateV2(OTM.RECENT_SALE)),
    salesLastCampaignLastListedPrice: toMinMax(),
    salesLastCampaignEndDate: toMinMax(SearchFilterHelper.getDefaultDateV2(OTM.FOR_SALE)),
    salesLastSaleSettlementDate: toMinMax(),
    salesLastCampaignStartDate: toMinMax(),
    rentalLastCampaignLastListedPrice: toMinMax(),
    rentalLastCampaignEndDate: toMinMax(SearchFilterHelper.getDefaultDateV2(OTM.FOR_RENT)),
    rentalLastCampaignStartDate: toMinMax(),
    valuationCapitalValue: toMinMax(),
    valuationAssessmentDate: toMinMax(),
    distanceToHospital: toMinMaxWithUnit('km'),
    distanceToRiverFrontage: toMinMaxWithUnit('km'),
    distanceToSchool: toMinMaxWithUnit('km'),
    distanceToSeaRiverCanal: toMinMaxWithUnit('km'),
    distanceToShops: toMinMaxWithUnit('km'),
    housesOnFarm: toMinMax(),
    irrigatedArea: toMinMax(),
    damsBores: toMinMax(),
    paddocksYards: toMinMax(),
    factoryArea: toMinMaxArea(),
    mezzanineArea: toMinMaxArea(),
    officeShowroomArea: toMinMaxArea(),
    otherArea: toMinMaxArea(),
    shopArea: toMinMaxArea(),
    showroomArea: toMinMaxArea(),
    warehouseArea: toMinMaxArea(),
    workshopArea: toMinMaxArea(),
    diningRooms: toMinMax(),
    familyRumpusRooms: toMinMax(),
    floorLevelsInside: toMinMax(),
    loungeRooms: toMinMax(),
    loungeDiningRoomsCombined: toMinMax(),
    kitchens: toMinMax(),
    noOfStudyRooms: toMinMax(),
    toilets: toMinMax(),
    lockupGarages: toMinMax(),
    noOfTennisCourts: toMinMax(),
    buildingArea: toMinMaxArea(),
    equivalentBuildingArea: toMinMaxArea(),
    floorLevelOfUnit: toMinMax(),
    floorArea: toMinMaxArea(),
    floorLevelsInsideUnit: toMinMax(),
    flats: toMinMax(),
    streetFrontage: toMinMaxWithUnit(''),
    totalFloorsInBuilding: toMinMax(),
    yearBuildingRefurbished: toMinMax(),
    yearEstablished: toMinMax(),
    currentUnimprovedLandValue: toMinMax(),
    unimprovedCapitalValue: toMinMax(),
    unimprovedCapitalValueDate: toMinMax(),
    sort: toSort,
    valuationLandValue: toMinMax(),
    valuationImprovementsValue: toMinMax(),
};

const FORM_FILTERS_FORMATTER_WITH_OTHER_KEY = {
    salesLastSaleSource: toAgentsAdvised,
};

const getSearchTypeByCategoryId = categoryId =>
    SEARCH_TYPE_BASED_ON_CATEGORY[categoryId] || ListPropertyPreview.SEARCH_TYPE.ADDRESS;

const getMonthDiff = (dates) => {
    const dateMin = moment(dates[0], 'YYYYMMDD');
    const dateMax = moment(dates[1], 'YYYYMMDD');
    return dateMax.diff(dateMin, 'months', true);
}

const convertDateToPresetDateOption = (_filters, savedSearchFilters, dateField, dateOptionFieldName = 'dateFilterSelectOption') => {
    const monthDiff = getMonthDiff(savedSearchFilters[dateField].split('-'));
    Object.defineProperty(_filters, dateOptionFieldName, {
        value: `${monthDiff}months`,
        writable: true,
        enumerable: true,
        configurable: true
    })

    Object.defineProperty(_filters, dateField, {
        value: {
            min: moment().subtract(monthDiff, 'months').format('YYYYMMDD'),
            max: moment().format('YYYYMMDD'),
        },
        writable: true,
        enumerable: true,
        configurable: true
    });
}

const handleCustomDateValues = (_filters, savedSearchFilters, dateField, dateOptionFieldName = 'dateFilterSelectOption') => {
    const dates = savedSearchFilters[dateField].split('-');
    const dateMin = Commons.formatDate(dates[0], 'DD MMM YYYY', 'YYYYMMDD');
    const dateMax = Commons.formatDate(dates[1], 'DD MMM YYYY', 'YYYYMMDD');
    _filters[dateOptionFieldName] = `${dateMin}-${dateMax}`;
};

const toFormFilters = (statusType, filters, savedSearchFilters, customDate) => {
    const _filters = {};
    Object.entries(filters).forEach(([key, value]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (FORM_FILTERS_FORMATTER.hasOwnProperty(key)) {
            Object.defineProperty(_filters, key, {
                value: FORM_FILTERS_FORMATTER[key](value),
                enumerable: true,
                configurable: true,
            });
        // eslint-disable-next-line no-prototype-builtins
        } else if (FORM_FILTERS_FORMATTER_WITH_OTHER_KEY.hasOwnProperty(key)) {
            const { key: _key, value: _value } = FORM_FILTERS_FORMATTER_WITH_OTHER_KEY[key](value);
            Object.defineProperty(_filters, _key, {
                value: _value,
                enumerable: true,
                configurable: true,
            });
        } else {
            Object.defineProperty(_filters, key, {
                value,
                enumerable: true,
                configurable: true,
            });
        }
    });

    const dateField = SearchFilterHelper.getDateAndPriceFieldNames(statusType).date;
    const dateFieldsWithSelectOptions = {
        ...CUSTOM_DATE_FILTER_SELECT_OPTIONS,
        [dateField]: 'dateFilterSelectOption',
        salesLastSaleSettlementDate: 'salesLastSaleSettlementDateOption',
    };
    Object.keys(dateFieldsWithSelectOptions).forEach(key => {
        if (savedSearchFilters?.[key]) {
            const valOptName = dateFieldsWithSelectOptions[key];
            if (customDate?.includes(key) || (valOptName === 'dateFilterSelectOption' && customDate?.includes('date'))) {
                handleCustomDateValues(_filters, savedSearchFilters, key, valOptName);
            } else {
                convertDateToPresetDateOption(_filters, savedSearchFilters, key, valOptName);
            }
        }
    });

    return _filters;
};

const sanitizeFilterRequest = (savedSearch) => {
    const { filters } = savedSearch;
    // format property type and subType to cater to rapid search
    if (filters.type) {
        filters.type = Commons.formatPropertyTypes(filters.type);
    }
    EXCLUDE_SAVED_SEARCH_FILTERS.forEach((key) => {
        delete filters[key];
    });
    const filterBasedOnCategory = getFilterFromCategory(savedSearch);

    return {
        ...filterBasedOnCategory,
        ...filters,
    };
};

const removeSearchStringBasedFields = (_filters) => {
    Object.values(CATEGORY_KEY).concat(['addressComplete']).forEach((field) => {
        delete _filters[field];
    });
};

export const deleteUnusedFiltersInMobile = (_filters) => {
    delete _filters.addressStreet;
    delete _filters.addressSuburb;
    delete _filters.addressState;
    delete _filters.addressPostcode;
    delete _filters.councilArea;
    delete _filters.councilAreaState;
    delete _filters.addresses;
}

export default {
    toFormFilters,
    getStatusTypeBySelectedTab,
    getSearchTypeByCategoryId,
    sanitizeFilterRequest,
    getStatusType,
    getMonthDiff,
    handleCustomDateValues,
    removeSearchStringBasedFields,
    classicPresetOptionList,
    getFilterFromCategory,
    deleteUnusedFiltersInMobile,
};
