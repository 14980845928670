import SuggestionTypes from '../suggestionTypes';
import ListPropertyPreview from '../listPropertyPreview';
import {
    COUNCIL_AREA_FIELD,
    STREET_FIELD,
    SUBURB_FIELD,
    COUNCIL_AREA_CATEGORY,
    POSTCODE_FIELD,
    ADDRESS_FIELD,
} from '../../helpers/Localization';
import {
    SALES_LAST_SALE_CONTRACT_DATE_PARAM,
    SALES_LAST_CAMPAIGN_END_DATE_PARAM,
    RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,
} from '../batchSearch/batchSearch';

export const MAX_PROPERTIES_WITH_NOTIFICATIONS = 10000;

export const EXCLUDE_SAVED_SEARCH_FILTERS = [
    'addressComplete', // added because addressComplete was returned from saved search response
    'isRecentlySold',
    'isForRent',
    'isForSale',
    'isCustomDate',
    'includeHistorical', // GET V1 endpoint adds this when you save a search in V2 and contains isActive="true,false"
];

export const CATEGORY_KEY = {
    1: ADDRESS_FIELD,
    2: STREET_FIELD,
    3: SUBURB_FIELD,
    4: POSTCODE_FIELD,
    5: COUNCIL_AREA_FIELD,
    6: 'titleReference',
    7: 'councilArea',
    8: 'addressState',
    11: 'id',
    '21,22': 'id',
    27: 'legalDescription',
    'roll/val': 'id',
};

export const CATEGORY_PATH = {
    1: 'searchString',
    2: 'searchString',
    3: 'searchString',
    4: 'searchString',
    5: 'searchString',
    6: 'filters.titleReference',
    7: 'searchString',
    8: 'searchString',
    11: 'filters.id',
    '21,22': 'filters.id',
    27: 'filters.legalDescription',
    'roll/val': 'filters.id',
};

export const SEARCH_TYPE_BASED_ON_CATEGORY = {
    1: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    2: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    3: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    4: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    5: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    6: ListPropertyPreview.SEARCH_TYPE.TITLE_REFERENCE,
    7: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    8: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    11: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    '21,22': ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    27: ListPropertyPreview.SEARCH_TYPE.LEGAL_DESCRIPTION,
    'roll/val': ListPropertyPreview.SEARCH_TYPE.ROLL_VALUATION,
};

export const SUGGESTION_TYPE_BASED_ON_CATEGORY = {
    1: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    2: 'street',
    3: 'locality',
    4: 'postcode',
    5: 'councilArea',
    6: ListPropertyPreview.SEARCH_TYPE.TITLE_REFERENCE,
    7: 'councilArea',
    8: 'state',
    11: ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    '21,22': ListPropertyPreview.SEARCH_TYPE.ADDRESS,
    27: ListPropertyPreview.SEARCH_TYPE.LEGAL_DESCRIPTION,
    'roll/val': ListPropertyPreview.SEARCH_TYPE.ROLL_VALUATION,
};

export const STATUS_TYPES_FILTER_KEY = {
    all: { isRecentlySold: false, isForRent: false, isForSale: false },
    recentSale: { isRecentlySold: true, isForRent: false, isForSale: false },
    forSale: { isRecentlySold: false, isForRent: false, isForSale: true },
    forRent: { isRecentlySold: false, isForRent: true, isForSale: false },
};

export const SELECTED_TAB_INDEX = {
    all: 0,
    recentSale: 1,
    forSale: 2,
    forRent: 3,
};

export const EMAIL_DIGEST_PERIODS = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const EMAIL_DIGEST_OPTIONS = [
    { key: EMAIL_DIGEST_PERIODS.DAILY, label: 'Daily' },
    { key: EMAIL_DIGEST_PERIODS.WEEKLY, label: 'Weekly' },
    { key: EMAIL_DIGEST_PERIODS.MONTHLY, label: 'Monthly' },
];

export const CUSTOM_DATE_FILTER_SELECT_OPTIONS = {
    salesLastCampaignStartDate: 'salesLastCampaignStartDateOption',
    rentalLastCampaignStartDate: 'rentalLastCampaignStartDateOption',
    rentalLastCampaignEndDate: 'dateFilterSelectOption',
    valuationAssessmentDate: 'valuationAssessmentDateOption',
    salesLastSaleContractDate: 'salesLastSaleContractDateOption',
    unimprovedCapitalValueDate: 'unimprovedCapitalValueDateOption',
};

export const DATE_FILTER_WITH_PRESETS = {
    date: 'dateFilterSelectOption',
    salesLastCampaignEndDate: 'dateFilterSelectOption',
    rentalLastCampaignEndDate: 'dateFilterSelectOption',
    salesLastSaleSettlementDate: 'salesLastSaleSettlementDateOption',
    salesLastCampaignStartDate: 'salesLastCampaignStartDateOption',
    rentalLastCampaignStartDate: 'rentalLastCampaignStartDateOption',
    valuationAssessmentDate: 'valuationAssessmentDateOption',
    salesLastSaleContractDate: 'salesLastSaleContractDateOption',
    unimprovedCapitalValueDate: 'unimprovedCapitalValueDateOption',
};

export const DATE_FILTER_SELECT_OPTION_V2 = {
    all: SALES_LAST_SALE_CONTRACT_DATE_PARAM,
    recentSale: SALES_LAST_SALE_CONTRACT_DATE_PARAM,
    forSale: SALES_LAST_CAMPAIGN_END_DATE_PARAM,
    forRent: RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,
};

export const DEFAULT_NOTIFICATION_SETUP = {
    notificationsEnabled: false,
    notifyAdd: false,
    notifyRemove: false,
    notifySold: false,
    notifyForSale: false,
    notifyForRent: false,
    emailEnabled: false,
    pushEnabled: false,
    emailPeriod: EMAIL_DIGEST_PERIODS.WEEKLY,
};

export default {
    [SuggestionTypes.ADDRESS]: '1',
    [SuggestionTypes.STREET]: '2',
    [SuggestionTypes.LOCALITY]: '3',
    [SuggestionTypes.SUBURB]: '3',
    [SuggestionTypes.POSTCODE]: '4',
    [SuggestionTypes.COUNCIL_AREA]: COUNCIL_AREA_CATEGORY,
    [SuggestionTypes.TITLE_REF]: '6',
    [SuggestionTypes.TERRITORIAL_AUTHORITY]: '7',
    [SuggestionTypes.STATE]: '8',
    [SuggestionTypes.SCHOOL]: '11',
};
