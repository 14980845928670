import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { styled, Grid, Chip } from '@mui/material';
import AddAlert from '@mui/icons-material/AddAlert';
import RemoveCircle from '@mui/icons-material/RemoveCircle';

import { routeCodes } from '../../../../constants/routes';
import Colors from '../../../../constants/colors';
import { SAVED_SEARCH_EVENT_TAGS } from '../../../../constants/eventTags';
import SavedSearchPropertyCount from '../../../userFavorites/savedSearches/SavedSearchPropertyCount';
import savedSearchApi from '../../../../api/savedSearchesApi';
import Commons from '../../../../helpers/Commons';
import FavouritesCard from '../FavouritesCard';
import { formatSavedSearchesAsV1 } from '../../../../helpers/search/SavedSearchV1FormatBuilder';

const StyledName = styled('div')({
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: Colors.WHITE,
    display: 'flex',
    alignItems: 'center',
});

const StyledPropertyCountContainer = styled('div')({
    paddingBottom: '8px',
    '& .MuiTypography-root': {
        color: Colors.WHITE,
    },
});

const StyledNotificationsContainer = styled('div')({
    color: Colors.WHITE,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '166%',
    letterSpacing: '0.4px',
});

const StyledNotification = styled(({ color, ...rest }) => <div {...rest} />)(({ color }) => ({
    borderRadius: '4px',
    marginBottom: '4px',
    padding: '2px 4px',
    backgroundColor: color,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
}));

const StyledSearchName = styled('div')({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const StyledLoader = styled('div')({
    color: Colors.WHITE,
});

const StyledErrorMessage = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const SavedSearchCardHome = (props) => {
    const { searchCriteria: rawSearchCriteria, hasNotificationRole, enableSavedSearchV2, clUsrId } = props;

    const searchCriteria = enableSavedSearchV2
        ? formatSavedSearchesAsV1(rawSearchCriteria)
        : rawSearchCriteria;

    const {
        itemId,
        searchName,
        selectedTab,
        filters,
    } = searchCriteria;

    const [isLimitReached, setIsLimitReached] = useState(true);
    const [notificationLoading, setNotificationLoading] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [isNotificationViewed, setIsNotificationViewed] = useState(false);
    const [addedProperties, setAddedProperties] = useState(0);
    const [removedProperties, setRemovedProperties] = useState(0);
    const [soldProperties, setSoldProperties] = useState(0);
    const [forSaleProperties, setForSaleProperties] = useState(0);
    const [forRentProperties, setForRentProperties] = useState(0);

    const notificationSettings = Commons.get(searchCriteria, 'notificationSettings') || {};
    const { notificationsEnabled, notifyAdd, notifyRemove, notifySold, notifyForSale, notifyForRent } = notificationSettings;
    const soldPropertyCount = notifySold && soldProperties;
    const forSalePropertyCount = notifyForSale && forSaleProperties;
    const forRentPropertyCount = notifyForRent && forRentProperties;
    const updatedPropertyCount = soldPropertyCount + forSalePropertyCount + forRentPropertyCount;
    const selectedTabId = Number(selectedTab);


    useEffect(() => {
        const notificationsSwitchedOn = notificationsEnabled
            && (notifyAdd || notifyRemove || notifySold || notifyForSale || notifyForRent);
        if (notificationsSwitchedOn && !isLimitReached && hasNotificationRole) {
            setNotificationLoading(true);
            savedSearchApi.getNotificationHistory(itemId, clUsrId)
                .then(({ response = {} }) => {
                    const { status, result } = response;
                    if (status === 'success') {
                        const { notificationHistoryList } = result;
                        setSoldProperties(notificationHistoryList
                            .filter(({ eventType }) => eventType === 1).length);
                        setForSaleProperties(notificationHistoryList
                            .filter(({ eventType }) => eventType === 2).length);
                        setForRentProperties(notificationHistoryList
                            .filter(({ eventType }) => eventType === 3).length);
                        setAddedProperties(notificationHistoryList
                            .filter(({ eventType }) => eventType === 4).length);
                        setRemovedProperties(notificationHistoryList
                            .filter(({ eventType }) => eventType === 5).length);
                    }
                })
                .catch(() => {
                    setNotificationError(true);
                })
                .finally(() => {
                    setNotificationLoading(false);
                });
        }
    }, [itemId, isLimitReached]);
    const sendMixpanelEvent = () => {
        if ((addedProperties || removedProperties || soldProperties || forSaleProperties || forRentProperties)
            && notificationsEnabled
            && !isNotificationViewed) {
            setIsNotificationViewed(true);
        }
    };

    const hasEventFlags = enableSavedSearchV2 ? !!selectedTabId && !!SAVED_SEARCH_EVENT_TAGS[selectedTabId].label
        : SAVED_SEARCH_EVENT_TAGS.some(({ flag }) => filters[flag]);

    const displayedTag = SAVED_SEARCH_EVENT_TAGS.find(({ label, color, flag }, index) =>
        enableSavedSearchV2 ? (index === selectedTabId && !!label) : filters[flag]
    );

    return (
        <Link
            data-testid="save-search-card-home-link"
            to={routeCodes.SAVED_SEARCH.path(itemId)}
            onClick={sendMixpanelEvent}
            target="_blank"
        >
            <FavouritesCard overrideStyle={{ height: '142px' }}>
                <StyledName>
                    <Grid container spacing={1}>
                        <Grid item xs={hasEventFlags ? 7 : 12}>
                            <StyledSearchName
                                title={searchName}
                            >
                                {searchName}
                            </StyledSearchName>
                        </Grid>
                        {
                            displayedTag &&
                            <Grid
                                item
                                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                xs={5}
                                key={displayedTag.label}
                            >
                                <Chip
                                    label={displayedTag.label}
                                    size="small"
                                    sx={{
                                        backgroundColor: displayedTag.color,
                                        borderRadius: '4px',
                                        '& .MuiChip-label': {
                                            color: Colors.WHITE,
                                            textTransform: 'uppercase',
                                            fontSize: '10px',
                                            overflow: 'unset',
                                        },
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </StyledName>
                <StyledPropertyCountContainer>
                    <SavedSearchPropertyCount
                        savedSearch={searchCriteria}
                        onLimitReached={setIsLimitReached}
                        isHomePage
                    />
                </StyledPropertyCountContainer>
                <StyledNotificationsContainer>
                    {
                        notificationLoading ?
                            <StyledLoader>Loading...</StyledLoader>
                            :
                            <>
                                {
                                    (updatedPropertyCount > 0)
                                    && notificationsEnabled
                                    && !isNotificationViewed &&
                                    <StyledNotification color="rgba(76, 175, 80, 0.08)">
                                        <AddAlert htmlColor="#4CAF50" fontSize="inherit" />
                                        <span>
                                            {updatedPropertyCount} {Commons.getPropertyLabel(updatedPropertyCount)} updated
                                        </span>
                                    </StyledNotification>
                                }
                                {
                                    // notification yet to be completed
                                    addedProperties > 0
                                    && notificationsEnabled
                                    && notifyAdd
                                    && !isNotificationViewed &&
                                    <StyledNotification color="rgba(76, 175, 80, 0.08)">
                                        <AddAlert htmlColor="#4CAF50" fontSize="inherit" />
                                        <span>
                                            {addedProperties} {Commons.getPropertyLabel(addedProperties)} added
                                        </span>
                                    </StyledNotification>
                                }

                                {
                                    removedProperties > 0
                                    && notificationsEnabled
                                    && notifyRemove
                                    && !isNotificationViewed &&
                                    <StyledNotification color="rgba(255, 152, 0, 0.08)">
                                        <RemoveCircle htmlColor="#FF9800" fontSize="inherit" />
                                        <span>
                                            {removedProperties} {Commons.getPropertyLabel(removedProperties)} removed
                                        </span>
                                    </StyledNotification>
                                }
                            </>
                    }
                    {
                        notificationError &&
                        <StyledErrorMessage>Something went wrong!</StyledErrorMessage>
                    }
                </StyledNotificationsContainer>
            </FavouritesCard>

        </Link>
    );
};

SavedSearchCardHome.propTypes = {
    searchCriteria: PropTypes.object,
    hasNotificationRole: PropTypes.bool,
    enableSavedSearchV2: PropTypes.bool,
    clUsrId: PropTypes.string,
};

export default SavedSearchCardHome;