export const RECENT_SEARCH_START = 'RECENT_SEARCH_START';
export const RECENT_SEARCH_END = 'RECENT_SEARCH_END';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const GET_PROPERTY_BY_COORDINATES = 'GET_PROPERTY_BY_COORDINATES';
export const GET_PROPERTY_BY_COORDINATES_SUCCESS = 'GET_PROPERTY_BY_COORDINATES_SUCCESS';
export const GET_PROPERTY_BY_COORDINATES_FAILED = 'GET_PROPERTY_BY_COORDINATES_FAILED';

export const startRecentSearchProgress = () => {
    return {
        type: RECENT_SEARCH_START,
    };
}

export const endRecentSearchProgress = () => {
    return {
        type: RECENT_SEARCH_END,
    };
}

export const setSelectedProperty = (id) => ({
    type: SET_SELECTED_PROPERTY,
    payload: id,
});

export const setDynamicProperty = (coordinates) => ({
    type: GET_PROPERTY_BY_COORDINATES,
    payload: coordinates,
});
