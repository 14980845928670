import { Component } from 'react';
import { createPortal } from 'react-dom';
import { MapContext } from '@react-google-maps/api';
import PropTypes from 'prop-types';

export default class CustomControl extends Component {
    static contextType = MapContext;
    static propTypes = {
        position: PropTypes.number,
    };
    constructor(props) {
        super(props);
        this.controlDiv = document.createElement('div');
        this.controlDiv.classList.add(props.className);
    }
    render() {
        this.context?.controls[this.props.position].push(this.controlDiv);
        return createPortal(this.props.children, this.controlDiv);
    }
}
