import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';

import ScrollableContainer from 'js/components/common/ScrollableContainer';
import Commons from '../../../../helpers/Commons';
import savedSearchApi from '../../../../api/savedSearchesApi';
import EmptyFavoritesPage from '../EmptyFavoritesPage';
import SavedSearchCardHome from './SavedSearchCardHome';
import Entitlements from '../../../../helpers/Entitlements';
import Colors from '../../../../constants/colors';
import { SAVED_SEARCHES } from '../../../../constants/favourites/favourites';
import { routeCodes } from '../../../../constants/routes';
import { CONTENT_HEIGHT, StyledButton } from '../UserFavoritesHome';
import UnsubscribedPage from '../UnsubscribedPage';

const ListSkeleton = ({ listsToRender }) => {
    return (
        <>
            {Array(listsToRender)
                .fill(1)
                .map((card) => (
                    <Grid item xs={6} key={`card-${card}`}>
                        <div style={{
                            borderRadius: '8px',
                            background: '#212121',
                            padding: '16px',
                            width: '223px',
                            height: '142px',
                        }}>
                            <Skeleton
                                variant="text"
                                sx={{ backgroundColor: 'gray' }}
                            />
                        </div>
                    </Grid>
                ))}
        </>
    );
};
const SavedSearchPanelHome = (props) => {
    const history = useHistory();
    const [isError, setIsError] = useState(false);
    const [called, setCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listItems, setListItems] = useState([]);

    const { usrDetail, enableSavedSearchV2 } = props;
    const hasNotificationRole = Entitlements.hasNotificationRole(usrDetail);
    const hasSavedSearchRole = Entitlements.hasSaveSearchRole(usrDetail);
    const showList = listItems && listItems.length > 0;
    const showEmptyPage = called && !showList;
    const clUsrId = Commons.get(usrDetail, 'clUsrGuid');

    const handleViewAllClick = (event, tabValue) => {
        history.push({
            pathname: routeCodes.USER_FAVORITES.path,
            state: {
                activeTab: tabValue,
            },
        });
    };

    const getSavedSearch = () => {
        setLoading(true);
        savedSearchApi.getSavedSearches(enableSavedSearchV2).then((res) => {
            const { response } = res;
            setListItems(response.result.items);
        }).catch(() => {
            setIsError(true);
        }).finally(() => {
            setCalled(true);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (usrDetail?.accountDetail?.clAccountGuid && hasSavedSearchRole) {
            getSavedSearch();
        }
    }, [usrDetail?.accountDetail?.clAccountGuid]);

    if (!hasSavedSearchRole) {
        return (
            <UnsubscribedPage
                type="Saved Searches"
                message="To save your frequent searches and stay updated"
                Icon={<TurnedInNotIcon sx={{
                        width: '56px',
                        height: '56px',
                        flexShrink: '0',
                        color: Colors.PRIMARY.MAIN,
                    }}
                />}
            />
        );
    }

    return (
        <>
            <ScrollableContainer height={CONTENT_HEIGHT}>
                <Grid container spacing={1}>
                    {
                        loading ? <ListSkeleton listsToRender={1} />
                            : listItems.map((item, index) => (
                                showList && !loading &&
                                <Grid item xs={6} key={item?.itemId}>
                                    <SavedSearchCardHome
                                        index={index}
                                        clUsrId={clUsrId}
                                        searchCriteria={item}
                                        key={item?.itemId}
                                        hasNotificationRole={hasNotificationRole}
                                        enableSavedSearchV2={enableSavedSearchV2}
                                    />
                                </Grid>
                            ))
                    }
                </Grid>
                {
                    showEmptyPage && !loading &&
                    <EmptyFavoritesPage
                        title={isError ? 'Sorry, there was an error fetching your data.' : 'You\'re out of the loop!'}
                        subTitle={isError ? 'Please refresh the page to try again.' : 'Save your frequent searches to stay updated.'}
                        Icon={<TurnedInNotIcon sx={{
                            width: '56px',
                            height: '56px',
                            flexShrink: '0',
                            color: Colors.PRIMARY.MAIN,
                        }}
                        />}
                    />
                }
            </ScrollableContainer>
            {
                !showEmptyPage &&
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <StyledButton onClick={(e) => handleViewAllClick(e, SAVED_SEARCHES)}>
                        View All
                    </StyledButton>
                </div>
            }
        </>
    );
};

SavedSearchPanelHome.propTypes = {
    usrDetail: PropTypes.object,
    enableSavedSearchV2: PropTypes.bool,
};
export default connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
    enableSavedSearchV2: state.launchDarkly.get('featureFlag')['crux.saved.search.v2'],
}))(SavedSearchPanelHome);