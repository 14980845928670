import { isAU } from 'js/constants/crux';

const getSingleLineAddress = (address) => address?.singleLine || address?.street?.singleLine || '';
const getLocallyFormattedAddress = address => address?.locallyFormattedAddress || '';
const getAddress = (address) => {
    if (isAU) {
        return getSingleLineAddress(address);
    }
    return getLocallyFormattedAddress(address);
};

export default {
    get: getAddress,
    getSingleLine: getSingleLineAddress,
    getLocallyFormatted: getLocallyFormattedAddress,
}