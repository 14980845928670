import Commons from './Commons';
import EntitlementsRole from '../constants/entitlementsRole';
import { routeCodes } from '../constants/routes';
import SuggestionTypes from '../constants/suggestionTypes';
import FeatureFlag from '../model/launchDarkly/FeatureFlag';

const {
    SEARCH_NAME,
    SEARCH_BUILDING_NAME,
    LGA_SEARCH,
    SEARCH_PARCEL,
    MAP_ADVANCED,
    SAVE_SEARCH,
    NOTIFICATIONS,
    // TODO: RADIUS SEARCH - uncomment
    // RADIUS_SEARCH,
    ADVANCED_FILTERS,
    PROSPECTOR,
    MARKETING_FULL,
    SAVED_LISTS,
    SAVED_TERRITORY,
    REIWA_TITLES,
    EXPORT_LIMIT_PRO,
    REI_FORMS,
} = EntitlementsRole;

const canOrderTitles = clapiUsrDetail => !!Commons.get(clapiUsrDetail, 'userAccountDetail.canOrderTitles');
const isRolesAvailable = clapiUsrDetail => Commons.get(clapiUsrDetail, 'entitlements.roles');

// TODO: Remove clapiUsrDetail param
const hasRole = (clapiUsrDetail, role) => {
    const entitlementRoles = Commons.get(clapiUsrDetail, 'entitlements.roles');
    return entitlementRoles?.some(_role => _role.toLowerCase() === role.toLowerCase());
};

const hasSearchNameRole = clapiUsrDetail => hasRole(clapiUsrDetail, SEARCH_NAME);
const hasSearchBuildingRole = clapiUsrDetail => hasRole(clapiUsrDetail, SEARCH_BUILDING_NAME);
const hasLGASearchRole = clapiUsrDetail => hasRole(clapiUsrDetail, LGA_SEARCH);
const hasParcelSearchRole = clapiUsrDetail => hasRole(clapiUsrDetail, SEARCH_PARCEL);
const hasMapAdvancedRole = clapiUsrDetail => hasRole(clapiUsrDetail, MAP_ADVANCED);
const hasSaveSearchRole = clapiUsrDetail => hasRole(clapiUsrDetail, SAVE_SEARCH);
const hasSavedTerritoriesRole = clapiUsrDetail => hasRole(clapiUsrDetail, SAVED_TERRITORY);
const hasNotificationRole = clapiUsrDetail => hasRole(clapiUsrDetail, NOTIFICATIONS);
const hasSavedListRole = clapiUsrDetail => hasRole(clapiUsrDetail, SAVED_LISTS);
// TODO: RADIUS SEARCH - bring back original
// const hasRadiusSearchRole = clapiUsrDetail => hasRole(clapiUsrDetail, RADIUS_SEARCH);
const hasRadiusSearchRole = clapiUsrDetail => true;
const hasAdvancedFiltersRole = clapiUsrDetail => hasRole(clapiUsrDetail, ADVANCED_FILTERS);
const hasProspectorRole = clapiUsrDetail => hasRole(clapiUsrDetail, PROSPECTOR);
const hasMarketingFullRole = clapiUsrDetail => hasRole(clapiUsrDetail, MARKETING_FULL);
const hasReiwaTitlesRole = clapiUsrDetail => hasRole(clapiUsrDetail, REIWA_TITLES);
const hasPropertyInvestmentRole = clapiUsrDetail => hasRole(clapiUsrDetail, EXPORT_LIMIT_PRO);

const hasREIFormsRole = clapiUsrDetail => hasRole(clapiUsrDetail, REI_FORMS);

const commonAccess = role => ({
    hasAccess: (props) => {
        const { userDetails } = props;
        if (!hasRole(userDetails, role)) {
            window.location.replace(routeCodes.HOME.path);
            return false;
        }
        return true;
    },
});

const withLaunchDarklyAccess = (role, flag) => ({
    hasAccess: (props) => {
        const { featureFlags } = props;
        const featureFlag = new FeatureFlag(featureFlags[flag]);
        if (featureFlag.isEntitlements()) {
            return commonAccess(role)
                .hasAccess(props);
        } else if (featureFlag.isTrue()) {
            return true
        }
        window.location.replace(routeCodes.HOME.path);
        return false;
    },
});

export const SearchName = commonAccess(EntitlementsRole.SEARCH_NAME);
export const SearchParcel = commonAccess(EntitlementsRole.SEARCH_PARCEL);
export const SearchBuildingName = commonAccess(EntitlementsRole.SEARCH_BUILDING_NAME);
export const RadiusSearch = commonAccess(EntitlementsRole.RADIUS_SEARCH);
export const SavedTerritory = commonAccess(EntitlementsRole.SAVED_TERRITORY);
export const SuburbStats = commonAccess(EntitlementsRole.SUBURB_STATISTICS);
export const NZTitlesAndDocuments = {
    hasAccess: (props) => {
        const { userDetails } = props;
        if (canOrderTitles(userDetails)) {
            return true;
        }
        window.location.replace(routeCodes.HOME.path);
        return false;
    }
};

export const LGASearch = {
    hasAccess: (props) => {
        const {
            userDetails,
            location: {
                state: {
                    suggestion = null,
                } = {},
            } = {},
        } = props;
        const withSuggestion = !!suggestion;
        const isStateSearch = withSuggestion &&
            suggestion.suggestionType === SuggestionTypes.STATE;
        const isCouncilAreaSearch = withSuggestion &&
            suggestion.suggestionType === SuggestionTypes.COUNCIL_AREA;
        const withAccess = (isStateSearch || isCouncilAreaSearch) ?
            hasLGASearchRole(userDetails) : true;
        if (!withSuggestion || !withAccess) {
            window.location.replace(routeCodes.HOME.path);
            return false;
        }
        return true;
    },
};

export const SavedSearch = withLaunchDarklyAccess(EntitlementsRole
    .SAVE_SEARCH, 'crux.saved.search');

export const SavedList = withLaunchDarklyAccess(EntitlementsRole
    .SAVED_LISTS, 'crux-user-saved-lists');

export default {
    canOrderTitles,
    isRolesAvailable,
    hasRole,
    hasSearchBuildingRole,
    hasSearchNameRole,
    hasLGASearchRole,
    hasParcelSearchRole,
    hasMapAdvancedRole,
    hasSaveSearchRole,
    hasNotificationRole,
    hasRadiusSearchRole,
    hasAdvancedFiltersRole,
    hasProspectorRole,
    hasMarketingFullRole,
    hasSavedListRole,
    hasSavedTerritoriesRole,
    hasReiwaTitlesRole,
    hasPropertyInvestmentRole,
    hasREIFormsRole,
};
