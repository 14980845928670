import exceptions from '../exceptions';
import { IS_PRODUCTION } from '../constants/crux';
import Store from '../store';
import CruxProxy from './cruxProxy';

const LINKED_ACCOUNTS_PATH = '/api/clapi/accounts';

const timeoutHandler = (timeout) => {
    // setup timeout handler
    const controller = new AbortController();
    const fetchTimeout = setTimeout(() => controller.abort(), timeout);
    return {
        signal: controller.signal,
        fetchTimeout,
    };
};

const getTimeouts = () => {
    if (Store && Store.store && Store.store.getState()) {
        const { config } = Store.store.getState();
        if (config && config.get('timeouts')) {
            return config.get('timeouts');
        }
    }

    // Return default values to avoid undefined errors
    // on config requests - (config.getConfig and config.getPublicConfiguration)
    return {
        small: 5000,
        large: 30000,
    };
};

function ApiError(message, data, status) {
    let response = null;
    let isObject = false;

    // We are trying to parse response
    try {
        response = JSON.parse(data);
        isObject = true;
    } catch (e) {
        response = data;
    }

    return {
        response,
        message,
        status,
        toString: () => `${message}\nResponse:\n${isObject ? JSON.stringify(response, null, 2) : response}`,
    };
}

const urlInit = (pathParam, isProxy) => {
    if (!IS_PRODUCTION && isProxy) {
        return `http://${window.location.hostname}:8080${pathParam}`;
    }
    return pathParam;
};

// Execute API call
// Signal {AbortSignal} - dependency on handling timeouts
// Pair this request with timeoutHandler when handling timeouts
const executeRequest = (url, options, signal) => fetch(url, { signal, ...options }).catch((error) => {
    const _error = error.name === 'AbortError' ? exceptions.REQUEST_TIMEOUT.message : error;
    throw new Error(_error);
});

const getUrlInit = (path) => {
    return urlInit(path, true);
}

const logoutInitiated = () => !!logoutInProgress[0];

// An array of size 1 which value is used as a flag to indicate that logout is in-progress.
// The default value of logoutInProgress[0] when the array is created is 0. We're setting the value to 1 with the help
// of the standard JavaScript Atomics functions which guarantee that the logout will be initiated only once.
const logoutInProgress = new Uint8Array(1);
console.log('Initializing api/index.js');

const initiateSessionApiCall = (requestOptions = {}, headerOptions = {}) => {
    // Define default options
    const defaultOptions = { credentials: 'include' };

    // Define default headers
    const options = {
        // Merge options
        ...defaultOptions,
        ...headerOptions,
        // Merge headers
        headers: {
            // ...defaultHeaders,
            ...headerOptions.headers,
        },
    };
    // Build Url
    // for local development, let's skip the proxy pass
    const url = urlInit(requestOptions.path, requestOptions.isProxy || requestOptions.protected);

    // Detect if we are uploading a file.
    // Photo upload requires a formData to pass the file as a parameter
    // We need to be cautious whenever we use formData for our POST requests,
    // for now, it is only being used to send files to our APIs, using formData to pass json object
    // will be treated as file and will not work accordingly.
    // If in case in the future we need to use formData to send json object,
    // we need to find another way to detect file inside formData
    const isFile = typeof window !== 'undefined' &&
        (options.body instanceof File || options.body instanceof FormData);

    // Stringify JSON data
    // If body is not a file
    if (options.body && typeof options.body === 'object' && !isFile) {
        options.body = JSON.stringify(options.body);
    } else if (isFile) {
        // Deleting content-type header when file is being passed
        // Browser will assign it automatically.
        delete options.headers['Content-Type'];
    }

    // Variable which will be used for storing response
    let response = null;

    // Set timeout
    const timeout = (requestOptions.timeout) ?
        requestOptions.timeout : getTimeouts().small;

    // setup timeout handler
    const { signal, fetchTimeout } = timeoutHandler(timeout);
    return executeRequest(url, options, signal)
        .then((responseObject) => {
            // Saving response for later use in lower scopes
            response = responseObject;
            // Clears timeout once we get a successful response
            clearTimeout(fetchTimeout);

            // Check for error HTTP error codes
            if (response.status < 200 || response.status >= 300) {
                // Get response as text
                return response.text();
            }
            // Get response as json or blob
            const contentType = response.headers.get('content-type');
            if (contentType.startsWith('image/')) {
                return response.blob();
            }
            else if (contentType.startsWith('text/')) {
                return response.text();
            }
            return response.json();
        })
        .then((parsedResponse) => {
            if (((response.status === 401
                        && requestOptions.path !== '/api/logout'
                        && parsedResponse.includes('unauthenticated_session_error'))
                    || (response.status === 500
                        && requestOptions.path !== '/api/logout'
                        && parsedResponse.includes('auth_session_error')))
                && Atomics.exchange(logoutInProgress, 0, 1) === 0) {

                console.log("Request to: " + encodeURI(requestOptions.path) + " failed with status code / response: " +
                    response.status + " / " + parsedResponse + ", initiating logout...");
                CruxProxy.logout();
            }

            // Check for HTTP error codes
            if (response.status < 200 || response.status >= 300) {
                // Throw error
                throw parsedResponse;
            }

            // Request succeeded
            return parsedResponse;
        })
        .catch((error) => {
            // Throw custom API error
            // If response exists it means HTTP error occurred
            if (response) {
                if (response.status === 403) {
                    console.log('403 error', error);
                } else {
                    if (response.status >= 200 && response.status < 300) {
                        return null;
                    }
                    throw ApiError(`Request failed with status ${response.status}.`, error, response.status);
                }
            } else {
                throw ApiError(error.toString(), null, 'REQUEST_FAILED');
            }
        });
};

export default {
    getUrlInit,
    LINKED_ACCOUNTS_PATH,
    executeRequest,  // this is used only internally and from index.spec.js
    initiateSessionApiCall,
    getTimeouts,
    timeoutHandler, // this is used only internally and from index.spec.js
    logoutInitiated,
};
