import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import BlockUi from 'react-block-ui';

import Commons from '../../helpers/Commons';
import { clearSuggestion, getSuggestions } from '../../actions/clapi';
import { toggleSuggestionLoader } from '../../actions/componentAccess';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import SuggestionHighlighter from '../common/SuggestionHighlighter';
import Tooltip from './Tooltip';

const SuggestByType = forwardRef(({
    dispatch,
    suggestions = [],
    isSearchSuggestionsLoading,
    selectedSuggestion,
    setSelectedSuggestion,
    types,
    placeholderText = 'Type here',
    name,
    disableLoader = false,
    tooltip,
}, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [keyword, setKeyword] = useState('');

    useImperativeHandle(ref, () => ({
        resetInput: () => setKeyword(''),
    }));

    useEffect(() => {
        const { suggestion } = selectedSuggestion || {};
        if (suggestion) {
            setKeyword(suggestion);
        }
        // Clearing up things
        return () => {
            dispatch(clearSuggestion());
            setKeyword('');
            if (isSearchSuggestionsLoading) {
                dispatch(toggleSuggestionLoader(false, false))
            }
        };
    }, []);

    const handleInputTextChange = useCallback((event, { newValue, method }) => {
        if (method === 'type') {
            const trimmedValue = newValue.trim();
            if (trimmedValue.length > 2 && !Commons.hasBackSlash(trimmedValue)) {
                dispatch(getSuggestions({
                    keyword: trimmedValue,
                    suggestionTypes: types,
                }));
            } else if (trimmedValue.length < 3 &&
                (suggestions.length > 0 || isSearchSuggestionsLoading)) {
                // Additional "isSearchSuggestionsLoading" validation is included to
                // prevent always clearing of suggestions upon receiving characters less than 3.
                // Getting of suggestion will only happen when there are 3 or more characters.
                // When "isSearchSuggestionsLoading" is true, the fetching of suggestion is
                // initiating or has been initiated, only then should we clear the suggestion
                // if the keyword character count became less than 3
                dispatch(clearSuggestion());
            }
        }
        setKeyword(newValue);
    }, [suggestions, isSearchSuggestionsLoading]);

    // Making sure that the page will not trigger submit (input default behaviour)
    // on Enter and Tab
    const handleInputKeyDown = useCallback((event) => {
        // prevent from submitting then entered on text field
        if (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
            event.currentTarget.blur();
            if (keyword.length > 2) {
                dispatch(clearSuggestion());
            }
            if (isSearchSuggestionsLoading) {
                dispatch(toggleSuggestionLoader(false, false))
            }
        }
    }, [keyword, isSearchSuggestionsLoading]);

    const renderSuggestion = useCallback((suggestion, { query }) => {
        // structure for each suggestion
        const matches = match(suggestion.suggestion, query);
        const parts = parse(suggestion.suggestion, matches);
        return (
            <div
                className="search-suggestion"
            >
                <SuggestionHighlighter
                    parts={parts}
                    className="matched-suggestion"
                />
            </div>
        );
    }, [suggestions]);

    const renderSuggestionsContainer = useCallback(({ containerProps, children }) => {
        return (
            <div {...containerProps}>
                {
                    suggestions.length > 0 && isFocused &&
                    <div className="search-divider">
                        <span className="text-uppercase">Suggestions</span>
                    </div>
                }
                {children}
            </div>
        );
    }, [suggestions, isFocused]);

    const onSuggestionSelected = useCallback((event, { suggestion }) => {
        // prevent form submission upon pressing Enter
        event.preventDefault();
        setSelectedSuggestion(suggestion);
        dispatch(clearSuggestion());
        setIsFocused(false);
    }, [selectedSuggestion]);

    const onBlur = (event, { highlightedSuggestion }) => {
        setIsFocused(false);
        if (!highlightedSuggestion) {
            setKeyword('');
            if (isSearchSuggestionsLoading || suggestions.length > 0) {
                dispatch(clearSuggestion());
            }
        }
    };

    const inputProps = {
        value: keyword,
        type: `${name}-search`,
        onChange: handleInputTextChange,
        onKeyDown: handleInputKeyDown,
        onFocus: () => setIsFocused(true),
        onBlur,
        className: 'form-control',
        placeholder: placeholderText,
        autoComplete: 'nope',
    };

    return (
        <div className="auto-suggest-container" >
            {
                !disableLoader &&
                <BlockUi
                    data-testid="loader"
                    blocking={isSearchSuggestionsLoading && isFocused}
                    className="white-blocker"
                />
            }
            {
                tooltip &&
                <Tooltip
                    tooltipTxt={tooltip}
                    tooltipId="suburb-state-suggest"
                    tooltipClass="gray"
                    iconType="infoIcon"
                    tooltipPosition="bottom"
                />
            }
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={() => {/* do nothing */}}
                onSuggestionsClearRequested={() => {/* do nothing */}}
                getSuggestionValue={(suggestion) => (suggestion.suggestion)}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                renderSuggestionsContainer={renderSuggestionsContainer}
                focusInputOnSuggestionClick={false}
                onSuggestionSelected={onSuggestionSelected}
                theme={{
                    container: 'search-keyword',
                    suggestionsContainer: `search-suggestions${isFocused ? '' : ' hide'}`,
                    suggestion: 'search-suggestion',
                    suggestionHighlighted: 'search-suggestion-highlight',
                    containerOpen: 'search-keyword-open',
                }}
                alwaysRenderSuggestions={true}
            />
        </div>
    );
});

SuggestByType.propTypes = {
    dispatch: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    isSearchSuggestionsLoading: PropTypes.bool,
    selectedSuggestion: PropTypes.object,
    setSelectedSuggestion: PropTypes.func,
    types: PropTypes.array,
    placeholderText: PropTypes.string,
    name: PropTypes.string.isRequired,
    disableLoader: PropTypes.bool,
    tooltip: PropTypes.string,
};

SuggestByType.displayName = 'SuggestByType';

export default SuggestByType;
