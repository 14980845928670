import moment from 'moment/moment';
import DOMPurify from 'dompurify';
import { DOM_PURIFY_FOOTER_OPTIONS, isAU } from 'js/constants/crux';
import Random from './Random';
import { PRICE_AND_LAND_SIZE_PATTERN } from 'js/constants/regexPatterns';
import { ADD, REMOVE } from '../components/common/modals/CruxDialogBox';
import ListPropertyPreview from '../constants/listPropertyPreview';

const getPropertyLabel = (count) => count > 1 || count === 0 ? 'properties' : 'property';
const isUrl = (url) => {
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(url)) {
        return true;
    }
    return false;
};

const getSearchString = (searchString, searchType) => {
    if (searchType === ListPropertyPreview.SEARCH_TYPE.NAME) {
        const parsedData = JSON.parse(searchString);
        const { ownerFirstName, ownerLastName } = parsedData;
        if (ownerFirstName || ownerLastName) {
            const fullName = `${ownerFirstName || ''} ${ownerLastName || ''}`.trim();
            return fullName;
        }
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.COMPANY) {
        return JSON.parse(searchString).companyName;
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.BUILDING) {
        return JSON.parse(searchString).addressBuildingName.value;
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.PARCEL) {
        return JSON.parse(searchString).addressParcelSuburbState;
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.LEGAL_DESCRIPTION) {
        return JSON.parse(searchString).legalDescription;
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.TITLE_REFERENCE) {
        return JSON.parse(searchString).titleReference;
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.RADIUS) {
        return searchString
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.VOLUME_FOLIO) {
        return JSON.parse(searchString).volumeNumber;
    } else if (searchType === ListPropertyPreview.SEARCH_TYPE.ROLL_VALUATION) {
        return JSON.parse(searchString).valuationReference;
    }
    return searchString;
};

const objectToCommaSeparated = (object) => Object.values(object)
    .filter(data => data)
    .join(', ');
const printTemplate = (content, style = 'html, body { height: 99%; page-break-after: avoid !important; page-break-before: avoid !important; background: rgb(82, 86, 89); } @page { margin: 0; size: auto; border: 0; padding: 0; }') => `<!DOCTYPE html><html><style>${style}</style><body>${content}</body></html>`;

const numberWithCommas = (number, fractionDigits = 0) => {
    if (!number && parseInt(number) !== 0) return '';
    if (number === '-') {
        return number;
    }
    return Number(number).toLocaleString(undefined, { minimumFractionDigits: fractionDigits });
};

const delay = t => new Promise(resolve => setTimeout(resolve, t));

const isObjectEmpty = object => JSON.stringify(object) === JSON.stringify({});

const get = (obj, key) => key.split('.').reduce((o, x) => ((typeof o === 'undefined' || o === null) ? o : o[x]), obj);

const capitalizeFirstLetter = string => (string?.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()));

const capitalizeDashedCharacters = (string) => {
    return capitalizeFirstLetter(string.replace('-', ' - ')).replace(' - ', '-');
};

const formatDate = (dateString, dateFormat, identifier = '') => {
    if (dateString && dateFormat) {
        return moment(dateString, identifier).format(dateFormat);
    }
    return '';
};

const getType = isChecked => (isChecked ? ADD : REMOVE);
const formatTenureDate = (value) => {
    if (value) {
        const currentDate = new Date();
        const averageHold = moment(currentDate).subtract(value, 'y');
        const fromDate = moment(averageHold).add(1, 'y');
        const toDate = moment(averageHold).subtract(1, 'y');

        const formattedFromDate = formatDate(fromDate, 'YYYYMMDD');
        const formattedToDate = formatDate(toDate, 'YYYYMMDD');

        return { min: formattedFromDate, max: formattedToDate };
    }
    return { min: '', max: '' };
};

const parseDate = (dateString, currentDateFormat) => {
    if (dateString && currentDateFormat) {
        return moment(dateString, currentDateFormat);
    }
    return '';
};

const format = (v, formatStr) => (v ? v.format(formatStr) : '');

const extractStateFromSL = (singleLine) => {
    if (singleLine) {
        const addressArr = singleLine.split(' ');
        return addressArr[addressArr.length - 2];
    }
    return '';
};

const hasBackSlash = (val) => {
    if (val.indexOf('\\') > -1) {
        return true;
    }
    return false;
};

// returns numeric chars only
const isNumberKey = (value) => {
    const reg = /^-?\d+$/;
    if (reg.test(value)) {
        return value;
    }
    // replaces all non-numeric characters
    return value.replace(/\D/g, '');
};

const round = (value, precision) => {
    const multiplier = 10 ** (precision || 0);
    return Math.round(parseFloat(value) * multiplier) / multiplier;
};

const isObjectNotExist = (obj) => {
    if (obj instanceof Object) {
        return Object.keys(obj).length === 0;
    }
    return (obj === undefined || obj === null);
};

const splitUnitAndValue = (str) => {
    const splittedValue = {
        unit: '',
        numericValue: '',
    };
    const regex = new RegExp(PRICE_AND_LAND_SIZE_PATTERN);
    splittedValue.unit = str.replace(regex, '$5');
    splittedValue.numericValue = str.replace(regex, '$3');
    return splittedValue;
};

const removeCommaAndSpace = str => str.replace(/[, ]+/g, '').trim();
const removeSpace = str => str.replace(/\s+/g, '');
const removeComma = str => str.replace(/[,]+/g, '').trim();

// TODO: REMOVE - REDESIGN HAS NEW VERSION
// If you're using hectare's conversion,
// please create a separate method that is intended only for Ha and
// use getFormattedUnitAndValue to get unit and value
const getNumericValue = (str, isCurrency) => {
    if (str) {
        const splittedValue = splitUnitAndValue(str);
        const { numericValue, unit } = splittedValue;
        let newValue = numericValue;
        const formattedUnit = unit.toLowerCase();
        const formattedValue = parseFloat(newValue);
        if (unit !== '' && !Number.isNaN(formattedValue)) {
            if (formattedUnit === 'ha') {
                newValue = formattedValue * 10000;
            } else if (formattedUnit === 'k') {
                newValue = formattedValue * 1000;
            } else if (formattedUnit === 'm' && isCurrency) {
                newValue = formattedValue * 1000000;
            } else if (formattedUnit === 'b' && isCurrency) {
                newValue = formattedValue * 1000000000;
            }
        }
        return removeCommaAndSpace(newValue.toString());
    }
    return '';
};

const getFormattedUnitAndValue = (value) => {
    const { numericValue, unit } = splitUnitAndValue(value);
    return {
        unit: unit.toLowerCase(),
        value: parseFloat(removeCommaAndSpace(numericValue)),
    };
};

const expandAbbreviatedValue = (value, isMeter = false) => {
    if (value && typeof value === 'string') {
        const { unit, value: _value } = getFormattedUnitAndValue(value);
        let expanded = _value;
        if (!unit || Number.isNaN(_value)) return expanded;
        if (unit && !Number.isNaN(_value)) {
            if (unit === 'k') {
                expanded = _value * 1000;
            } else if (!isMeter && unit === 'm') {
                expanded = _value * 1000000;
            } else if (unit === 'b') {
                expanded = _value * 1000000000;
            } else if (unit === 'm²') {
                expanded = _value;
            } else if (unit === 'ha') {
                expanded = _value * 10000;
            } else if (unit === '+') {
                expanded = _value;
            }
        }
        return expanded.toString();
    }
    return value;
};

const getNumericFromStr = (str, hasDecimal) => {
    const numeric = typeof str === 'string' ? str.replace(/[^0-9.]/g, '') : str;
    return hasDecimal ? parseFloat(numeric) : parseInt(numeric, 10);
};

const toUpperCase = (src, key) => {
    if (src) {
        if (src.constructor === String) {
            return src.toUpperCase();
        } else if (src.constructor === Object) {
            const tmp = get(src, key);
            return toUpperCase(tmp);
        }
    }

    return '';
};

const removeEmptyValuesInObj = (obj) => {
    Object.keys(obj).forEach((key) => {
        ((obj[key] && typeof obj[key] === 'object') && removeEmptyValuesInObj(obj[key])) ||
        ((obj[key] === '' || obj[key] === null) && delete obj[key]);
    });
    return obj;
};

const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const isPositiveNumber = (val) => {
    const regexp = new RegExp(/^(\d+)$/g);
    const regexpRes = regexp.test(val);
    return (val === '' || regexpRes);
};

/**
 * Make promise cancellable
 * see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * @param promise
 * @returns {{cancel(): void, promise: Promise<any>}}
 */
const makeCancelablePromise = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((res, rej) => {
        promise
            .then(d => {
                return hasCanceled_ ? rej(hasCanceled_) : res(d);
            })
            .catch(e => {
                rej(hasCanceled_ ? hasCanceled_ : e);
            });
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

const getCoordinate = (property) => {
    if (property.coordinate && !isObjectEmpty(property.coordinate)) {
        return {
            lat: property.coordinate.latitude,
            lng: property.coordinate.longitude,
        };
    }

    return null;
};

const truncateTextWithEllipsis = (text, limit) => {
    if (!text) {
        return '';
    }

    if (text.length > limit) {
        return `${text.substring(0, limit)}...`;
    }
    return text;
};

const removeFalsyFromArray = (arr) => {
    try {
        if (arr) {
            return arr.filter(val => val);
        }
    } catch (err) {
        // err
    }

    return null;
};

function regExpressionCheck(regexp, val) {
    const regexpRes = regexp.test(val);
    return (val === '' || regexpRes);
}

const isZeroToTwenty = (val) => {
    const regexp = new RegExp(/^((0+)?([0-9]|([1][0-9])|(20)))$/g);
    return regExpressionCheck(regexp, val);
};
const isZeroToOneHundred = (val) => {
    const regexp = new RegExp(/^((0+)?([0-9]{0,2}?|100))$/g);
    return regExpressionCheck(regexp, val);
};
const isZeroToHundredThousand = (val) => {
    const regexp = new RegExp(/^((0+)?([0-9]{0,5}?|100000))$/g);
    return regExpressionCheck(regexp, val);
};
const isZeroToHundredMillion = (val) => {
    const regexp = new RegExp(/^((0+)?([0-9]{0,8}?|100000000))$/g);
    return regExpressionCheck(regexp, val);
};

const isAllDigits = (val) => {
    const regexp = new RegExp(/^[0-9]+$/g);
    return regExpressionCheck(regexp, val);
};

const chunkArray = (array, chunkSize) => {
    const results = [];
    while (array.length) {
        results.push(array.splice(0, chunkSize));
    }
    return results;
};

// propertySummaryList could be the response from clapi search
// or could be the response from crux multipage search
// or could be the response from rapid search
const getStateDisclaimerKeys = (propertySummaryList, initState) => {
    const states = initState ? [`state${initState}_au`] : [];
    if (propertySummaryList) {
        states.push(...propertySummaryList
            .map((property) => {
                const state = extractStateFromSL(get(property, 'propertySummary.address.singleLineAddress')) ||
                    get(property, 'state') ||
                    get(property, 'addressState') ||
                    extractStateFromSL(get(property, 'singleLineAddress'));
                return state ? `state${state}_au` : null;
            }));
    }
    return [...new Set(removeFalsyFromArray(states))];
};

/**
 * @param value
 * @returns {number} 1 if positive, 0 if zero, -1 if negative
 */
const getSign = (value) => {
    if (value > 0) return 1;
    else if (value < 0) return -1;
    return 0;
};

const isHistoric = (searchParam) => {
    const includeHistoric = new URLSearchParams(searchParam).get('includeHistoric');
    return isAU && !!includeHistoric && includeHistoric === 'true';
};

const includeHistoricalByActive = (isActive = '') => {
    // should set the paramValue to string to handle the boolean issue
    const active = `${isActive}`.split(',');
    // strict check for historical properties
    return (active.filter(active => 'true' === active.trim()).length === 1 &&
        active.filter(active => 'false' === active.trim()).length === 1);
};


const getUrlParamValue = (param) => {
    try {
        const url = new URL(window.location.href);
        return url.searchParams.get(param);
    } catch (e) {
        return '';
    }
};

const isHistoricProperty = (property) => {
    const isActiveProperty = get(property, 'isActiveProperty');
    return isAU && isActiveProperty === false;
};

const addHistoricParam = (url, isIncludeHistoric = isHistoric(window.location.search)) => {
    const formedUrl = url || '';
    if (isIncludeHistoric) {
        let delimiter = '?';
        if (formedUrl && formedUrl.match(/\?/g)) {
            delimiter = '&';
        }
        return `${formedUrl}${delimiter}includeHistoric=true`;
    }

    return formedUrl;
};

const sortStringArrayNumericLast = (arr) => {
    const isNumber = input => /\d/.test(input);
    return arr.sort((a, b) => {
        // sort string starting with numbers to last
        if (isNumber(a[0]) && !isNumber(b[0])) return 1;
        if (isNumber(b[0]) && !isNumber(a[0])) return -1;
        // string sorting regardless of casing
        const lowerCaseA = a.toLowerCase();
        const lowerCaseB = b.toLowerCase();
        if (lowerCaseA < lowerCaseB) return -1;
        if (lowerCaseA > lowerCaseB) return 1;
        return 0;
    });
};

const isStringArrayEquals = (a, b) =>
    JSON.stringify(a.slice().sort()) === JSON.stringify(b.slice().sort());

const generateRandomString = () => (Random.getNumber() + 1).toString(36).substring(7);

const parseBoolean = (stringBoolean) => {
    if (stringBoolean) {
        if (stringBoolean === 'true') {
            return true;
        } else if (stringBoolean === 'false') {
            return false;
        }
        throw new Error('Not a boolean');
    }
    return false;
};

const getBase64FileType = base64Data => base64Data
    .substring('data:image/'.length, base64Data.indexOf(';base64'));

const camelize = str => str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

const formatPropertyTypes = (str) => {
    const formattedPropertyType = str.split(",")
        .map((item) => capitalizeFirstLetter(item.trim()));
    return formattedPropertyType.toString();
}

const decodeHtml = (html) => {
    // get rid of html entities
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent.trim();
};

const formatPipeSeparatedAddress = (addressString) => addressString.split('|')
    .map(address => address.replace(/, /g, ' '))
    .join(', ');

const purifyDomElement = (text) => DOMPurify.sanitize(text?.replace(/\u00a0/g, ' '), DOM_PURIFY_FOOTER_OPTIONS);

const getUserSetting = (userSettings = [], key, defaultValue = {}) => {
    const options = userSettings
        .find(userSetting => userSetting.name === key)
        ?.value;

    return options ? JSON.parse(options) : defaultValue;
}
const getLastElementFromArray = (arr = []) => arr[arr.length - 1];

const isArrayEmpty = arr => !arr || arr.length === 0;

const isArrayNotEmpty = arr => !isArrayEmpty(arr);

const isTerritoriesMaxed = (savedTerritories) => {
    return savedTerritories?.list?.length >= 25 || savedTerritories?.totalCount >= 25;
}

const toPercent = (text) => text ? `${text}%` : '-';

const sortObjectByKeys = (object) => Object
    .keys(object)
    .sort()
    .reduce((ac, cu) => ({ ...ac, [cu]: object[cu] }), {});

const replaceSpaceWithDash = string => string?.split(' ').join('-').toLowerCase() || '';

const getRequestParamValue = (url, paramName) => {
    const urlSearchParams = url.includes('?') ? url.split('?')[1] : '';
    const params = new URLSearchParams(urlSearchParams);
    return params.get(paramName);
};

const removeRequestParams = (uriPath) => {
    return uriPath.includes('?') ? uriPath.split('?')[0] : uriPath;
};

const sanitizeCRLF = (text) => text?.replace(/[\n]/g, '\\n')?.replace(/[\r]/g, '\\r');

export default {
    isUrl,
    printTemplate,
    numberWithCommas,
    isObjectEmpty,
    get,
    capitalizeFirstLetter,
    hasBackSlash,
    formatDate,
    parseDate,
    extractStateFromSL,
    isNumberKey,
    round,
    isObjectNotExist,
    format,
    removeCommaAndSpace,
    removeSpace,
    getNumericValue,
    splitUnitAndValue,
    toUpperCase,
    removeEmptyValuesInObj,
    isEqual,
    delay,
    isPositiveNumber,
    makeCancelablePromise,
    getCoordinate,
    truncateTextWithEllipsis,
    removeFalsyFromArray,
    isZeroToTwenty,
    isZeroToHundredThousand,
    isZeroToOneHundred,
    isZeroToHundredMillion,
    chunkArray,
    getStateDisclaimerKeys,
    getSign,
    isAllDigits,
    isHistoric,
    isHistoricProperty,
    includeHistoricalByActive,
    addHistoricParam,
    sortStringArrayNumericLast,
    getNumericFromStr,
    expandAbbreviatedValue,
    isStringArrayEquals,
    getUrlParamValue,
    generateRandomString,
    parseBoolean,
    getBase64FileType,
    camelize,
    formatPropertyTypes,
    decodeHtml,
    formatPipeSeparatedAddress,
    removeComma,
    purifyDomElement,
    formatTenureDate,
    getUserSetting,
    getLastElementFromArray,
    isArrayEmpty,
    isArrayNotEmpty,
    capitalizeDashedCharacters,
    toPercent,
    sortObjectByKeys,
    replaceSpaceWithDash,
    getPropertyLabel,
    getRequestParamValue,
    removeRequestParams,
    isTerritoriesMaxed,
    getType,
    sanitizeCRLF,
    getSearchString,
    objectToCommaSeparated,
};
