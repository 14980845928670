const EXCEEDED_MAX_ATTEMPTS_TO_REFRESH_TOKEN = 'Exceeded max attempts to refresh token';
const NO_TOKEN_AVAILABLE_PLEASE_LOGIN_AGAIN = 'No token available, please login again';
const UNAUTHORIZED = 'Request failed with status 401.';
const CHUNK_LOAD_ERROR = 'ChunkLoadError';

// ignored message should be added in the list
const ignoredErrorMessageList = [
    EXCEEDED_MAX_ATTEMPTS_TO_REFRESH_TOKEN,
    NO_TOKEN_AVAILABLE_PLEASE_LOGIN_AGAIN,
    UNAUTHORIZED,
    CHUNK_LOAD_ERROR,
];

// Callback method from the bugsnag for customizing events.
// Utilized to ignore events that shouldn't be sent to bugsnag.

// https://docs.bugsnag.com/platforms/javascript/legacy/react/customizing-error-reports/
// You can either call ignore() or return a false value to cancel the event from sending
// Already tried ignore() but it's not working as expected on higher environments
const beforeSend = (error) => {
    if (error.errors.errorMessage.includes(CHUNK_LOAD_ERROR)) {
        window.location.reload(true);
    }
    return !ignoredErrorMessageList.includes(error.errors.errorMessage);
};

export default beforeSend;
